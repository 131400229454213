import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useGetAllCampusesQuery } from "../../../../graphql/generated.tsx";
import { Card } from "flowbite-react";
import { Link } from "react-router-dom";

const AssignmentCampusListing = () => {
  const [campus, setCampus] = useState([]);
  const { data, loading, error } = useGetAllCampusesQuery();
  useEffect(() => {
    if (data) {
      setCampus(data.allCampus);
    }
  }, [data]);
  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p className="">Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
             Assignments Reports
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {campus.map((campus) => (
            <Link to={`/admin/reports/assignments/${campus.name}`} key={campus.id}>
              <Card className="h-full">
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {campus.name}
                </h5>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AssignmentCampusListing;
