import React from "react";
import Draggable from "react-draggable";
import swal from "sweetalert";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import "./Detections.css";

var count_facedetect = 0;

export default class Detection extends React.Component {
  videoRef = React.createRef();
  canvasRef = React.createRef();

  state = {
    hasSubmitted: false,
  };

  componentDidMount() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
            width: 500,
            height: 300,
          },
        })
        .then((stream) => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve) => {
            this.videoRef.current.onloadedmetadata = () => {
              this.canvasRef.current.width = this.videoRef.current.videoWidth;
              this.canvasRef.current.height = this.videoRef.current.videoHeight;
              resolve();
            };
          });
        });

      const modelPromise = cocoSsd.load();
      Promise.all([modelPromise, webCamPromise])
        .then((values) => {
          this.detectFrame(this.videoRef.current, values[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  detectFrame = (video, model) => {
    model.detect(video).then((predictions) => {
      if (this.canvasRef.current) {
        this.renderPredictions(predictions);
        requestAnimationFrame(() => {
          this.detectFrame(video, model);
        });
      } else {
        return false;
      }
    });
  };

  renderPredictions = (predictions) => {
    const ctx = this.canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    // Filter out predictions that are not "person", "cell phone", or "book"
    const filteredPredictions = predictions.filter((prediction) =>
      ["person", "cell phone", "book"].includes(prediction.class)
    );

    if (filteredPredictions.length === 0) {
      swal("Face Not Detected", "Action has been Recorded", "error");
      count_facedetect += 1;
    }

    filteredPredictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      const width = prediction.bbox[2];
      const height = prediction.bbox[3];
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);
      ctx.fillStyle = "#00FFFF";
      const textWidth = ctx.measureText(prediction.class).width;
      const textHeight = parseInt(font, 10);
      ctx.fillRect(x, y, textWidth + 8, textHeight + 8);

      if (prediction.class !== "person") {
        swal(
          `${
            prediction.class.charAt(0).toUpperCase() + prediction.class.slice(1)
          } Detected`,
          `Action has been Recorded: Cheat count: ${count_facedetect + 1}`,
          "error"
        );
        count_facedetect += 1;
      }
    });

    filteredPredictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      ctx.fillStyle = "#000000";
      ctx.fillText(prediction.class, x, y);
    });

    sessionStorage.setItem("count_facedetect", count_facedetect);

    if (
      count_facedetect >= this.props.max_cheat_count &&
      !this.state.hasSubmitted
    ) {
      this.setState({ hasSubmitted: true }, () => {
        this.props.handleSubmit();
      });
    }
  };

  render() {
    return (
      <div className="container">
        <Draggable>
          <video
            className="video"
            autoPlay
            playsInline
            muted
            ref={this.videoRef}
            width="200"
            height="150"
          />
        </Draggable>
        <Draggable>
          <canvas
            className="hidden"
            ref={this.canvasRef}
            width="200"
            height="150"
          />
        </Draggable>
      </div>
    );
  }
}
