import React, { useEffect, useState } from "react";
import { Modal, Button, TextInput, Label, Checkbox, Spinner } from "flowbite-react";
import Select from "react-select"; // Import react-select for multi-select

const EditStaffModal = ({
  isOpen,
  onClose,
  onSubmit,
  staffDetails,
  loading,
  clusterOptions, 
}) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    clusterIds: [],
    isAdminStaff: false,
  });

  useEffect(() => {
    if (staffDetails) {
      setFormValues({
        name: staffDetails?.name,
        email: staffDetails?.email,
        clusterIds: staffDetails?.clusterIds?.map((id) => ({
          value: id,
          label: clusterOptions.find((c) => c.id === id)?.name,
        })),
        isAdminStaff: staffDetails.isAdminStaff,
      });
    }
  }, [staffDetails, clusterOptions]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleClusterChange = (selectedOptions) => {
    setFormValues((prev) => ({
      ...prev,
      clusterIds: selectedOptions || [], 
    }));
  };

  const handleSubmit = () => {
    onSubmit({
      ...formValues,
      clusterIds: formValues?.clusterIds?.map((cluster) => cluster.value),
    });
  };

  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header className="text-lg font-semibold">Edit Staff</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          <div>
            <Label className="block text-sm font-medium text-gray-700">
              Name
            </Label>
            <TextInput
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              className="mt-1"
              placeholder="Enter staff name"
              required
            />
          </div>
          <div>
            <label className="flex items-center space-x-2">
              <Checkbox
                name="isAdminStaff"
                checked={formValues.isAdminStaff}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <span className="text-sm font-medium text-gray-700">
                Admin Staff
              </span>
            </label>
          </div>
          <div>
            <Label className="block text-sm font-medium text-gray-700">
              Email
            </Label>
            <TextInput
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className="mt-1"
              placeholder="Enter staff email"
              required
            />
          </div>
          {!formValues.isAdminStaff && (
            <div>
              <Label className="block text-sm font-medium text-gray-700">
                Clusters
              </Label>
              <Select
                isMulti
                value={formValues.clusterIds}
                onChange={handleClusterChange}
                options={clusterOptions?.map((cluster) => ({
                  value: cluster.id,
                  label: cluster.name,
                }))}
                className="mt-1"
                placeholder="Select clusters"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleSubmit}
          disabled={loading}
          className="bg-primary p-0 focus:ring-blue-500"
        >
          {loading && <Spinner size="sm" className="mr-2" />}
          Update
        </Button>
        <Button color="gray" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditStaffModal;
