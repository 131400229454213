import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import formatDate from "../Helpers/formatDate";

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: "Helvetica",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#bfbfbf",
    paddingBottom: 10,
    marginBottom: 20,
  },
  logo: {
    width: 100,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#333",
  },
  subtitle: {
    fontSize: 12,
    color: "#666",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    margin: "10px 0",
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#f7f7f7",
    borderBottomColor: "#bfbfbf",
    borderBottomWidth: 1,
  },
  tableRowOdd: {
    backgroundColor: "#ffffff",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: "#e0e0e0",
    fontWeight: "bold",
  },
  footer: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 10,
    color: "#666",
  },
});

const PDFDocument = ({ students, ClusterName, CreatedAt }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={'/favicon.ico'} style={styles.logo} />
        <View>
          <Text style={styles.title}>{ClusterName} Report</Text>
          <Text style={styles.subtitle}>Created At: {CreatedAt}</Text>
        </View>
      </View>
      <View style={styles.table}>
        <View style={[styles.tableRow, styles.tableHeader]}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Email</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Points</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Last Submissions</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Questions Completed</Text>
          </View>
        </View>
        {students?.map((student, index) => (
          <View
            style={[
              styles.tableRow,
              index % 2 === 0 ? styles.tableRowOdd : null,
            ]}
            key={index}
          >
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{student.student.email}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{student.totalScore}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatDate(student.latestSubmissionDate)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {student.student.completedQuestions.length}
              </Text>
            </View>
          </View>
        ))}
      </View>
      <Text style={styles.footer}>Generated by Better Tomorrow</Text>
    </Page>
  </Document>
);

export { PDFDocument };
