import React, { useState, useEffect } from "react";
import NavBar from "../../../Common/NavBar";
import {
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
  useUpdateStudentMutation,
} from "../../../graphql/generated.tsx";
import { MdClose, MdOutlineEdit } from "react-icons/md";
import { TfiSave } from "react-icons/tfi";
import StudentLayout from "../Layout/index.jsx";

const MyProfile = () => {
  const email = JSON.parse(localStorage.getItem("user")).email;
  const {
    data: StudentData,
    loading,
    error,
  } = useGetStudentByEmailIdQuery({
    variables: { email },
  });
  const { data: CampusData } = useGetCampusQuery({
    variables: { id: StudentData?.getStudentByEmailId?.campusId },
    skip: !StudentData,
  });
  const [isEditing, setIsEditing] = useState({});
  const [formData, setFormData] = useState({
    dob: "",
    hackerRankUname: "",
    id: "",
    leetCodeUname: "",
    name: "",
    phno: "",
    placeOfResidence: "",
  });

  const [updateStudent] = useUpdateStudentMutation();
  useEffect(() => {
    if (StudentData && StudentData.getStudentByEmailId) {
      setFormData({
        dob: StudentData.getStudentByEmailId.dob || "",
        hackerRankUname: StudentData.getStudentByEmailId.hackerRankUname || "",
        id: StudentData.getStudentByEmailId.id || "",
        leetCodeUname: StudentData.getStudentByEmailId.leetCodeUname || "",
        name: StudentData?.getStudentByEmailId?.name || "",
        phno: StudentData.getStudentByEmailId.phno || "",
        placeOfResidence:
          StudentData.getStudentByEmailId.placeOfResidence || "",
        email: StudentData.getStudentByEmailId.email || "",
        rollNo: StudentData.getStudentByEmailId.rollNo || "",
        campusName: CampusData?.campus?.name || "",
      });
    }
  }, [CampusData?.campus?.name, StudentData]);

  const handleEditClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveClick = (field) => {
    const {
      id,
      dob,
      hackerRankUname,
      leetCodeUname,
      name,
      phno,
      email,
      placeOfResidence,
      rollNo,
    } = formData;
    updateStudent({
      variables: {
        updateStudentInput: {
          id,
          dob,
          hackerRankUname,
          leetCodeUname,
          name,
          phno,
          email,
          placeOfResidence,
          rollNo,
        },
      },
    });
    setIsEditing((prev) => ({ ...prev, [field]: false }));
  };

  const handleCloseClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
  };

  const renderField = (field, label) => (
    <div
      key={field}
      className="flex items-center mb-4 p-4 bg-white rounded-md shadow-md"
    >
      <strong className="w-1/3 text-gray-700">{label}:</strong>
      {isEditing[field] ? (
        <input
          type={field === "dob" ? "date" : "text"}
          name={field}
          value={formData[field]}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
      ) : (
        <span className="w-1/2 text-gray-900">{formData[field]}</span>
      )}
      {field !== "email" && field !== "campusName" && !isEditing[field] && (
        <MdOutlineEdit
          onClick={() => handleEditClick(field)}
          className="ml-5 text-primary cursor-pointer"
        />
      )}
      {isEditing[field] && (
        <>
          <TfiSave
            onClick={() => handleSaveClick(field)}
            className="ml-3 h-5 w-5 hover:text-secondary cursor-pointer"
          />
          <MdClose
            onClick={() => handleCloseClick(field)}
            className="ml-3 h-5 w-5 hover:text-red-500 cursor-pointer"
          />
        </>
      )}
    </div>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <StudentLayout>
      <div className="lg:mt-14 bg-slate-100 min-h-screen min-w-screen max-md:ml-10 max-md:mr-3 max-md:mb-5">
        <h2 className="text-3xl font-bold text-center w-full mb-6 max-md:text-xl pt-5">
          My Profile
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-7xl mx-auto bg-white p-6 rounded-lg shadow-lg">
          {renderField("name", "Name")}
          {renderField("rollNo", "Roll No")}
          {renderField("campusName", "College Name")}
          {renderField("dob", "Date of Birth")}
          {renderField("email", "Email")}
          {renderField("hackerRankUname", "HackerRank Username")}
          {renderField("leetCodeUname", "LeetCode Username")}
          {renderField("phno", "Phone Number")}
          {renderField("placeOfResidence", "Place of Residence")}
        </div>
      </div>
    </StudentLayout>
  );
};

export default MyProfile;
