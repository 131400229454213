import { Modal, Table } from "flowbite-react";
import React, { useState } from "react";

const ImportQuestionCard = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  function getDifficultyColor(difficulty) {
    switch (difficulty) {
      case "EASY":
        return "bg-green-200 text-gray-800";
      case "MEDIUM":
        return "bg-yellow-100 text-gray-800";
      case "HARD":
        return "bg-red-100 text-gray-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  }
  const difficulty =
    data?.difficulty?.charAt(0).toUpperCase() +
    data?.difficulty?.slice(1).toLowerCase();
  return (
    <>
      <div
        onClick={openModal}
        className="flex flex-col ml-5 justify-between relative cursor-pointer w-full"
      >
        <div className="flex max-md:flex-col justify-between lg:items-center mb-1">
          <div className="font-bold text-xl hover:text-blue-500">
            {data.title}
          </div>
          <div className="max-md:mt-3 flex">
            {data?.difficulty && (
              <div
                className={`p-1 w-16 text-[12px] text-center font-light rounded-lg ${getDifficultyColor(
                  data.difficulty
                )}`}
              >
                {difficulty}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col max-md:mt-3">
          <div className="mb-2">
            <p className="text-gray-500 text-sm">Max Score: {data.marks}</p>
          </div>
          <div className="flex flex-wrap gap-1">
            {data?.tags &&
              data.tags.map((tag, index) => (
                <span
                  key={index}
                  className="inline-block bg-blue-100 text-blue-800 rounded px-2 py-1 text-xs font-medium"
                >
                  {tag}
                </span>
              ))}
          </div>
        </div>
      </div>
      <Modal dismissible show={isModalOpen} onClose={closeModal}>
        <Modal.Header className="font-bold text-lg">{data.title}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className="font-semibold text-black">Text:</span>{" "}
              <div dangerouslySetInnerHTML={{ __html: data.text }} />
            </p>
            {/* <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
           <span className="font-semibold text-black">Assignment ID:</span>{" "}
           {assignmentId}
         </p> */}
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className="font-semibold text-black block">
                Question Subtext:
              </span>{" "}
              {data.question_sub_text}
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className="font-semibold text-black">Marks:</span>{" "}
              {data.marks}
            </p>

            <div>
              <span className="font-semibold">Test Cases:</span>
              <Table className="w-full mt-2 border border-black outline-black">
                <Table.Head>
                  <Table.HeadCell className="border border-black px-4 py-2">
                    Input
                  </Table.HeadCell>
                  <Table.HeadCell className="border border-black px-4 py-2">
                    Output
                  </Table.HeadCell>
                  <Table.HeadCell className="border border-black px-4 py-2">
                    Visibility
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body>
                  {data.test_cases.map((testCase) => (
                    <Table.Row key={testCase.id}>
                      <Table.Cell className="border border-black px-4 py-2">
                        {testCase.input}
                      </Table.Cell>
                      <Table.Cell className="border border-black px-4 py-2">
                        {testCase.output}
                      </Table.Cell>
                      <Table.Cell className="border border-black px-4 py-2">
                        {testCase.visibility}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>

            <div>
              {data?.hints?.length !== 0 ? (
                <span className="font-semibold">Hints:</span>
              ) : null}
              <ul>
                {data?.hints?.length !== 0
                  ? data.hints.map((hint, index) =>
                      hint.length > 0 ? (
                        <li key={index} className="text-gray-500">
                          Hint {index + 1}: {hint}
                        </li>
                      ) : null
                    )
                  : null}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImportQuestionCard;
