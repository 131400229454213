import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import {
  useGetCampusQuery,
  useGetClustersByCampusQuery,
  useGetLeaderboardQuery,
  useGetStaffByEmailIdQuery,
  useGetStudentsByCampusQuery,
} from "../../../../graphql/generated.tsx";
import LeaderboardStaff from "../../Components/LeaderBoardStaff.jsx";
import {
  Spinner,
  Table,
  Tabs,
  TextInput,
  Button,
  Pagination,
  Select,
} from "flowbite-react";
import { MdDashboard, MdOutlinePersonOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { TiGroup } from "react-icons/ti";
import { FaPerson } from "react-icons/fa6";

const StaffDashboard = () => {
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: staffData } = useGetStaffByEmailIdQuery({
    variables: { email },
  });

  const { data: campusDetails, loading: campusLoading } = useGetCampusQuery({
    variables: { id: staffData?.getStaffByEmailId?.campusId },
    skip: !staffData,
  });
  const campus = campusDetails?.campus;

  const { data: clustersData, loading: clustersLoading } =
    useGetClustersByCampusQuery({
      variables: { campusName: campus?.name },
      skip: !campus,
    });

   

    const handleFilter = (value) => {
      setFilter(value);
    };

  const availableClusters = staffData?.getStaffByEmailId?.isAdminStaff
    ? clustersData?.getClustersByCampus
    : clustersData?.getClustersByCampus.filter((cluster) =>
        staffData?.getStaffByEmailId?.clusterIds?.includes(cluster.id)
      );

  const [selectedCluster, setSelectedCluster] = useState(
    availableClusters?.[0]?.id
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("custom");
  const studentsPerPage = 20;

  const {
    data: studentData,
    loading: studentLoading,
    refetch,
  } = useGetStudentsByCampusQuery({
    variables: {
      campusId: campus?.id,
      clusterid: selectedCluster || availableClusters?.[0]?.id,
      searchTerm: searchClicked ? searchTerm : "",
      page,
      limit: studentsPerPage,
    },
  });

  const handleSearchClick = () => {
    setSearchClicked(true);
    refetch({
      campusId: campus?.id,
      clusterid: selectedCluster,
      searchTerm,
    });
    setSearchClicked(false);
  };

  const totalStudents = studentData?.getStudentsByCampus?.total || 0;
  const totalPages = Math.ceil(totalStudents / studentsPerPage);

   const {
     data: leaderboardData,
     loading: leaderboardLoading,
     error: leaderboardError,
     refetch: leaderboardRefetch,
   } = useGetLeaderboardQuery({
     variables: {
       campusName: campus?.name,
       clusterId: selectedCluster || availableClusters?.[0]?.id,
      //  filter,
     },
   });

   const Datepicker = () => {
     return (
       <Select value={filter} onChange={(e) => handleFilter(e.target.value)}>
         <option value="custom">TILL DATE</option>
         <option value="weekly">PAST 7 DAYS</option>
         <option value="daily">TODAY</option>
         <option value="past10days">PAST 10 DAYS</option>
         <option value="monthly">THIS MONTH</option>
       </Select>
     );
   };

  if (clustersLoading || studentLoading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="xl" />
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="overflow-x-auto">
        <Tabs aria-label="Full width tabs" variant="fullWidth">
          {/* Individual Tab */}
          <Tabs.Item title="Student Lookup" active icon={FaPerson}>
            <div className="p-4 sm:p-10">
              <div className="flex flex-col sm:flex-row justify-between items-center mb-4 gap-2">
                <Select
                  value={selectedCluster}
                  onChange={(e) => setSelectedCluster(e.target.value)}
                  disabled={availableClusters?.length == 1}
                >
                  {availableClusters?.map((cluster) => (
                    <option key={cluster.id} value={cluster.id}>
                      {cluster.name}
                    </option>
                  ))}
                </Select>

                <form
                  className="flex w-full sm:w-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearchClick();
                  }}
                >
                  <TextInput
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    placeholder="Search by name, roll no, or email"
                    className="rounded-none rounded-l-lg w-[235px] "
                    style={{ borderRadius: "10px 0px 0px 10px" }}
                  />
                  <Button
                    type="submit"
                    className="rounded-none rounded-r-lg bg-primary"
                  >
                    Search
                  </Button>
                </form>
              </div>
              <div className="overflow-x-auto">
                {/* Display student data in a table */}
                {studentData?.getStudentsByCampus?.students?.length != 0 && (
                  <Table className="min-w-full bg-white rounded-lg shadow-md text-center">
                    <Table.Head >
                      <Table.HeadCell className="text-sm font-semibold text-gray-700 uppercase tracking-wider bg-gray-100">
                        Name
                      </Table.HeadCell>
                      <Table.HeadCell className="text-sm font-semibold text-gray-700 uppercase tracking-wider bg-gray-100">
                        Roll No
                      </Table.HeadCell>
                      <Table.HeadCell className="text-sm font-semibold text-gray-700 uppercase tracking-wider bg-gray-100">
                        Email
                      </Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                      {studentData?.getStudentsByCampus?.students?.map(
                        (student) => (
                          <Table.Row
                            key={student.id}
                            className="bg-white hover:bg-gray-100 transition duration-200"
                          >
                            <Table.Cell
                              onClick={() => navigate(`student/${student.id}`)}
                              className="cursor-pointer px-4 py-3 text-gray-800 border-b border-gray-200"
                            >
                              {student.name}
                            </Table.Cell>
                            <Table.Cell className="px-4 py-3 text-gray-600 border-b border-gray-200">
                              {student.rollNo}
                            </Table.Cell>
                            <Table.Cell className="px-4 py-3 text-gray-600 border-b border-gray-200">
                              {student.email}
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                )}
              </div>
              {studentData?.getStudentsByCampus?.students?.length === 0 && (
                <div className="flex flex-col items-center justify-center h-[500px] text-center">
                  <MdOutlinePersonOff className="text-7xl text-gray-400 mb-4" />
                  <h2 className="text-2xl font-bold text-gray-600 mb-2">
                    Oops, No students are enrolled in this batch
                  </h2>
                  <p className="text-gray-500 mb-6 lg:w-[500px]">
                    It looks like we couldn't find any matching data. Try
                    refining the filter options.
                  </p>
                </div>
              )}
              {studentData?.getStudentsByCampus?.students?.length != 0 &&
                !searchTerm && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={page}
                      layout="navigation"
                      onPageChange={(newPage) => setPage(newPage)}
                      totalPages={totalPages}
                    />
                  </div>
                )}
            </div>
          </Tabs.Item>

          <Tabs.Item title="Batch Analysis" icon={TiGroup}>
            <div className="p-4 sm:p-10">
              <div className="flex justify-between mb-4">
                <Select
                  value={selectedCluster}
                  onChange={(e) => setSelectedCluster(e.target.value)}
                  disabled={availableClusters?.length == 1}
                >
                  {availableClusters?.map((cluster) => (
                    <option key={cluster.id} value={cluster.id}>
                      {cluster.name}
                    </option>
                  ))}
                </Select>

                {leaderboardData?.getLeaderboard.length != 0 && (
                  <div className="flex items-center justify-between pt-3 sm:pt-6">
                    <Datepicker />
                  </div>
                )}
              </div>
              <LeaderboardStaff
                clusterId={selectedCluster || availableClusters?.[0]?.id}
                campusName={campus?.name}
                refetch={leaderboardRefetch}
                filter={filter}
                leaderboardLoading={leaderboardLoading}
                leaderboardError={leaderboardError}
                leaderboardData={leaderboardData}
              />
            </div>
          </Tabs.Item>
        </Tabs>
      </div>
    </AdminLayout>
  );
};

export default StaffDashboard;
