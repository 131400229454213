import { Modal, Label, TextInput, Checkbox, Button, Spinner } from "flowbite-react";
import React, { useState, useEffect } from "react";
import Select from "react-select";

const CreateStaffModal = ({
  isOpen,
  onClose,
  onSubmit,
  campusid,
  loading,
  clusterOptions,
}) => {

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    campusId: campusid,
    clusterIds: [],
    isAdminStaff: false,
  });

  useEffect(() => {
    setFormValues((prevValues) => ({ ...prevValues, campusId: campusid }));
  }, [campusid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormValues({ ...formValues, clusterIds: selectedOptions || [] });
  };

  const handleCheckboxChange = () => {
    setFormValues({
      ...formValues,
      isAdminStaff: !formValues.isAdminStaff,
      clusterIds: [],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  return (
    <Modal show={isOpen} size="md" popup onClose={onClose}>
      <Modal.Header className="border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-900">Create Staff</h2>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="space-y-5">
          {/* Name Field */}
          <div>
            <Label htmlFor="name" value="Name" />
            <TextInput
              id="name"
              name="name"
              placeholder="Enter name"
              value={formValues.name}
              onChange={handleChange}
              required
              className="mt-1"
            />
          </div>

          {/* isAdminStaff Field */}
          <div className="flex items-center gap-2">
            <Checkbox
              id="isAdminStaff"
              name="isAdminStaff"
              checked={formValues.isAdminStaff}
              onChange={handleCheckboxChange}
            />
            <Label htmlFor="isAdminStaff">Is Admin Staff</Label>
          </div>

          {/* Email Field */}
          <div>
            <Label htmlFor="email" value="Email" />
            <TextInput
              id="email"
              type="email"
              name="email"
              placeholder="Enter email"
              value={formValues.email}
              onChange={handleChange}
              required
              className="mt-1"
            />
          </div>

          {/* ClusterIds Field */}
          {!formValues.isAdminStaff && (
            <div>
              <Label htmlFor="clusters" value="Clusters" />
              <Select
                id="clusters"
                isMulti
                options={clusterOptions?.map((cluster) => ({
                  value: cluster.id,
                  label: cluster.name,
                }))}
                value={formValues.clusterIds}
                onChange={handleSelectChange}
                className="mt-1"
              />
            </div>
          )}

          {/* Buttons */}
          <div className="flex justify-end gap-2 mt-4">
            <Button type="button" color="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" color="success">
              {loading && <Spinner size="sm" className="mr-2" />}
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateStaffModal;
