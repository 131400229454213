import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import StudentQuestionCard from "../Components/StudentQuestionCard";
import PracticeLayout from "../Layout";
import { useGetAllQuestionQuery } from "../../../graphql/generated.tsx";
import { Button } from "flowbite-react";

const StudentQuestionListing = () => {
  const { categoryId, courseId, clusterid } = useParams();
  const [questions, setQuestions] = useState([]);
  const [catName, setCatName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;
  const { data, loading, refetch } = useGetAllQuestionQuery({
    variables: {
      courseId: courseId,
      page: currentPage,
      limit: questionsPerPage,
      categoryId: String(categoryId),
    },
  });

  useEffect(() => {
    refetch();
    if (data?.getAllQuestion?.questions) {
      setCatName(data.getAllQuestion.questions?.[0]?.category?.name || "");
      setQuestions(data.getAllQuestion.questions);
    }
  }, [data]);

  const totalPages = data?.getAllQuestion?.totalCount
    ? Math.ceil(data.getAllQuestion.totalCount / questionsPerPage)
    : 0;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <PracticeLayout>
        <div className="flex items-center justify-center h-screen">
          <p className="">Loading...</p>
        </div>
      </PracticeLayout>
    );
  }

  return (
    <PracticeLayout>
      <div className="p-8 max-md:p-5">
        <button
          onClick={() => window.history.back()}
          className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
        >
          <span className="gap-2 text-sm font-medium flex items-center">
            <IoMdArrowRoundBack className="h-6 w-6" />
            Go Back
          </span>
        </button>
        <h2 className="text-3xl font-bold text-center w-full max-md:text-2xl mt-5">
          {catName}
        </h2>
        <div className="mt-8">
          {questions.length === 0 ? (
            <div className="text-center text-gray-500">
              No questions available
            </div>
          ) : (
            questions.map((question, index) => (
              <div key={question?._id}>
                <StudentQuestionCard
                  data={question}
                  clusterid={clusterid}
                  courseId={courseId}
                  catName={catName}
                />
              </div>
            ))
          )}
        </div>
        <div className="flex justify-between mt-4">
          <Button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="p-0 bg-primary rounded-md"
          >
            Previous
          </Button>
          <span className="text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="p-0 bg-primary rounded-md"
          >
            Next
          </Button>
        </div>
      </div>
    </PracticeLayout>
  );
};

export default StudentQuestionListing;
