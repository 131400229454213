import { Spinner, Table } from "flowbite-react";
import { FaMedal } from "react-icons/fa";
import { useGetStudentByEmailIdQuery } from "../../../graphql/generated.tsx";
import { SkeletonRow } from "./LeaderBoardSkeleton.jsx";

export function AssignmentsTable({ assignments, assignmentInfo }) {
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const {
    data: studentData,
    loading: studentLoading,
    error: studentError,
  } = useGetStudentByEmailIdQuery({
    variables: { email },
  });


  const getPositionIcon = (position) => {
    const positionColors = {
      1: "text-yellow-400",
      2: "text-gray-400",
      3: "text-orange-400",
    };

    return (
      <div className="flex items-center gap-2 text-center justify-center">
        {position}
        {position <= 3 && <FaMedal className={positionColors[position]} />}
      </div>
    );
  };

  const renderProgressSegments = (assign) => {
    const completedQuestions = assign?.questions?.filter(
      (question) => question.score === question.AllotedTotalMarks
    ).length;

    const totalQuestions = assign?.totalNumberOfQuestion || 1;
    const completionRate = completedQuestions / totalQuestions;

    const segmentCount = 3;
    const filledSegments = Math.round(completionRate * segmentCount);

    return (
      <div className="flex space-x-1 items-center justify-center">
        {[...Array(segmentCount)].map((_, index) => (
          <div
            key={index}
            className={`w-6 h-1.5 rounded-full ${
              index < filledSegments ? "bg-primary" : "bg-gray-200"
            }`}
          />
        ))}
      </div>
    );
  };

  if (studentError) {
    return (
      <div className="flex justify-center items-center my-10">
        <p className="text-red-500">Error loading student data.</p>
      </div>
    );
  }

  if (!assignments || assignments.length === 0) {
    return (
      <div className="my-10 bg-white py-5 shadow-sm rounded-lg">
        <h2 className="font-semibold mb-3 ml-5 text-xl">
          {assignmentInfo?.name}
        </h2>
        <p className="text-gray-500 text-center my-5 w-full">
          No records found.
        </p>
      </div>
    );
  }

  const studentId = studentData?.getStudentByEmailId?.id;
  const leaderboard = assignments;

  const studentIndex = leaderboard?.findIndex(
    (stud) => stud.student.id === studentId
  );

  const topFivePositions = leaderboard?.slice(0, 5);
  const studentsToShow =
    studentIndex !== -1
      ? leaderboard?.slice(
          Math.max(studentIndex - 5, 0),
          Math.min(studentIndex + 6, leaderboard.length)
        )
      : leaderboard.slice(0, 10);

  return (
    <div className="overflow-x-auto my-10 bg-white pt-5 shadow-sm rounded-lg max-md:h-full">
      <h2 className="font-semibold mb-3 text-2xl text-center">
        {assignmentInfo.name}
      </h2>
      <h3 className="font-semibold mb-3 ml-5 text-xl">Top 5 Students</h3>
      <Table className="min-w-full text-sm text-left text-gray-500 shadow-none mb-8">
        <Table.Head className="bg-gray-100">
          {[
            "Position",
            "Name",
            "Roll No",
            "Points",
            "Questions Attended",
            "Questions Completed",
            "Batch Enrolled",
            "Progress",
          ].map((heading) => (
            <Table.HeadCell className="py-3 px-6 text-center" key={heading}>
              {heading}
            </Table.HeadCell>
          ))}
        </Table.Head>
        <Table.Body className="bg-white divide-y divide-gray-200">
          {topFivePositions.map((assign, index) => {
            const questionAttended = assign?.questions.filter(
              (question) => question?.score > 0
            ).length;
            const questionCompleted = assign.questions.filter(
              (question) => question?.score === question?.AllotedTotalMarks
            ).length;

            return (
              <Table.Row
                key={assign.student?.id || index}
                className={`${
                  assign?.student?.id === studentId
                    ? "shadow-[0_0px_20px_-3px_rgb(0_0_0/0.2)] relative text-center"
                    : "bg-white text-center"
                }`}
              >
                <Table.Cell className="text-center">
                  {getPositionIcon(assign?.position)}
                </Table.Cell>
                <Table.Cell className="py-3 font-medium text-gray-900">
                  {assign.student?.name || "Unknown"}
                </Table.Cell>
                <Table.Cell className="py-3">
                  {assign.student?.rollNo || "N/A"}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {assign?.totalScore || 0}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {questionAttended}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {questionCompleted}/{assign?.totalNumberOfQuestion}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {assign.clusterNames}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {renderProgressSegments(assign)}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {studentIndex > 4 && (
        <div>
          <h3 className="font-semibold mb-3 ml-5 text-xl">Your Position</h3>
          <Table className="min-w-full text-sm text-left text-gray-500 shadow-none">
            <Table.Head className="bg-gray-100">
              {[
                "Position",
                "Name",
                "Roll No",
                "Points",
                "Questions Attended",
                "Questions Completed",
                "Batch Enrolled",
                "Progress",
              ].map((heading) => (
                <Table.HeadCell className="py-3 px-6 text-center" key={heading}>
                  {heading}
                </Table.HeadCell>
              ))}
            </Table.Head>
            <Table.Body className="bg-white divide-y divide-gray-200">
              {studentsToShow.map((assign, index) => {
                const questionAttended = assign.questions.filter(
                  (question) => question.score > 0
                ).length;
                const questionCompleted = assign.questions.filter(
                  (question) => question.score === question.AllotedTotalMarks
                ).length;

                return (
                  <Table.Row
                    key={assign.student?.id || index}
                    className={`${
                      assign.student?.id === studentId
                        ? "shadow-[0_0px_20px_-3px_rgb(0_0_0/0.2)] relative"
                        : "bg-white"
                    }`}
                  >
                    <Table.Cell>{getPositionIcon(assign.position)}</Table.Cell>
                    <Table.Cell
                      className={`py-3  text-gray-900 text-center ${
                        assign.student?.id === studentId
                          ? "font-bold"
                          : "font-medium"
                      }`}
                    >
                      {assign.student?.name || "Unknown"}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {assign.student?.rollNo || "N/A"}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {assign.totalScore || 0}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {questionAttended}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {questionCompleted}/{assign?.totalNumberOfQuestion}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {assign.clusterNames}
                    </Table.Cell>
                    <Table.Cell className="py-3 text-center">
                      {renderProgressSegments(assign)}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
}
