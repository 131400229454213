import { Route } from "react-router-dom";
import Campus from "../../Modules/Admin/Screens/Manage_Campus/Campus";
import CampusDetails from "../../Modules/Admin/Screens/Manage_Campus/CampusDetails";

const ManageCampus = [
  <Route key="campus" path="admin/campus" element={<Campus />} />,
  <Route
    key="campus-details"
    path="admin/campus/:id"
    element={<CampusDetails />}
  />,
];

export default ManageCampus;
