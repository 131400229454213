import React from "react";
import { Route } from "react-router-dom";
import HomePage from "../Modules/HomePage";
import Footer from "../Modules/Footer";
import Practice from "../Modules/Practice";
import PractiseClusterListing from "../Modules/Practice/Screens/PractiseClusterListing";

const publicRoutes = [
  <Route path="/MainPage" element={<Footer />} />,
  // <Route path="/practice" element={<PractiseClusterListing />} />,
];

export default publicRoutes;
