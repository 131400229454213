import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Spinner, Table } from "flowbite-react";
import {
  useGetClusterQuery,
  useGetLeaderboardQuery,
} from "../../../graphql/generated.tsx";
import formatDate from "../../Admin/Helpers/formatDate.jsx";
import { useNavigate } from "react-router-dom";
import { MdOutlinePersonOff } from "react-icons/md";

const LeaderboardStaff = ({
  clusterId,
  campusName,
  refetch,
  filter,
  leaderboardLoading,
  leaderboardError,
  leaderboardData,
}) => {
  useEffect(() => {
    refetch({ campusName, clusterId, filter });
  }, [filter, campusName, clusterId, refetch]);

  if (leaderboardLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (leaderboardError) {
    return (
      <div className="text-center text-red-600">
        <p>Error: {leaderboardError.message}</p>
      </div>
    );
  }

  const leaderboard = leaderboardData?.getLeaderboard || [];
  const top10Students = leaderboard.slice(0, 10);
  const least10Students = leaderboard.length > 10 ? leaderboard.slice(-10) : [];

  return (
    <div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8 my-5">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex w-full items-center justify-end">
          {/* <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
            {clusterData?.getCluster?.name}'s Leaderboard
          </h3> */}
        </div>
      </div>

      {/* Table for Top 10 Students */}
      {leaderboardData?.getLeaderboard.length != 0 ? (
        <>
          <h4 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Top {top10Students.length} Students
          </h4>

          <div className="mt-4 flex flex-col">
            <div className="overflow-x-auto rounded-sm">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow sm:rounded-lg">
                  <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 border text-center">
                    <Table.Head className="bg-gray-50 dark:bg-gray-700">
                      <Table.HeadCell>Email</Table.HeadCell>
                      <Table.HeadCell>Last Submission</Table.HeadCell>
                      <Table.HeadCell>Points</Table.HeadCell>
                      <Table.HeadCell>Questions Completed</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="bg-white dark:bg-gray-800">
                      {top10Students.length > 0 ? (
                        top10Students.map((student, index) => (
                          <Table.Row key={index} className="hover:bg-gray-50">
                            <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white">
                              {student.student.email}
                            </Table.Cell>
                            <Table.Cell className="text-sm font-semibold text-gray-900 dark:text-white">
                              {formatDate(student.latestSubmissionDate)}
                            </Table.Cell>
                            <Table.Cell className="text-sm font-normal text-gray-500 dark:text-gray-400">
                              {student.totalScore}
                            </Table.Cell>
                            <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white">
                              {student.student.completedQuestions?.length || 0}
                            </Table.Cell>
                          </Table.Row>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Cell
                            colSpan="4"
                            className="text-center text-sm font-normal text-gray-500 dark:text-gray-400"
                          >
                            No data available
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          {least10Students.length != 0 && (
            <>
              {" "}
              <h4 className="mt-8 mb-4 text-xl font-bold text-gray-900 dark:text-white">
                Least 10 Students
                <span className="font-extralight text-sm">
                  {" "}
                  (Need to be monitored)
                </span>
              </h4>
              <div className="mt-4 flex flex-col">
                <div className="overflow-x-auto rounded-sm">
                  <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow sm:rounded-lg">
                      <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 border text-center">
                        <Table.Head className="bg-gray-50 dark:bg-gray-700">
                          <Table.HeadCell>Email</Table.HeadCell>
                          <Table.HeadCell>Last Submission</Table.HeadCell>
                          <Table.HeadCell>Points</Table.HeadCell>
                          <Table.HeadCell>Questions Completed</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="bg-white dark:bg-gray-800">
                          {least10Students.length > 0 ? (
                            least10Students.map((student, index) => (
                              <Table.Row
                                key={index}
                                className="hover:bg-gray-50"
                              >
                                <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white">
                                  {student.student.email}
                                </Table.Cell>
                                <Table.Cell className="text-sm font-semibold text-gray-900 dark:text-white">
                                  {formatDate(student.latestSubmissionDate)}
                                </Table.Cell>
                                <Table.Cell className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                  {student.totalScore}
                                </Table.Cell>
                                <Table.Cell className="text-sm font-normal text-gray-900 dark:text-white">
                                  {student.student.completedQuestions?.length ||
                                    0}
                                </Table.Cell>
                              </Table.Row>
                            ))
                          ) : (
                            <Table.Row>
                              <Table.Cell
                                colSpan="4"
                                className="text-center text-sm font-normal text-gray-500 dark:text-gray-400"
                              >
                                No data available
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-[500px] text-center">
          <MdOutlinePersonOff className="text-7xl text-gray-400 mb-4" />
          <h2 className="text-2xl font-bold text-gray-600 mb-2">
            Oops, No students are enrolled in this batch
          </h2>
          <p className="text-gray-500 mb-6 lg:w-[500px]">
            It looks like we couldn't find any matching data. Try refining the
            filter options.
          </p>
        </div>
      )}
    </div>
  );
};

export default LeaderboardStaff;
