import React, { useEffect, useState } from "react";
import {
  useFindAssignmentsForStudentWithFilterLazyQuery,
  useGetAssignmentsForStudentQuery,
  useGetAssignmentSubmitLazyQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import { Card, Spinner, Button, Label } from "flowbite-react";
import {
  FaBook,
  FaCalendarAlt,
  FaCheck,
  FaCheckCircle,
  FaSearch,
} from "react-icons/fa";
import formatDate from "../../Admin/Helpers/formatDate.jsx";
import { useNavigate } from "react-router-dom";
import StudentLayout from "../../Student/Layout/index.jsx";
import Dropdown from "react-multilevel-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSolidDownArrow } from "react-icons/bi";

const Assignments = () => {
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const [filterApplied, setFilterApplied] = useState("week");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("pending");
  const [filterType, setFilterType] = useState("week");
  const [customDateRange, setCustomDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [customDateRangeApplied, setCustomDateRangeApplied] = useState(false);
  const [completedAssignmentMap, setCompletedAssignmentMap] = useState({});
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [isFilteringComplete, setIsFilteringComplete] = useState(false); // New State

  const navigate = useNavigate();

  const { data: student, loading: studentLoading } =
    useGetStudentByEmailIdQuery({
      variables: { email },
    });

  const { data: campus } = useGetCampusQuery({
    variables: { id: student?.getStudentByEmailId?.campusId },
    skip: !student?.getStudentByEmailId,
  });

  const [
    getAssignments,
    { data: assignmentsData, loading: assignmentsLoadingLazy },
  ] = useFindAssignmentsForStudentWithFilterLazyQuery();
  const [getAssignmentSubmit, { loading }] = useGetAssignmentSubmitLazyQuery();
  const { data: TotalAssignments, loading: assignmentsLoading } =
    useGetAssignmentsForStudentQuery({
      variables: { email },
    });

  // Fetch assignments based on filterType and customDateRange
  useEffect(() => {
    if (email) {
      setIsFilterLoading(true);
      getAssignments({
        variables: {
          email,
          filterType: customDateRangeApplied ? "custom" : filterType,
          customDateRange: customDateRangeApplied ? customDateRange : null,
        },
      });
    }
  }, [email, filterType, customDateRangeApplied, getAssignments]);

  // Fetch submission status for assignments
  useEffect(() => {
    setIsFilterLoading(true);
    setIsFilteringComplete(false); // Set filtering not complete initially

    if (student && (assignmentsData || TotalAssignments)) {
      let assignmentsToCheck = [];

      if (statusFilter === "completed") {
        assignmentsToCheck =
          assignmentsData?.findAssignmentsForStudentWithFilter.filter(
            (assignment) => completedAssignmentMap[assignment.id] !== true
          ) || [];
      } else {
        assignmentsToCheck =
          TotalAssignments?.getAssignmentsForStudent?.filter(
            (assignment) => completedAssignmentMap[assignment.id] === undefined
          ) || [];
      }

      const assignmentPromises = assignmentsToCheck?.map((assignment) =>
        getAssignmentSubmit({
          variables: {
            assignmentId: assignment.id,
            studentId: student.getStudentByEmailId.id,
            campus_name: campus?.campus?.name,
          },
        }).then((result) => {
          setCompletedAssignmentMap((prev) => ({
            ...prev,
            [assignment.id]: result.data?.getAssignmentSubmit?.completed,
          }));
        })
      );

      // Wait for all assignment checks to complete before setting filtering to complete
      Promise.all(assignmentPromises).then(() => {
        setIsFilteringComplete(true); // Mark filtering as complete
        setIsFilterLoading(false); // Stop loading spinner
      });
    }
  }, [
    student,
    assignmentsData,
    TotalAssignments,
    statusFilter,
    getAssignmentSubmit,
  ]);

  useEffect(() => {
    if (statusFilter === "completed") {
      setFilteredAssignments(
        assignmentsData?.findAssignmentsForStudentWithFilter?.filter(
          (assignment) => completedAssignmentMap[assignment.id] === true
        ) || [] // Ensure it falls back to an empty array
      );
    } else {
      setFilteredAssignments(
        TotalAssignments?.getAssignmentsForStudent?.filter(
          (assignment) => completedAssignmentMap[assignment.id] !== true
        ) || [] // Ensure it falls back to an empty array
      );
    }
    setIsFilterLoading(false);
  }, [
    statusFilter,
    assignmentsData,
    TotalAssignments,
    completedAssignmentMap,
    getAssignmentSubmit,
  ]);

  const handleFilterChange = (type) => {
    setCustomDateRangeApplied(false);
    setFilterType(type);
    setFilterApplied(type);
    setCustomDateRange({
      startDate: null,
      endDate: null,
    });
    setIsDropdownOpen(false);
  };

  const handleCustomDateRangeChange = (field, value) => {
    setFilterApplied("custom");
    setCustomDateRange((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const applyCustomFilter = () => {
    if (customDateRange.startDate && customDateRange.endDate) {
      setCustomDateRangeApplied(true);
      setIsDropdownOpen(false);
    }
  };

  // Display loading spinner until all filtering and fetching are complete
  if (
    studentLoading ||
    assignmentsLoading ||
    isFilterLoading ||
    loading ||
    assignmentsLoadingLazy ||
    !isFilteringComplete // Ensure filtering is complete
  ) {
    return (
      <StudentLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="lg" />
        </div>
      </StudentLayout>
    );
  }
  let title;
  if (filterApplied === "week") {
    title = "Last Week";
  } else if (filterApplied === "month") {
    title = "Last Month";
  } else {
    title = "Custom Range";
  }
  return (
    <StudentLayout>
      <div className="m-10 space-y-8 max-md:ml-12">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold max-md:text-3xl">Assignments</h1>
          <p className="text-black mt-2">
            Track your pending and completed assignments
          </p>
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8 space-y-4 sm:space-y-0">
          <div className="flex space-x-4">
            <div className="flex bg-white rounded-md border border-primary">
              <button
                className={`px-4 py-1.5 rounded-md ${
                  statusFilter === "pending" ? "bg-primary text-white" : ""
                }`}
                onClick={() => setStatusFilter("pending")}
              >
                Pending
              </button>
              <button
                className={`px-4 py-1.5 rounded-md ${
                  statusFilter === "completed" ? "bg-primary text-white" : ""
                }`}
                onClick={() => setStatusFilter("completed")}
              >
                Completed
              </button>
            </div>
          </div>

          {statusFilter === "completed" && (
            <div>
              <Dropdown
                title={
                  <span className="flex items-center justify-between">
                    {title}
                    <BiSolidDownArrow className="ml-2 h-3 w-4 mt-0.5" />
                  </span>
                }
                className="p-2 border border-primary rounded-md bg-white text-[#2C5DE5]"
                open={isDropdownOpen}
                position="middle"
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
              >
                <Dropdown.Item
                  onClick={() => handleFilterChange("week")}
                  isActive={filterApplied === "week"}
                  className={`flex items-center hover:bg-transparent hover:text-current ${
                    filterApplied === "week" ? "bg-primary text-white" : ""
                  }`}
                >
                  Last Week
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => handleFilterChange("month")}
                  isActive={filterApplied === "month"}
                  className={`flex items-center hover:bg-transparent hover:text-current ${
                    filterApplied === "month" ? "bg-primary text-white" : ""
                  }`}
                >
                  Last Month
                </Dropdown.Item>

                <Dropdown.Item
                  isActive={filterApplied === "custom"}
                  onClick={(e) => e.stopPropagation()}
                  className={`flex items-center hover:bg-transparent hover:text-current ${
                    filterApplied === "custom" ? "bg-primary text-white" : ""
                  }`}
                >
                  Custom Range
                  <Dropdown.Submenu title="Custom Date Range">
                    <div className="flex flex-col p-4 w-[300px]">
                      <Label className="mb-1">From: </Label>
                      <DatePicker
                        selected={customDateRange.startDate}
                        onChange={(date) =>
                          handleCustomDateRangeChange("startDate", date)
                        }
                        className="border border-gray-300 rounded  w-full mb-3"
                        placeholderText="Start Date"
                        dateFormat="yyyy-MM-dd"
                      />
                      <Label className="mb-1">To: </Label>
                      <DatePicker
                        selected={customDateRange.endDate}
                        onChange={(date) =>
                          handleCustomDateRangeChange("endDate", date)
                        }
                        className="border border-gray-300 rounded p-2 w-full mb-3"
                        placeholderText="End Date"
                        dateFormat="yyyy-MM-dd"
                      />
                      <Button
                        onClick={applyCustomFilter}
                        className="w-full bg-primary text-white"
                      >
                        Apply
                      </Button>
                    </div>
                  </Dropdown.Submenu>
                </Dropdown.Item>
              </Dropdown>
            </div>
          )}
        </div>

        {/* Assignments Grid */}
        {filteredAssignments?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredAssignments?.map((assignment) => (
              <Card
                key={assignment.id}
                className="hover:shadow-lg cursor-pointer transition-transform transform hover:scale-105 rounded-xl"
                onClick={() => navigate(`/assignments/${assignment.id}`)}
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <FaBook className="text-blue-500 mr-2" />
                    <h2 className="text-xl font-semibold">{assignment.name}</h2>
                  </div>
                  {completedAssignmentMap[assignment.id] && (
                    <FaCheckCircle className="text-green-500" />
                  )}
                </div>
                <div className="mb-4">
                  <span className="text-gray-700">
                    {assignment.short_description}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-gray-500">
                    <FaCalendarAlt className="mr-2" />
                    <span>{formatDate(assignment.due_date)}</span>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[500px] text-center">
            <FaSearch className="text-7xl text-gray-400 mb-4" />
            <h2 className="text-2xl font-bold text-gray-600 mb-2">
              Oops, No Results Found
            </h2>
            <p className="text-gray-500 mb-6 lg:w-[500px]">
              It looks like we couldn't find any matching data. Try refining the
              filter options.
            </p>
          </div>
        )}
      </div>
    </StudentLayout>
  );
};

export default Assignments;
