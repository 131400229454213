import React, { useState, useEffect } from "react";
import { CommandLineIcon } from "@heroicons/react/24/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import AdminLayout from "../../Layout/index.jsx";
import EditCourse from "../../Components/EditCourseModal.jsx";
import { FaEdit } from "react-icons/fa";
import { IoMdAdd, IoMdArrowRoundBack } from "react-icons/io";
import { useParams } from "react-router-dom";
import CategoryCard from "../../Components/CategoryCard.jsx";
import {
  useGetAdminUsingEmailQuery,
  useGetCourseQuery,
} from "../../../../graphql/generated.tsx";
import { RiQuestionAnswerFill, RiQuestionnaireFill } from "react-icons/ri";

const CourseDetails = () => {
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: admin } = useGetAdminUsingEmailQuery({
    variables: { email },
    skip: !email,
  });

  const { data, loading } = useGetCourseQuery({ variables: { id } });
  useEffect(() => {
    setCourseData(data?.getCourse);
  }, [data]);

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p className="">Loading...</p>
        </div>
      </AdminLayout>
    );
  }
  return (
    <AdminLayout>
      <div className="p-8 max-md:p-5">
        <button
          onClick={() => window.history.back()}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <span className="gap-2 text-sm font-medium flex items-center">
            <IoMdArrowRoundBack className="h-6 w-6" />
            Go Back
          </span>
        </button>
        <div className="px-6 py-8 rounded-md shadow-sm bg-white relative mt-5">
          {admin?.getAdminUsingEmail?.isSuperAdmin && (
            <FaEdit
              className="absolute top-7 right-5 h-6 w-6 text-gray-500 hover:text-primary cursor-pointer"
              onClick={handleEditClick}
            />
          )}

          {showModal && (
            <EditCourse
              id={id}
              onClose={handleCloseModal}
              courseData={courseData}
              setCourseData={setCourseData}
            />
          )}
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <div className="inline-block bg-orange-100 p-6 rounded-lg max-md:w-1/2">
              <CommandLineIcon className="h-12 w-12 text-orange-500" />
            </div>
            <div className="md:max-w-screen-sm">
              <div className="font-bold text-xl mb-2">{courseData?.name}</div>
              <div className="text-slate-500 text-sm">
                {courseData?.short_description}
              </div>
            </div>
          </div>
          <hr className="max-w-screen-md my-6" />
          <div className="flex gap-32 flex-wrap">
            <div className="flex flex-col items-center justify-center">
              <ClockIcon className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">Duration</span>
              <span className="font-bold">{courseData?.time_period} hours</span>
            </div>
            <div className="flex flex-col items-center justify-center">
              <RiQuestionAnswerFill className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">
                Total Questions
              </span>
              <span className="font-bold">
                {courseData?.question_ids?.length}
              </span>
            </div>
            <div className="flex flex-col items-center justify-center">
              <RiQuestionnaireFill className="w-5 h-5 text-green-500" />
              <span className="text-sm text-slate-500 mb-1">
                Easy Questions
              </span>
              <span className="font-bold">{courseData?.easy_count}</span>
            </div>
            <div className="flex flex-col items-center justify-center">
              <RiQuestionnaireFill className="w-5 h-5 text-yellow-500" />
              <span className="text-sm text-slate-500 mb-1">
                Medium Questions
              </span>
              <span className="font-bold">{courseData?.medium_count}</span>
            </div>
            <div className="flex flex-col items-center justify-center">
              <RiQuestionnaireFill className="w-5 h-5 text-red-500" />
              <span className="text-sm text-slate-500 mb-1">
                Hard Questions
              </span>
              <span className="font-bold">{courseData?.hard_count}</span>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <Link
            to={`/admin/courses/${id}/add-question`}
            className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary"
          >
            <IoMdAdd className="mr-1 mt-1" /> <span>Add Question</span>
          </Link>
        </div>
        <div className="mt-8 bg-white p-8 max-md:p-5 rounded-md min-h-2xl">
          <h1 className="text-center text-2xl font-semibold mb-10">
            Categories
          </h1>
          {courseData?.categories?.length === 0 ? (
            <div className="text-center text-gray-500">
              No Categories available
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {courseData?.categories?.map((cat) => (
                <div key={cat._id} className="flex">
                  <CategoryCard
                    name={cat.name}
                    description={cat.description}
                    cat_id={cat.id}
                    course_id={id}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default CourseDetails;
