import React, { useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import ConformationModal from "./ConformationModal";

const CourseCard = (props) => {
  const {
    name,
    short_description,
    time_period,
    course_id,
    onRemove,
    isSuperAdmin,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);

  const handleDeleteClick = (id) => {
    setCourseToDelete(id);
    setOpenModal(true); 
  };

  const handleConfirmDelete = () => {
    if (onRemove && courseToDelete) {
      onRemove(courseToDelete); 
    }
    setOpenModal(false); 
  };

  return (
    <div className="relative">
      {isSuperAdmin && onRemove && (
        <MdDelete
          onClick={() => handleDeleteClick(course_id)}
          className="absolute top-2 right-2 h-7 w-7 hover:bg-red-500 rounded-full cursor-pointer p-1"
        />
      )}
      <Link
        to={`/admin/courses/${course_id}`}
        className={`shadow-xl rounded-lg w-[300px] min-h-[300px] bg-white flex flex-col justify-between ${
          !onRemove
            ? "hover:-translate-y-1 hover:scale-105 transition ease-in-out cursor-pointer"
            : ""
        }`}
      >
        <div className="p-6">
          <div className="text-xl font-bold mb-4">{name}</div>
          <div className="text-slate-500 text-base">{short_description}</div>
        </div>
        {time_period ? (
          <div className="py-6 pl-6 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FaRegClock /> {time_period}hrs
            </div>
          </div>
        ) : null}
      </Link>

      {/* Confirmation modal */}
      {courseToDelete && (
        <ConformationModal
          openModal={openModal}
          event={handleConfirmDelete} // Call confirm delete function
          setOpenModal={setOpenModal} // Pass setOpenModal to close the modal on cancel
          text={"Are you sure you want to delete this course?"}
        />
      )}
    </div>
  );
};

export default CourseCard;
