import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Button, Checkbox } from "flowbite-react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminLayout from "../../../Layout";
import ImportQuestionCard from "../../../Components/ImportQuestionCard";
import {
  useCreateBulkAssignmentQuestionsMutation,
  useFindAllQuestionsQuery,
  useGetAllAssignementQuestionQuery,
} from "../../../../../graphql/generated.tsx";
import { tagsData } from "../../../../../Mockdata/tags.js";
import { useNavigate } from "react-router-dom";

const difficultyLevels = ["EASY", "MEDIUM", "HARD"];

const ImportAssignmentQuestion = () => {
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState([]);
  const [page, setPage] = useState(1);
  const { assignmentid } = useParams();
  const [createBulkAssignmentQuestion] =
    useCreateBulkAssignmentQuestionsMutation();

  const { data: assignmentQuestionsData } = useGetAllAssignementQuestionQuery({
    variables: { assignmentId: assignmentid },
  });

  const { data: questionsData, refetch } = useFindAllQuestionsQuery({
    variables: {
      page,
      limit: 10,
      searchName: searchQuery,
      tags: selectedTags,
      difficulties: selectedDifficulty,
    },
  });

  useEffect(() => {
    if (questionsData && assignmentQuestionsData) {
      const assignmentQuestionTitles = new Set(
        assignmentQuestionsData.getAllAssignementQuestion.map((q) => q.title)
      );
      const filteredQuestions = questionsData.findAllQuestions.filter(
        (q) => !assignmentQuestionTitles.has(q.title)
      );
      setQuestions(filteredQuestions);
    }
  }, [questionsData, assignmentQuestionsData]);

  useEffect(() => {
    refetch();
  }, [page, searchQuery, selectedTags, selectedDifficulty, refetch]);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (questions.length === 10) {
      setPage(page + 1);
    }
  };

  const handleSearch = () => {
    refetch();
    setPage(1);
  };

  const handleCheckboxChange = (e, questionId) => {
    if (e.target.checked) {
      setSelectedQuestions([...selectedQuestions, questionId]);
    } else {
      setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
    }
  };

  const handleSave = async () => {
    const filteredQuestions = questions
      .filter((q) => selectedQuestions.includes(q.id))
      .map(({ __typename, predefinedCode, id, test_cases, ...rest }) => {
        const filteredTestCase = test_cases?.map(({ __typename, ...tc }) => tc);
        const { __typename: codeType, ...predefinedcode } =
          predefinedCode || {};
        return {
          ...rest,
          test_cases: filteredTestCase,
          assignment_id: assignmentid,
          predefinedCode: predefinedcode,
        };
      });
    try {
      await createBulkAssignmentQuestion({
        variables: {
          createQuestionsInput: filteredQuestions,
        },
      });
      toast.success("Imported Successfully");
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error in Importing");
    }
  };

  const handleTagSelect = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
    setPage(1); // Reset page to 1 when changing tags
  };

  const handleDifficultySelect = (level) => {
    if (selectedDifficulty.includes(level)) {
      setSelectedDifficulty(selectedDifficulty.filter((d) => d !== level));
    } else {
      setSelectedDifficulty([...selectedDifficulty, level]);
    }
    setPage(1); // Reset page to 1 when changing difficulty
  };

  return (
    <AdminLayout>
      <div className="flex">
        <div className="flex-grow p-8">
          <div className="flex items-center mb-6">
            <button
              onClick={() => navigate(-1)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
            >
              <span className="gap-2 text-sm font-medium flex items-center">
                <IoMdArrowRoundBack className="h-6 w-6" />
                Go Back
              </span>
            </button>
            <div className="ml-auto">
              <Button
                onClick={handleSave}
                disabled={selectedQuestions.length === 0}
                className="bg-primary text-white p-0 rounded-md"
              >
                Save
              </Button>
            </div>
          </div>
          <div className="flex justify-center mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search..."
              className="border border-gray-300 rounded-md px-2 py-1 mr-2"
            />
            <Button onClick={handleSearch} className="bg-primary p-0">
              Search
            </Button>
          </div>
          <div className="flex justify-between">
            <div className="mt-5 p-8 rounded-md bg-gray-100 w-[75%]">
              {questions?.map((assignment) => (
                <div
                  className="flex items-center w-full bg-white px-8 mb-2 justify-center py-4 rounded-md shadow-md"
                  key={assignment.id}
                >
                  <Checkbox
                    className="pb-3"
                    onChange={(e) => handleCheckboxChange(e, assignment.id)}
                    checked={selectedQuestions.includes(assignment.id)}
                  />
                  <div className="w-full">
                    <ImportQuestionCard data={assignment} />
                  </div>
                </div>
              ))}
              <div className="flex justify-between mt-4 p-5">
                <Button
                  onClick={handlePrevPage}
                  disabled={page === 1}
                  className="bg-primary p-0"
                >
                  Prev
                </Button>
                <span className="text-gray-500">Page {page}</span>
                <Button
                  onClick={handleNextPage}
                  disabled={questions.length < 10}
                  className="bg-primary p-0"
                >
                  Next
                </Button>
              </div>
            </div>
            <div className="w-64 mt-5 p-8 bg-white shadow-lg rounded-lg h-[500px]">
              <div className="mb-4">
                <h3 className="font-semibold mb-2">Filter by Tags</h3>
                {tagsData.map((tag) => (
                  <div key={tag} className="flex items-center mb-2">
                    <Checkbox
                      checked={selectedTags.includes(tag)}
                      onChange={() => handleTagSelect(tag)}
                    />
                    <span className="ml-2">{tag}</span>
                  </div>
                ))}
              </div>
              <div className="mb-4">
                <h3 className="font-semibold mb-2">Filter by Difficulty</h3>
                {difficultyLevels.map((level) => (
                  <div key={level} className="flex items-center mb-2">
                    <Checkbox
                      checked={selectedDifficulty.includes(level)}
                      onChange={() => handleDifficultySelect(level)}
                    />
                    <span className="ml-2">{level}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ImportAssignmentQuestion;
