import { Route } from "react-router-dom";
import Courses from "../../Modules/Admin/Screens/Course/Courses.jsx";
import CourseDetails from "../../Modules/Admin/Screens/Course/CourseDetails";
import QuestionListing from "../../Modules/Admin/Screens/Course/Questions/QuestionListing";
import ImportQuestion from "../../Modules/Admin/Screens/Course/Questions/ImportQuestion";
import CreateQuestion from "../../Modules/Admin/Screens/Course/Questions/CreateQuestion";
import EditQuestion from "../../Modules/Admin/Screens/Course/Questions/EditQuestion";
import PreviewQuestion from "../../Modules/Admin/Screens/Course/Questions/PreviewQuestion";
import CreateCourse from "../../Modules/Admin/Screens/Course/CreateCourse.jsx";

const CourseRoutes = [
  <Route key="courses" path="/admin/courses" element={<Courses />} />,
  <Route
    key="create-course"
    path="/admin/courses/create-course"
    element={<CreateCourse />}
  />,
  <Route
    key="course-details"
    path="/admin/courses/:id"
    element={<CourseDetails />}
  />,
  <Route
    key="question-listing"
    path="/admin/courses/:course_id/:cat_id"
    element={<QuestionListing />}
  />,
  <Route
    key="import-question"
    path="/admin/courses/:course_id/:cat_id/import-question"
    element={<ImportQuestion />}
  />,
  <Route
    key="create-question"
    path="/admin/courses/:id/add-question"
    element={<CreateQuestion />}
  />,
  <Route
    key="edit-question"
    path="/admin/courses/:courseid/edit-question/:questionid"
    element={<EditQuestion />}
  />,
  <Route
    key="preview-question"
    path="/admin/courses/preview-question/:courseId/:questionId"
    element={<PreviewQuestion />}
  />,
];

export default CourseRoutes;
