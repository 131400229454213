import React, { useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ConformationModal from "./ConformationModal";

const AssignmentCard = ({
  assignment_id,
  name,
  description,
  due_date,
  onRemove,
}) => {
  // State to manage the open/close status of the modal
  const [openModal, setOpenModal] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState(null);

  // Function to handle delete with confirmation modal
  const handleDeleteClick = (id) => {
    setAssignmentToDelete(id);
    setOpenModal(true); // Open the modal when delete is clicked
  };

  const handleConfirmDelete = () => {
    if (onRemove && assignmentToDelete) {
      onRemove(assignmentToDelete); // Call the onRemove function when confirmed
    }
    setOpenModal(false); // Close the modal after confirmation
  };

  return (
    <div className="relative">
      {onRemove && (
        <MdDelete
          onClick={() => handleDeleteClick(assignment_id)} // Open modal on delete click
          className="absolute top-2 right-2 h-7 w-7 hover:bg-red-500 rounded-full cursor-pointer p-1"
        />
      )}
      <div className="shadow-xl rounded-lg w-[300px] min-h-[300px] bg-white flex flex-col justify-between">
        <div className="p-6">
          <div className="text-xl font-bold mb-4">{name}</div>
          <div className="text-slate-500 text-base">{description}</div>
        </div>
        {due_date ? (
          <div className="py-6 pl-6 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FaRegClock /> Due: {new Date(due_date).toLocaleDateString()}
            </div>
          </div>
        ) : null}
      </div>

      {/* Confirmation modal */}
      {assignmentToDelete && (
        <ConformationModal
          openModal={openModal}
          event={handleConfirmDelete} // Call confirm delete function
          setOpenModal={setOpenModal} // Pass setOpenModal to close the modal on cancel
          text={"Are you sure you want to delete this assignment?"}
        />
      )}
    </div>
  );
};

export default AssignmentCard;
