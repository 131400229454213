import React from "react";
import { FaRegClock } from "react-icons/fa";
import { Link } from "react-router-dom";

const CourseCard = (props) => {
  const { name, short_description, time_period, course_id, clusterId } = props;
  return (
    <Link
      to={`/student/courses/${clusterId}/${course_id}`}
      className="shadow-xl rounded-lg max-md:w-[300px] w-80 min-h-[200px] bg-white flex flex-col justify-between hover:-translate-y-1 hover:scale-105 transition ease-in-out cursor-pointer"
    >
      <div className="p-6">
        <div className="text-xl font-bold mb-4">{name}</div>
        <div className="text-slate-500 text-base">{short_description}</div>
      </div>
      {time_period ? (
        <div className="py-6 pl-6 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <FaRegClock /> {time_period}hrs
          </div>
        </div>
      ) : null}
    </Link>
  );
};

export default CourseCard;
