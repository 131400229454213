import { Button, Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import CourseCard from "../CourseCard";
import {
  useAddCoursesToClusterMutation,
  useGetAllCoursesByClusterQuery,
  useGetAllCoursesQuery,
  useRemoveCourseFromClusterMutation,
} from "../../../../graphql/generated.tsx";
import AdminLayout from "../../Layout/index.jsx";
import Select from "react-select";

const ManageCourseTab = ({ campusName, clusterId }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [addCourse, setAddCourse] = useState();
  const [courses, setCourses] = useState([]);

  const { data: allCourseData } = useGetAllCoursesQuery();
  const [addCoursesToCluster] = useAddCoursesToClusterMutation();
  const [removeCourseFromCluster] = useRemoveCourseFromClusterMutation();

  const { data: courseData, error: courseError, refetch } =
    useGetAllCoursesByClusterQuery({
      variables: { campusName, clusterId },
      onCompleted: (data) => setCourses(data.getAllCoursesByCluster),
    });

  const handleAddCourses = async () => {
    try {
      const { data: AddCourses } = await addCoursesToCluster({
        variables: {
          clusterId,
          courseIds: selectedCourses.map((c) => c.value),
          campusName,
        },
      });
      setAddCourse(AddCourses);
      refetch()
      setShowModal(false);
    } catch (error) {
      console.error("Error adding courses:", error);
    }
  };

  useEffect(() => {
    setCourses(courseData?.getAllCoursesByCluster);
  }, [addCourse, courseData]);

  const handleRemoveCourse = async (courseId) => {
    try {
      await removeCourseFromCluster({
        variables: { clusterId, courseId, campusName },
      });
      setCourses((prevCourses) =>
        prevCourses.filter((course) => course.id !== courseId)
      );
    } catch (error) {
      console.error("Error removing course:", error);
    }
  };

  
  if (courseError)
    return <AdminLayout>Error: {courseError.message}</AdminLayout>;

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      {" "}
      <div className="p-10">
        <div className="flex justify-end items-end mb-8 max-md:gap-2">
          <Button
            onClick={toggleModal}
            className="text-white p-0 rounded-lg shadow-md flex items-center bg-primary"
          >
            <IoMdAdd className="mr-1 mt-1" /> <span>Add Course</span>
          </Button>
        </div>
        <div className="flex gap-6 flex-wrap max-md:p-5">
          {courses?.map((course) => (
            <CourseCard
              key={course.id}
              course_id={course.id}
              name={course.name}
              short_description={course.short_description}
              time_period={course.time_period}
              onRemove={() => handleRemoveCourse(course.id)}
            />
          ))}
        </div>
      </div>
      <Modal show={showModal} onClose={toggleModal}>
        <Modal.Header>Add Courses</Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddCourses();
            }}
          >
            <div className="mb-4 h-[200px]">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Select Courses
              </label>
              <Select
                options={allCourseData?.getAllCourses?.map((course) => ({
                  label: course.name,
                  value: course.id,
                }))}
                isMulti
                value={selectedCourses}
                onChange={setSelectedCourses}
                placeholder="Select courses"
              />
            </div>
            <div className="flex items-center justify-between">
              <Button type="submit">Add</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageCourseTab;
