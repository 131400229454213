

export const tagsData = [
    "Arrays",
    "React",
    "GraphQL",
    "Node.js",
    "CSS",
    "HTML"
  ];
  