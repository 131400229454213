import React, { useEffect, useState } from "react";
import { Sidebar } from "flowbite-react";
import { HiChartPie, HiViewBoards } from "react-icons/hi";
import { IoBookSharp, IoSettingsOutline } from "react-icons/io5";
import { MdAssignment } from "react-icons/md";
import { Link } from "react-router-dom";
import { useGetStudentByEmailIdQuery } from "../../../graphql/generated.tsx";

function LeftSideBar() {
  const [currentPage, setCurrentPage] = useState("");

  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: student, loading: studentLoading } =
    useGetStudentByEmailIdQuery({
      variables: { email },
    });

  const studentDetails = student?.getStudentByEmailId;

  useEffect(() => {
    const newPage = window.location.pathname;
    setCurrentPage(newPage);
  }, [setCurrentPage]);

  const customTheme = {
    root: {
      base: "bg-white",
      inner: "bg-white",
    },
  };

  return (
    <div className="lg:w-12 lg:flex-shrink-0 h-full">
      <Sidebar
        collapsed={true}
        theme={customTheme}
        className="flex p-4 mt-3 fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700 bg-primary"
      >
        <Sidebar.Items>
          <Sidebar.ItemGroup className="bg-primary text-yellow-200">
            <Link to={"/dashboard"}>
              <Sidebar.Item
                icon={HiChartPie}
                className={`flex items-center justify-center   ${
                  currentPage === "/dashboard"
                    ? "bg-gray-200"
                    : "hover:bg-primary"
                }`}
              >
                Dashboard
              </Sidebar.Item>
            </Link>

            {studentDetails?.clusterIds.length > 0 && (
              <Link
                to={
                  studentDetails?.clusterIds.length === 1
                    ? `/student/courses/${studentDetails?.clusterIds[0]}`
                    : "/student/courses"
                }
              >
                <Sidebar.Item
                  icon={IoBookSharp}
                  className={`flex items-center justify-center ${
                    currentPage.startsWith("/student/courses")
                      ? "bg-gray-200"
                      : "hover:bg-primary"
                  }`}
                >
                  Courses
                </Sidebar.Item>
              </Link>
            )}

           
              <>
                <Link to={"/assignments"}>
                  <Sidebar.Item
                  
                    icon={HiViewBoards}
                    className={`flex items-center justify-center ${
                      currentPage.startsWith("/assignments")
                        ? "bg-gray-200"
                        : "hover:bg-primary"
                    }`}
                  >
                    Assignments
                  </Sidebar.Item>
                </Link>

                <Link to={"/student/assignment-leaderboard"}>
                  <Sidebar.Item
                    icon={MdAssignment}
                    className={`flex items-center justify-center ${
                      currentPage.startsWith("/student/assignment-leaderboard")
                        ? "bg-gray-200"
                        : "hover:bg-primary"
                    }`}
                  >
                    Leaderboard
                  </Sidebar.Item>
                </Link>
              </>

            <Link to={"/student/my-profile"}>
              <Sidebar.Item
                icon={IoSettingsOutline}
                className={`flex items-center justify-center ${
                  currentPage.startsWith("/student/my-profile")
                    ? "bg-gray-200"
                    : "hover:bg-primary"
                }`}
              >
                Settings
              </Sidebar.Item>
            </Link>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
}

export default LeftSideBar;
