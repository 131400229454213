import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout";
import {
  useAddAssignmentToStudentMutation,
  useGetStudentsByCampusQuery,
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import { Pagination, Table, Button, Checkbox } from "flowbite-react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";

const StudentListing = () => {
  const { campusId, assignmentId } = useParams();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [addAssignmentToStudent] = useAddAssignmentToStudentMutation();
  const limit = 10;
  const { loading, error, data, refetch } = useGetStudentsByCampusQuery({
    variables: { campusId, page, limit, searchTerm: debouncedSearchTerm },
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPage(1); 
      refetch({ campusId, page, limit, searchTerm }); 
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, refetch, campusId, limit]);

  const handleCheckboxChange = (studentId) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleSelectAllChange = () => {
    if (selectedStudents.length === filteredStudents.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(filteredStudents.map((student) => student.id));
    }
  };

  const handleAddAssignment = async () => {

    try {
      await addAssignmentToStudent({
        variables: {
          addAssignmentToStudentInput: { ids: selectedStudents, assignmentId },
        },
      });
      toast.success("Students Added");
    } catch (error) {
      console.error(error);
      toast.error("Error in Assigning to Student");
    }
  };

  if (loading)
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          Loading...
        </div>
      </AdminLayout>
    );
  if (error)
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          Error: {error.message}
        </div>
      </AdminLayout>
    );

  // Filter students who don't have the assignmentId
  const filteredStudents = data?.getStudentsByCampus.students.filter(
    (student) => !student.assignmentIds.includes(assignmentId)
  );

  const totalPages = Math.ceil(filteredStudents.length / limit);

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              Select Students
            </div>
          </div>
          <input
            type="text"
            className="border border-gray-200 rounded px-4 py-2"
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            onClick={handleAddAssignment}
            disabled={selectedStudents.length === 0}
          >
            Add Assignment to Selected Students
          </Button>
        </div>

        <div className="overflow-x-auto shadow-md sm:rounded-lg mt-10">
          <Table className="min-w-full divide-y divide-gray-200 ">
            <Table.Head className="bg-gray-100">
              <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <Checkbox
                  checked={selectedStudents.length === filteredStudents.length}
                  onChange={handleSelectAllChange}
                />
              </Table.HeadCell>
              <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </Table.HeadCell>
              <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </Table.HeadCell>
              <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                RollNo
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="bg-white divide-y divide-gray-200">
              {filteredStudents.map((student) => (
                <Table.Row
                  key={student.id}
                  className="hover:bg-gray-100 cursor-pointer"
                >
                  <Table.Cell className="px-6 py-4 whitespace-nowrap">
                    <Checkbox
                      checked={selectedStudents.includes(student.id)}
                      onChange={() => handleCheckboxChange(student.id)}
                    />
                  </Table.Cell>
                  <Table.Cell className="px-6 py-4 whitespace-nowrap">
                    {student.name || "N/A"}
                  </Table.Cell>
                  <Table.Cell className="px-6 py-4 whitespace-nowrap">
                    {student.email}
                  </Table.Cell>
                  <Table.Cell className="px-6 py-4 whitespace-nowrap">
                    {student.rollNo}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
              refetch({
                campusId,
                page: newPage,
                limit,
                searchTerm: debouncedSearchTerm,
              });
            }}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default StudentListing;
