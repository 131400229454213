import React from "react";

const ErrorModel = ({ error, language, runtimeError }) => {
  const extractErrorMessage = (errorString) => {
    let regex;
    if (language === "JAVA") {
      regex = /\/.*\/.*\/.*\/.*\.java:(\d+):.*error:\s(.*)/i;
    } else if (language === "C") {
      regex = /:(\d+):\d+: error: (.*)/i;
    }

    const match = errorString?.match(regex);
    if (match) {
      const lineNumber = match[1];
      const errorMessage = match[2];
      return `Line ${lineNumber}: ${errorMessage}`;
    } else {
      return "There could be some logic error in your code or a network connectivity issue. Kindly try again later.";
    }
  };

  const extractedMessage =error
  return (
    <div className="bg-white shadow-md p-5 mt-10">
      <h3 className="text-xl font-semibold mt-4">Error Message</h3>
      <div>
        <div className="mt-1 bg-red-100 p-4 text-red-500">
          {extractedMessage}
        </div>
      </div>
    </div>
  );
};

export default ErrorModel;
