import React from "react";
import { useNavigate } from "react-router-dom";

const TestScreenQuestionCard = ({ data, completedQuestions }) => {
  const navigate = useNavigate();

  const getDifficultyColor = (difficulty) => {
    switch (difficulty) {
      case "EASY":
        return "bg-green-200 text-gray-800";
      case "MEDIUM":
        return "bg-yellow-100 text-gray-800";
      case "HARD":
        return "bg-red-100 text-gray-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const difficulty =
    data?.difficulty?.charAt(0).toUpperCase() +
    data?.difficulty?.slice(1).toLowerCase();

  // Check if the question is completed
  const isCompleted = completedQuestions?.find(
    (ques) => ques.question_id === data.id
  );

  return (
    <div
      className={`relative cursor-pointer p-4 rounded-lg shadow-md bg-white`}
      onClick={() => {
        navigate(`${data.id}`);
      }}
    >
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
        <div className="font-bold text-xl">{data.title}</div>
        {data?.difficulty && (
          <div
            className={`mt-2 md:mt-0 p-1 w-16 text-xs text-center font-light rounded-md ${getDifficultyColor(
              data.difficulty
            )}`}
          >
            {difficulty}
          </div>
        )}
      </div>
      <div className="mb-2">
        <p className="text-gray-500 text-sm">Max Score: {data.marks}</p>
      </div>
      <div className="flex flex-wrap gap-1">
        {data?.tags &&
          data.tags.map((tag, index) => (
            <span
              key={index}
              className="inline-block bg-blue-100 text-blue-800 rounded px-2 py-1 text-xs font-medium mt-1"
            >
              {tag}
            </span>
          ))}
      </div>
      {isCompleted && (
        <div className="absolute bottom-3 right-2 bg-green-100 text-green-800 px-2 py-1 text-xs font-bold rounded">
          Completed
        </div>
      )}
    </div>
  );
};

export default TestScreenQuestionCard;
