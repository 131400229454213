export const pythonTooltipContent = `
  <pre>
  Fill the function code in the following format:
  
  Python:
  def targetFunctionName(args): 
      # function body
  </pre>
  `;

  export const cTooltipContent = `
  <pre>
  Fill the function code in the following format:
  
  C:
  int targetFunctionName(args) {
      // function body
  }
  </pre>
  `;

  export const javaTooltipContent = `
  <pre>
  Fill the function code in the following format:
  
  Java:
  public static returnType targetFunctionName(args) {
      // function body
  }
  </pre>
  `;

  export const cppTooltipContent = `
  <pre>
  Fill the function code in the following format:
  
  C++:
  int targetFunctionName(args) {
      // function body
  }
  </pre>
  `;