import React from "react";
import NavBar from "../../../Common/NavBar";

const AssignmentLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <main
        className={
          "bg-slate-100 min-w-screen min-h-screen p-20 max-md:p-5 max-md:mt-12"
        }
      >
        {children}
      </main>
    </>
  );
};

export default AssignmentLayout;
