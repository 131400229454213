import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout";
import {
  useGetAllCampusesQuery,
  useGetStudentAndCampusDetailsByAssignmentIdQuery,
  useRemoveAssignmentFromStudentMutation,
} from "../../../../graphql/generated.tsx";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Card, Table } from "flowbite-react";
import { useParams } from "react-router-dom";
import { Tabs } from "flowbite-react";
import { HiUserCircle } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import { FaTrash } from "react-icons/fa";

const AssignToStudents = () => {
  const { assignmentid } = useParams();
  const [campus, setCampus] = useState([]);
  const { data, loading, error } = useGetAllCampusesQuery();
  const { data: assignmentData, refetch } =
    useGetStudentAndCampusDetailsByAssignmentIdQuery({
      variables: { assignmentId: assignmentid },
    });
  const [removeAssignmentFromStudent] =
    useRemoveAssignmentFromStudentMutation();

  useEffect(() => {
    if (data) {
      setCampus(data.allCampus);
    }
  }, [data]);

  const handleRemoveAssignment = async (studentId, event) => {
    event.stopPropagation();
    try {
      await removeAssignmentFromStudent({
        variables: {
          removeAssignmentInput: {
            studentId,
            assignmentId: assignmentid,
          },
        },
      });
      // Refetch the assignment data to update the UI
      refetch();
    } catch (error) {
      console.error("Error removing assignment:", error);
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p className="">Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  if (error) return <AdminLayout>Error: {error.message}</AdminLayout>;

  return (
    <AdminLayout>
      <Tabs aria-label="Tabs with underline" variant="underline">
        <Tabs.Item active title="Select students" icon={HiUserCircle}>
          <div className="p-10">
            <div className="flex justify-between items-center mb-8 max-md:gap-2">
              <div className="flex items-center">
                <button
                  onClick={() => window.history.back()}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <IoMdArrowRoundBack className="h-6 w-6" />
                </button>
                <div className="text-2xl font-bold ml-2 max-md:text-lg">
                  Select Campus
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {campus.map((campus) => (
                <Card
                  key={campus.id}
                  className="mb-3"
                  href={`/admin/assignments/${assignmentid}/assign-to-students/${campus.id}`}
                >
                  <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {campus.name}
                  </h5>
                </Card>
              ))}
            </div>
          </div>
        </Tabs.Item>
        <Tabs.Item title="Assigned Students" icon={MdDashboard}>
          <div className="p-10">
            <h2 className="text-2xl font-bold mb-6">
              Students Assigned to Assignment
            </h2>
            {assignmentData &&
            assignmentData.getStudentAndCampusDetailsByAssignmentId.length >
              0 ? (
              <div className="overflow-x-auto shadow-md sm:rounded-lg">
                <Table className="min-w-full divide-y divide-gray-200 ">
                  <Table.Head className="bg-gray-100 dark:bg-gray-700">
                    <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </Table.HeadCell>
                    <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      RollNo
                    </Table.HeadCell>
                    <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </Table.HeadCell>
                    <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Campus
                    </Table.HeadCell>
                    <Table.HeadCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="bg-white divide-y divide-gray-200">
                    {assignmentData.getStudentAndCampusDetailsByAssignmentId.map(
                      ({ student, campus }) => (
                        <Table.Row
                          key={student.id}
                          className="hover:bg-gray-100 cursor-pointer"
                          // onClick={() =>
                          //   navigate(`/admin/student-details/${student.id}`)
                          // }
                        >
                          <Table.Cell className="px-6 py-4 whitespace-nowrap">
                            {student.name}
                          </Table.Cell>
                          <Table.Cell className="px-6 py-4 whitespace-nowrap">
                            {student.rollNo}
                          </Table.Cell>
                          <Table.Cell className="px-6 py-4 whitespace-nowrap">
                            {student.email}
                          </Table.Cell>
                          <Table.Cell className="px-6 py-4 whitespace-nowrap">
                            {campus.name}
                          </Table.Cell>
                          <Table.Cell className="px-6 py-4 whitespace-nowrap">
                            <button
                              onClick={(event) =>
                                handleRemoveAssignment(student.id, event)
                              }
                              className="text-red-600 hover:text-red-800 focus:outline-none"
                            >
                              <FaTrash className="h-4 w-4" />
                            </button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <p className="text-gray-600 dark:text-gray-200">
                No students assigned to this assignment yet.
              </p>
            )}
          </div>
        </Tabs.Item>
      </Tabs>
    </AdminLayout>
  );
};

export default AssignToStudents;
