import React, { useEffect, useState } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import studentRoutes from "./Routes/StudentRoutes";
import adminRoutes from "./Routes/StaffsRoutes";
import publicRoutes from "./Routes/PublicRoutes";
import authRoutes from "./Routes/AuthRoutes";
import { auth } from "./utils/firebase";
import SuperAdminRoutes from "./Routes/Admin/SuperAdminRoutes.jsx";
import StudentOnboarding from "./Modules/Student/Screens/StudentOnboarding";
import Admin from "./Modules/Admin/Screens/Dashboards/SuperAdminDashboard";
import StaffOnboarding from "./Modules/Admin/Screens/Staffs/StaffOnboarding";
import StaffDashboard from "./Modules/Admin/Screens/Dashboards/StaffDashboard";
import { Spinner } from "flowbite-react";
import Assignments from "./Modules/Assignments/Screens/Assignments";
import HomePage from "./Modules/HomePage";
import {
  useGetAdminUsingEmailLazyQuery,
  useGetAdminUsingEmailQuery,
} from "./graphql/generated.tsx";
import AssignmentRoutes from "./Routes/Admin/AssignmentRoutes";
import CourseRoutes from "./Routes/Admin/CourseRoutes";
import ReportRoutes from "./Routes/Admin/ReportRoutes";
import StudentCreationRoutes from "./Routes/Admin/StudentCreationRoutes";
import Courses from "./Modules/Admin/Screens/Course/Courses.jsx";
import ManageCampus from "./Routes/Admin/ManageCampus.jsx";
import ManageClusterRoutes from "./Routes/Admin/ManageClusterRoutes.jsx";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        user.getIdToken().then((token) => {
          localStorage.setItem("idtoken", token);
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  let userRole = null;
  let flogin = null;
  try {
    userRole = user?.reloadUserInfo?.customAttributes
      ? JSON?.parse(user?.reloadUserInfo?.customAttributes)?.role
      : null;
    flogin = JSON?.parse(user?.reloadUserInfo?.customAttributes)?.flogin;
  } catch (error) {
    console.error("Failed to parse user role:", error);
  }
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: admin } = useGetAdminUsingEmailQuery({
    variables: { email: email },
    skip: userRole != "bt-admin",
  });

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spinner size="xl" aria-label="Loading dashboard..." />
      </div>
    );
  }
  console.log({ admin });

  return (
    <div className="App w-full">
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              userRole === "student" ? (
                user ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              ) : userRole === "staff" ? (
                user ? (
                  <Navigate to="/admin" />
                ) : (
                  <Navigate to="/login" />
                )
              ) : user ? (
                <Navigate to="/admin/courses" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/assignments"
            element={user ? <Assignments /> : <Navigate to="/login" />}
          />
          {publicRoutes}
          {authRoutes}
          {(userRole === "bt-admin" || userRole === "student") && studentRoutes}
          {(userRole === "bt-admin" || userRole === "staff") && adminRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.isSuperAdmin &&
            CourseRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.isSuperAdmin &&
            AssignmentRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.isSuperAdmin &&
            ReportRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.isSuperAdmin &&
            StudentCreationRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.isSuperAdmin &&
            ManageCampus}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.isSuperAdmin &&
            ManageClusterRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.isSuperAdmin &&
            SuperAdminRoutes}

          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.permissions.courses?.length > 0 &&
            CourseRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.permissions.assignments?.length > 0 &&
            AssignmentRoutes}
          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.permissions.reports &&
            ReportRoutes}

          {userRole === "bt-admin" &&
            admin?.getAdminUsingEmail?.permissions.student_creation &&
            StudentCreationRoutes}

          {userRole === "staff" && (
            <Route key="admin" path="/admin" element={<StaffDashboard />} />
          )}
          {userRole === "bt-admin" && (
            <Route key="admin" path="/admin" element={<Admin />} />
          )}
          {userRole === "student" && flogin && (
            <Route
              key="student-onboarding"
              path="student/student-onboarding/:id"
              element={<StudentOnboarding />}
            />
          )}
          {userRole === "staff" && flogin && (
            <Route
              key="update-details"
              path="admin/staff-onboarding/:id"
              element={<StaffOnboarding />}
            />
          )}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
