import React from "react";
import { Checkbox, Label, Textarea } from "flowbite-react";
const CustomInput = ({ isChecked, handleCheckboxChange, setInput, input }) => {
  return (
    <div className="mt-8 w-full flex justify-between">
      <div>
        <Checkbox
          id="disabled"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <Label htmlFor="disabled" className="ml-2">
          Test against custom input
        </Label>
        {isChecked && (
          <Textarea
            name="input"
            id="input"
            cols="27"
            rows="10"
            sizing="sm"
            className="max-w-sm mt-6"
            onChange={(e) => setInput(e.target.value)}
            value={input}
          ></Textarea>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
