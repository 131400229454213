import { Route } from "react-router-dom";
import Reports from "../../Modules/Admin/Screens/Reports/Reports";
import AssignmentCampusListing from "../../Modules/Admin/Screens/Reports/AssignmentCampusListing";

const ReportRoutes =[
  <Route key="reports" path="/admin/reports" element={<Reports />} />,
  <Route
    key="assignment-campus-listing"
    path="/admin/reports/assignment"
    element={<AssignmentCampusListing />}
  />,
];

export default ReportRoutes;
