import React from "react";
import { Spinner, Table } from "flowbite-react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

const RunModel = ({ tableRef, isChecked, testResults, loading }) => {
  if (loading) {
    return (
      <div className="mt-8 w-full text-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="mt-8 w-full">
      <h4 className="text-2xl font-semibold text-gray-800">Test Results:</h4>
      <div className="overflow-x-auto">
        <Table
          ref={tableRef}
          className="w-full mt-4 border border-gray-300 shadow-lg rounded-lg"
        >
          <Table.Head>
            <Table.HeadCell className="border border-gray-300 px-6 py-3 text-lg font-semibold">
              Input
            </Table.HeadCell>
            <Table.HeadCell className="border border-gray-300 px-6 py-3 text-lg font-semibold">
              Output
            </Table.HeadCell>
            {!isChecked && (
              <>
                <Table.HeadCell className="border border-gray-300 px-6 py-3 text-lg font-semibold">
                  Expected Output
                </Table.HeadCell>
                <Table.HeadCell className="border border-gray-300 px-6 py-3 text-lg font-semibold">
                  Result
                </Table.HeadCell>
              </>
            )}
          </Table.Head>
          <Table.Body className="bg-white">
            {testResults?.map((result, index) => (
              <Table.Row
                key={index}
                className={`hover:bg-gray-100 transition duration-300 border border-gray-300 h-full ${
                  index % 2 != 0 ? "bg-gray-50" : "bg-white"
                }`}
              >
                <Table.Cell className=" px-6 py-4">
                  <pre className="whitespace-pre-wrap break-words font-sans">
                    {result.input}
                  </pre>
                </Table.Cell>
                <Table.Cell className="border border-gray-300 px-6 py-4">
                  <pre className="whitespace-pre-wrap break-words font-sans">
                    {result.output.trim()}
                  </pre>
                </Table.Cell>
                {!isChecked && (
                  <>
                    <Table.Cell className="border border-gray-300 px-6 py-4">
                      <pre className="whitespace-pre-wrap break-words font-sans">
                        {result.expextedOutput.trim()}
                      </pre>
                    </Table.Cell>
                    <Table.Cell
                      className={`font-semibold ${
                        result.expextedOutput.trim() === result.output.trim()
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {result.expextedOutput.trim() === result.output.trim() ? (
                        <div className="flex gap-2 items-center">
                          <AiOutlineCheckCircle className="text-xl" />
                          <span>Passed</span>
                        </div>
                      ) : (
                        <div className="flex gap-2 items-center">
                          <AiOutlineCloseCircle className="text-xl" />
                          <span>Failed</span>
                        </div>
                      )}
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default RunModel;
