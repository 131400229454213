import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  useDeleteQuestionMutation,
  useGetAllQuestionQuery,
} from "../../../../../graphql/generated.tsx";
import Question from "../../../Components/QuestionCard.jsx";
import AdminLayout from "../../../Layout/index.jsx";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";

const QuestionListing = () => {
  const { cat_id, course_id } = useParams();
  const [questions, setQuestions] = useState([]);
  const [catName, setCatName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10; // Adjust as needed

  const [deleteQuestion] = useDeleteQuestionMutation();
    const { data, loading, refetch } = useGetAllQuestionQuery({
      variables: {
        courseId: course_id,
        page: currentPage,
        limit: questionsPerPage,
        categoryId: String(cat_id),
      },
    });

  const handleDelete = async (id) => {
    try {
      const response = await deleteQuestion({ variables: { id, course_id } });
      if (response?.data?.deleteQuestion) {
        refetch();
      }
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };


  useEffect(() => {
    refetch();
    if (data?.getAllQuestion?.questions) {
      setCatName(data.getAllQuestion.questions?.[0]?.category?.name || "");
      setQuestions(data.getAllQuestion.questions);
    }
  }, [data]);

  const totalPages = data?.getAllQuestion?.totalCount
    ? Math.ceil(data.getAllQuestion.totalCount / questionsPerPage)
    : 0;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p>Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-8">
        <div className="flex justify-between items-center">
          <button
            onClick={() => window.history.back()}
            className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
          >
            <span className="gap-2 text-sm font-medium flex items-center">
              <IoMdArrowRoundBack className="h-6 w-6" />
              Go Back
            </span>
          </button>
          <h2 className="text-3xl font-bold text-center max-md:text-2xl mt-5">
            {catName}
          </h2>
          <Button className="p-0 bg-primary">
            <Link to ="import-question">Import Question</Link>
          </Button>
        </div>
        <div className="mt-8 bg-white p-5 rounded-md shadow-md">
          {questions.length === 0 ? (
            <div className="text-center text-gray-500">
              No questions available
            </div>
          ) : (
            questions.map((question, index) => (
              <div key={question?._id}>
                <Question
                  data={question}
                  courseId={course_id}
                  onDelete={handleDelete}
                  catName={catName}
                />
                {index !== questions.length - 1 && <hr className="px-8 my-4" />}
              </div>
            ))
          )}
        </div>
        <div className="flex justify-between mt-4">
          <Button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="p-0 bg-primary"
          >
            Previous
          </Button>
          <span className="text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="p-0 bg-primary"
          >
            Next
          </Button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default QuestionListing;
