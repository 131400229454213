import React from "react";
import { SiLeetcode } from "react-icons/si";

const Overlay = ({ message }) => {
  return (
    <div className="absolute inset-0 bg-gray-200 bg-opacity-100 flex flex-col items-center justify-center h-[345px] rounded-md">
      <SiLeetcode className="h-10 w-10 text-primary" />
      <div className="text-primary text-lg font-semibold mt-2">{message}</div>
    </div>
  );
};

export default Overlay;
