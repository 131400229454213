import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import { Card, Button, Label, Modal, TextInput } from "flowbite-react";
import { IoMdAdd, IoMdArrowRoundBack } from "react-icons/io";
import { useGetAllCampusesQuery, useCreateCampusMutation } from "../../../../graphql/generated.tsx";

const Campus = () => {
  const [campus, setCampus] = useState([]);
  const { data, loading, error } = useGetAllCampusesQuery();
  const [createCampus] = useCreateCampusMutation();
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    if (data) {
      setCampus(data.allCampus);
    }
  }, [data]);

  const handleCreateCampus = async () => {
    try {
      const { data: newCampusData } = await createCampus({
        variables: { createCampusInput: { name } },
      });
      setCampus((prevCampus) => [...prevCampus, newCampusData.createCampus]);
      setOpenModal(false);
    } catch (err) {
      console.error("Error creating campus:", err);
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p className="">Loading...</p>
        </div>
      </AdminLayout>
    );
  }
  
  if (error) return <AdminLayout>Error: {error.message}</AdminLayout>;

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              All Campus
            </div>
          </div>
          <Button
            onClick={() => setOpenModal(true)}
            className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary p-0"
          >
            <IoMdAdd className="mr-1 mt-1" /> <span>Create Campus</span>
          </Button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {campus.map((campus) => (
            <Card
              key={campus.id}
              className="mb-3"
              href={`/admin/campus/${campus.id}`}
            >
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {campus.name}
              </h5>
            </Card>
          ))}
        </div>

        {/* Modal for creating campus */}
        <Modal
          show={openModal}
          size="md"
          popup
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Create a new campus
              </h3>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Campus Name" />
                </div>
                <TextInput
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter campus name"
                  required
                />
              </div>
              <div className="w-full">
                <Button onClick={handleCreateCampus}>Create Campus</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default Campus;
