import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { SlSizeFullscreen } from "react-icons/sl";

const BackButton = ({ setFullScreen, isFullScreen }) => {
  return (
    <div className="flex justify-between items-center mb-6">
      <button
        onClick={() => window.history.back()}
        className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
      >
        <span className="gap-2 text-sm font-medium flex items-center">
          <IoMdArrowRoundBack className="h-6 w-6" />
          Go Back
        </span>
      </button>
    </div>
  );
};

export default BackButton;
