import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const useBlocker = (blocker, when) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!when) return;

    const handleBlockNavigation = (event) => {
      const confirmation = window.confirm(
        "You have unsaved changes. Do you really want to leave?"
      );
      if (!confirmation) {
        blocker(true); 
        event.preventDefault();
      } else {
        blocker(false); // Allow navigation
        navigate(location.pathname); // Redirect to the intended path
      }
    };

    window.addEventListener("beforeunload", handleBlockNavigation);

    return () => {
      window.removeEventListener("beforeunload", handleBlockNavigation);
    };
  }, [when, blocker, navigate, location]);
};
