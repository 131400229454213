import { Card } from "flowbite-react";
import React from "react";

const CardItem = ({ title, data }) => {
  return (
    <Card className="w-[350px] text-center hover:text-primary h-[150px]">
      <h1 className="text-2xl font-bold">{title}</h1>
      <p className="text-xl">{data}</p>
    </Card>
  );
};

export default CardItem;
