import React from "react";

class ChangingProgressProvider extends React.Component {
  static defaultProps = {
    interval: 1000,
  };

  state = {
    valuesIndex: 0,
    intervalId: null,
  };

  componentDidMount() {
    const intervalId = setInterval(() => {
      const newIndex = this.state.valuesIndex + 1;
      if (newIndex === this.props.values.length) {
        clearInterval(this.state.intervalId);
      } else {
        this.setState({
          valuesIndex: newIndex,
        });
      }
    }, this.props.interval);

    this.setState({
      intervalId,
    });
  }

  render() {
    return this.props.children(this.props.values[this.state.valuesIndex]);
  }
}

export default ChangingProgressProvider;
