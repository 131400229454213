// src/components/StudentOnboarding.js
import React, { useState, useEffect } from "react";
import { useUpdateStudentMutation } from "../../../graphql/generated.tsx";
import { Button, Label, TextInput } from "flowbite-react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../utils/firebase.js";

const StudentOnboarding = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [phno, setPhno] = useState("");
  const [dob, setDob] = useState("");
  const [placeOfResidence, setPlaceOfResidence] = useState("");
  const [leetCodeUname, setLeetCodeUname] = useState("");
  const [hackerRankUname, setHackerRankUname] = useState("");
  const [updateStudent, { loading, error }] = useUpdateStudentMutation();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [rollno, setRollNo] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
      }
    });
    return () => unsubscribe();
  }, []);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(phno)) {
      toast.error(
        "Invalid phone number. Please enter a 10-digit phone number."
      );
      return;
    }
    try {
      await updateStudent({
        variables: {
          updateStudentInput: {
            id,
            email,
            rollNo: rollno,
            name,
            phno,
            dob,
            placeOfResidence,
            leetCodeUname,
            hackerRankUname,
          },
        },
      });
      navigate("/");
    } catch (error) {
      toast.error("Error updating student.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="relative max-w-2xl w-full bg-white shadow-md rounded-lg p-6">
        <div className="absolute top-4 right-4">
          <img src={"/favicon.ico"} alt="Company Logo" className="h-16" />
        </div>

        {step === 1 && (
          <div className="p-4 text-center">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              Welcome to Student Onboarding
            </h2>
            <p className="text-gray-600 mb-8">
              Please follow the instructions to complete your onboarding
              process.
            </p>
            <Button
              onClick={() => setStep(2)}
              className="w-full p-0 bg-primary text-white rounded-md"
            >
              Next Step
            </Button>
          </div>
        )}

        {step === 2 && (
          <div className="p-8">
            <div className="flex items-center mb-4">
              <button
                onClick={() => setStep(1)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none mr-2"
              >
                <IoMdArrowRoundBack className="h-6 w-6" />
              </button>
              <h2 className="text-2xl font-bold text-gray-800">
                Complete Your Profile
              </h2>
            </div>
            <form
              onSubmit={handleSubmit}
              className="space-y-6 lg:grid lg:grid-cols-2 lg:gap-6 lg:space-y-0"
            >
              <div>
                <Label htmlFor="name">
                  Name: <span className="text-red-500">*</span>
                </Label>
                <TextInput
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="rollNo">
                  Roll No: <span className="text-red-500">*</span>
                </Label>
                <TextInput
                  type="text"
                  value={rollno}
                  onChange={(e) => setRollNo(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="phno">
                  Phone Number: <span className="text-red-500">*</span>
                </Label>
                <TextInput
                  type="text"
                  value={phno}
                  onChange={(e) => setPhno(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="dob">
                  Date of Birth: <span className="text-red-500">*</span>
                </Label>
                <TextInput
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="placeOfResidence">
                  Place of Residence: <span className="text-red-500">*</span>
                </Label>
                <TextInput
                  type="text"
                  value={placeOfResidence}
                  onChange={(e) => setPlaceOfResidence(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="leetCodeUname">LeetCode Username:</Label>
                <TextInput
                  type="text"
                  value={leetCodeUname}
                  onChange={(e) => setLeetCodeUname(e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="hackerRankUname">HackerRank Username:</Label>
                <TextInput
                  type="text"
                  value={hackerRankUname}
                  onChange={(e) => setHackerRankUname(e.target.value)}
                />
              </div>
              <Button
                type="submit"
                className="w-full lg:col-span-2 p-0 bg-primary text-white rounded-md "
              >
                <div className="flex justify-center items-center gap-2">
                  Let's Start Buddy!{" "}
                  <FaLongArrowAltRight className=" h-4 w-4" />
                </div>
              </Button>
              {loading && (
                <p className="text-blue-500 text-center mt-2 lg:col-span-2">
                  Loading...
                </p>
              )}
              {error && (
                <p className="text-red-500 text-center mt-2 lg:col-span-2">
                  Error: {error.message}
                </p>
              )}
            </form>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default StudentOnboarding;
  