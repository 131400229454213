import CourseCard from "./CourseCard.jsx";

const CoursesListing = ({courseData, clusterId}) => {
  
  return (
    <div className="flex gap-4 flex-wrap max-md:p-8 max-md:ml-5 mt-10 max-md:mt-2 md:mx-10 lg:mx-2">
      {courseData?.map((course) => (
        <CourseCard
          key={course._id}
          course_id={course.id}
          name={course.name}
          short_description={course.short_description}
          time_period={course.time_period}
          clusterId={clusterId}
        />
      ))}
    </div>
  );
};

export default CoursesListing;
