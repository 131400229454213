import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { FaMedal } from "react-icons/fa";
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "Helvetica",
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 20,
    alignSelf: "center",
  },
  header: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
    fontWeight: "bold",
    color: "#2c3e50",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#f9f9f9",
  },
  tableCell: {
    padding: 8, // Reduced padding
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ddd",
    textAlign: "center", // Center aligned
    fontSize: 10,
    width: "15%", // Set consistent width for each cell
  },
  tableHeader: {
    backgroundColor: "#2c3e50",
    color: "#fff",
    fontSize: 8,
    fontWeight: "bold",
  },
  tableBody: {
    fontSize: 10,
    color: "#333",
  },
  medal: {
    marginRight: 4,
    fontSize: 12,
    color: "#FFD700",
  },
});

const PDFDocument = ({ students, assignmentName }) => {
  return (
    <Document>
      <Page style={styles.page}>
        <Image style={styles.logo} src={"/favicon.ico"} />
        <Text style={styles.header}>{assignmentName} Leaderboard</Text>
        <View style={[styles.table, styles.section]}>
          <View style={styles.tableRow}>
            {[
              "Position",
              "Name",
              "Roll No",
              "Points",
              "Q-Attended",
              "Q-Completed",
              "Batch",
              "Progress",
            ].map((heading, index) => (
              <Text
                key={index}
                style={[
                  styles.tableCell,
                  styles.tableHeader,
                  heading === "Name"
                    ? { minWidth: "100px" }
                    : heading === "Q-Completed" ? { minWidth: "50px" }: {},
                ]}
              >
                {heading}
              </Text>
            ))}
          </View>
          {students?.map((student, index) => {
            const questionsAttended = student.questions.filter(
              (question) => question.score > 0
            ).length;
            const questionsCompleted = student.questions.filter(
              (question) => question.score === question.AllotedTotalMarks
            ).length;

            return (
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: index % 2 ? "#ffffff" : "#f0f0f0" },
                ]}
                key={index}
              >
                <Text style={[styles.tableCell, styles.tableBody]}>
                  {student.position}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableBody,
                    { minWidth: "100px" },
                  ]}
                >
                  {student.student.name}
                </Text>
                <Text style={[styles.tableCell, styles.tableBody]}>
                  {student.student.rollNo}
                </Text>
                <Text style={[styles.tableCell, styles.tableBody]}>
                  {student.totalScore}
                </Text>
                <Text style={[styles.tableCell, styles.tableBody]}>
                  {questionsAttended}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableBody,
                    { minWidth: "50px" },
                  ]}
                >
                  {questionsCompleted}/{student.questions.length}
                </Text>
                <Text style={[styles.tableCell, styles.tableBody]}>
                  {student.clusterNames}
                </Text>
                <Text style={[styles.tableCell, styles.tableBody]}>
                  {questionsCompleted === student.questions.length
                    ? "100%"
                    : `${Math.round(
                        (questionsCompleted / student.questions.length) * 100
                      )}%`}
                </Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
