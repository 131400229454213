import React from "react";
import { Route } from "react-router-dom";
import StudentData from "../Modules/Admin/Screens/Student_Creation/Students";
import StudentInfo from "../Modules/Admin/Screens/Student_Creation/StudentInfo";
import AllStudentsInCampus from "../Modules/Admin/Screens/Student_Creation/AllStudentsInCampus";
import StaffMyProfile from "../Modules/Admin/Screens/Staffs/StaffMyProfile";
import Reports from "../Modules/Admin/Screens/Reports/Reports";
import ReportsClusterListing from "../Modules/Admin/Screens/Reports/ReportsClusterListing";
import ClusterReport from "../Modules/Admin/Screens/Reports/ClusterReport";
import HomePage from "../Modules/HomePage";
import ReportType from "../Modules/Admin/Screens/Reports/ReportType";
import AssignmentListing from "../Modules/Admin/Screens/Reports/AssignmentListing";
import AssignmentReport from "../Modules/Admin/Screens/Reports/AssignmentReport";
import AssignmentCampusListing from "../Modules/Admin/Screens/Reports/AssignmentCampusListing";
import StudentDashboard from "../Modules/Student";

const adminRoutes = [
  <Route
    key="students-data"
    path="/admin/students"
    element={<StudentData />}
  />,
  <Route
    key="students-details"
    path="/admin/student-details/:id"
    element={<StudentInfo />}
  />,
  <Route
    key="students-details"
    path="/admin/student-details"
    element={<AllStudentsInCampus />}
  />,
  <Route
    key="my-profile"
    path="/staff/my-profile"
    element={<StaffMyProfile />}
  />,
  <Route key="reports" path="admin/reports" element={<ReportType />} />,
  <Route
    key="course-reports"
    path="admin/reports/course"
    element={<Reports />}
  />,
  <Route key="dashboard" path="/admin/student/:studentid" element={<StudentDashboard isAdmin={true}/>} />,

  <Route
    key="reports-cluster"
    path="admin/reports/assignments/:campusName"
    element={<AssignmentListing />}
  />,
  <Route
    key="assignment-reports"
    path="admin/reports/assignments/:campusName/:assignment_id"
    element={<AssignmentReport />}
  />,
  <Route
    key="reports-cluster"
    path="admin/reports/course/:campusName"
    element={<ReportsClusterListing />}
  />,

  <Route
    key="report"
    path="admin/reports/course/:campusName/:clusterId"
    element={<ClusterReport />}
  />,
];

export default adminRoutes;
