import { Route } from "react-router-dom";
import ManageClusters from "../../Modules/Admin/Screens/Manage_Cluster/ManageClusters";
import ClusterListing from "../../Modules/Admin/Screens/Manage_Cluster/ClusterListing";
import ClusterStudentListing from "../../Modules/Admin/Screens/Manage_Cluster/ClusterStudentListing"

const ManageClusterRoutes = [
  <Route
    key="manage-cluster"
    path="admin/clusters/campus-list"
    element={<ManageClusters />}
  />,
  <Route
    key="cluster-listing"
    path="admin/clusters/:campusName"
    element={<ClusterListing />}
  />,

  <Route
    key="cluster-student-listing"
    path="admin/clusters/:campusName/:clusterId"
    element={<ClusterStudentListing />}
  />,
];

export default ManageClusterRoutes;
