import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../Layout/index.jsx";
import { Label, TextInput, Textarea, Button } from "flowbite-react"; // Importing Flowbite components
import Select from "react-select";
import { toast } from "react-toastify";
import { IoMdAdd, IoMdArrowRoundBack } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import {
  useAllCategoriesQuery,
  useCreateCourseMutation,
} from "../../../../graphql/generated.tsx";

const languageOptions = [
  { value: "PYTHON", label: "Python" },
  { value: "C", label: "C" },
  { value: "JAVA", label: "Java" },
  { value: "CPP", label: "C++" },
];

const levelOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
];

const CreateCourse = () => {
  const navigate = useNavigate();
  const [createCourse] = useCreateCourseMutation();
  const [formData, setFormData] = useState({
    name: "",
    short_description: "",
    description: "",
    price: 0,
    time_period: 0,
    level: "",
    languages: [],
    domain: "",
    categories: [], // Initialize as an empty array
  });

  const { data: CategoriesData } = useAllCategoriesQuery();
  const allCategories = CategoriesData?.allCategories || [];


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 const handleCategoryChange = (selectedOptions) => {
   setFormData({
     ...formData,
     categories: selectedOptions.map((option) => ({
       id: option.value, // Use "value" for the id
       name: option.label, // Use "label" for the name
       description: option.description,
     })),
   });
 };

  const handleLanguageChange = (selectedOptions) => {
    setFormData({
      ...formData,
      languages: selectedOptions.map((option) => option.value),
    });
  };

  const handleLevelChange = (selectedOption) => {
    setFormData({
      ...formData,
      level: selectedOption.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.name.length < 5 ||
      formData.description.length < 5 ||
      formData.short_description.length < 5
    ) {
      toast.error(
        "Name, description, and short description must be at least 5 characters long."
      );
      return;
    }

    try {
      await createCourse({
        variables: {
          createCourseInput: {
            ...formData,
            price: parseFloat(formData.price),
            time_period: parseInt(formData.time_period),
          },
        },
      });
      toast.success("Course created successfully");
      navigate("/admin/courses");
    } catch (error) {
      console.error("Error creating course:", error);
      toast.error("Error creating course");
    }
  };

  return (
    <AdminLayout>
      <div className="p-8 max-md:p-5">
        <div className="flex items-center mb-6">
          <button
            onClick={() => window.history.back()}
            className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
          >
            <span className="gap-2 text-sm font-medium flex items-center">
              <IoMdArrowRoundBack className="h-6 w-6" />
              Go Back
            </span>
          </button>
        </div>
        <h2 className="text-3xl font-bold text-center w-full max-md:text-xl mb-4">
          Create Course
        </h2>

        <form
          onSubmit={handleSubmit}
          className="max-w-2xl mx-auto bg-white rounded-md shadow-md p-7"
        >
          <Label className="font-semibold" htmlFor="name" value="Name:" />
          <TextInput
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full rounded-md mb-4"
            required
          />

          <Label
            className="font-semibold"
            htmlFor="short_description"
            value="Short Description:"
          />
          <Textarea
            id="short_description"
            name="short_description"
            value={formData.short_description}
            onChange={handleChange}
            className="w-full rounded-md mb-4"
            required
          />

          <Label
            className="font-semibold"
            htmlFor="description"
            value="Description:"
          />
          <Textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full rounded-md mb-4"
            required
          />

          <Label className="font-semibold" htmlFor="price" value="Price:" />
          <TextInput
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            className="w-full rounded-md mb-4"
            required
          />

          <Label className="font-semibold" htmlFor="level" value="Level:" />
          <Select
            name="level"
            options={levelOptions}
            value={levelOptions.find(
              (option) => option.value === formData.level
            )}
            onChange={handleLevelChange}
            className="w-full rounded-md mb-4"
            required
          />

          <Label
            className="font-semibold"
            htmlFor="time_period"
            value="Time Period:"
          />
          <TextInput
            type="number"
            id="time_period"
            name="time_period"
            value={formData.time_period}
            onChange={handleChange}
            className="w-full rounded-md mb-4"
            required
          />

          <Label
            className="font-semibold"
            htmlFor="categories"
            value="Categories:"
          />
          <Select
            id="categories"
            options={allCategories.map((category) => ({
              label: category.name,
              value: category.id,
              description: category.description,
            }))}
            isMulti
            value={formData.categories.map((category) => ({
              label: category.name,
              value: category.id,
              description: category.description,
            }))}
            onChange={handleCategoryChange}
            placeholder="Select Categories"
            className="w-full rounded-md mb-4"
          />

          <Label
            className="font-semibold"
            htmlFor="languages"
            value="Languages:"
          />
          <Select
            isMulti
            name="languages"
            options={languageOptions}
            value={languageOptions.filter((option) =>
              formData.languages.includes(option.value)
            )}
            onChange={handleLanguageChange}
            className="w-full rounded-md mb-4"
          />

          <Label className="font-semibold" htmlFor="domain" value="Domain:" />
          <TextInput
            type="text"
            id="domain"
            name="domain"
            value={formData.domain}
            onChange={handleChange}
            className="w-full rounded-md mb-4"
          />

          <Button type="submit" className="p-0 mt-4 bg-primary">
            Create
          </Button>
        </form>
      </div>
    </AdminLayout>
  );
};

export default CreateCourse;
