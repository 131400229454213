import React from "react";
import AdminLayout from "../../Layout/index.jsx";
import { Tabs } from "flowbite-react";
import { HiAdjustments, HiClipboardList, HiUserCircle } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import QuestionSolved from "../../../Student/Components/QuestionSolved.jsx";
import BadgeCard from "../../../Student/Components/BadgeCard.jsx";
import AcSubmissionChart from "../../../Student/Components/AcSubmissionChart.jsx";
import {
  useGetDashboardCourseDetailsQuery,
  useGetLeetcodeDataQuery,
  useGetStudentQuery,
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import CardItem from "../../../Student/Components/CourseCard.jsx";
import CourseListing from "../../../Student/Components/CourseListing.jsx";

const StudentInfo = () => {
  const { id } = useParams();
  const { data } = useGetStudentQuery({ variables: { id } });
  const { data: leetCodeData, loading } = useGetLeetcodeDataQuery({
    variables: { username: data?.getStudent.leetCodeUname },
  });
  const { data: Courses } = useGetDashboardCourseDetailsQuery({
    variables: { student_id: data?.getStudent.id },
  });

  const acSubmissionNum =
    leetCodeData?.leetcodeData?.matchedUser?.submitStats?.acSubmissionNum;

  const allQuestionsCount = leetCodeData?.leetcodeData?.allQuestionsCount;
  return (
    <AdminLayout>
      <Tabs aria-label="Tabs with underline" variant="underline">
        <Tabs.Item active title="Dashboard" icon={MdDashboard}>
          <div className="p-8">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none w-full"
            >
              <span className="gap-2 text-sm font-medium flex items-center">
                <IoMdArrowRoundBack className="h-6 w-6" />
                {data?.getStudent.name && (
                  <h2 className="text-2xl max-md:text-2xl">
                    {data?.getStudent.name}'s Dashboard
                  </h2>
                )}
              </span>
            </button>

            <div className="flex gap-10 max-md:flex-col">
              <QuestionSolved studentData={data?.getStudent} />
              <BadgeCard />
            </div>
            <div className="mt-8">
              <h1 className="text-2xl font-bold my-4">
                LeetCode Solved vs. Total Questions
              </h1>
              <div className="max-w-xl">
                <AcSubmissionChart
                  isLoading={loading}
                  acSubmissionNum={acSubmissionNum}
                  allQuestionsCount={allQuestionsCount}
                />
              </div>
            </div>
          </div>
        </Tabs.Item>
        <Tabs.Item title="Course Details" icon={HiClipboardList}>
          <div className="m-10">
            <div className="my-10 grid grid-cols-3 gap-4 ">
              <CardItem
                title={"Course Enrolled"}
                data={data?.getStudent.courseIds?.length || 0}
              />
              <CardItem
                title={"Overall Question Completed"}
                data={data?.getStudent?.completedQuestions?.length || 0}
              />
            </div>
            <CourseListing Courses={Courses} />
          </div>
        </Tabs.Item>
      </Tabs>
    </AdminLayout>
  );
};

export default StudentInfo;
