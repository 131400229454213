import { Button, Modal, Table } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import {
  useAddStaffToClusterMutation,
  useGetStaffsByCampusQuery,
  useRemoveStaffFromClusterMutation,
} from "../../../../graphql/generated.tsx";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import Select from "react-select";

const StaffManageTab = ({ campusName, clusterId, campusId, ClusterData }) => {
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);

  useEffect(() => {
    if (ClusterData && ClusterData.getCluster) {
      setStaff(ClusterData.getCluster.staffs);
    }
  }, [ClusterData]);

  const toggleAddStaffModal = () => {
    setShowAddStaffModal(!showAddStaffModal);
  };

  const { data: AllStaffByCampus } = useGetStaffsByCampusQuery({
    variables: { campusId },
  });

  const [AddStaffToCluster] = useAddStaffToClusterMutation();
  const [removeClusterFromStaff] = useRemoveStaffFromClusterMutation();

  const handleAddStaff = async (e) => {
    e.preventDefault();
    try {
      const { data: AddClusterId } = await AddStaffToCluster({
        variables: {
          campusName,
          clusterId,
          staffs: selectedStaff.map(
            ({ id, name, designation, contactNo, email }) => ({
              id,
              name,
              designation,
              contactNo,
              email,
            })
          ),
        },
      });

      if (AddClusterId?.addStaffToCluster) {
        AddClusterId.addStaffToCluster.forEach((res) => {
          if (res.added) {
            setStaff((prev) => [...prev, res.staff]);
          }
        });
        setShowAddStaffModal(false);
        setSelectedStaff([]);
        toast.success("Staff added successfully");
      } else {
        throw new Error("Failed to add staff to cluster");
      }
    } catch (error) {
      toast.error(error.message || "Error adding staff to cluster");
    }
  };

  const handleSelectStaff = (staffId) => {
    if (selectedStaffIds.includes(staffId)) {
      setSelectedStaffIds(selectedStaffIds.filter((id) => id !== staffId));
    } else {
      setSelectedStaffIds([...selectedStaffIds, staffId]);
    }
  };

  const handleStaffChange = (selected) => {
    const selectedStaffData = selected.map((option) =>
      AllStaffByCampus?.getStaffsByCampus.find(
        (member) => member.id === option.value
      )
    );
    setSelectedStaff(selectedStaffData);
  };

  const handleRemoveStaff = async (staffIds) => {
    try {
      const { data: RemoveClusterId } = await removeClusterFromStaff({
        variables: {
          campusName,
          clusterId,
          staffIds,
        },
      });

      if (RemoveClusterId?.removeStaffFromCluster) {
        setStaff(
          staff.filter(
            (member) =>
              !RemoveClusterId.removeStaffFromCluster.some(
                (removed) => removed.staffId === member.id && removed.removed
              )
          )
        );
        setSelectedStaffIds([]);
        toast.success("Staff removed successfully");
      } else {
        throw new Error("Failed to remove staff from cluster");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Error removing staff from cluster");
    }
  };

  return (
    <>
      <div className="p-10">
        <div className="flex justify-between mb-4">
          <Button
            onClick={() => handleRemoveStaff(selectedStaffIds)}
            className="flex items-center bg-red-600 p-0"
            disabled={selectedStaffIds.length === 0}
          >
            <MdDelete className="mr-2 h-5 w-5" />
            Remove Selected Staff
          </Button>
          <Button onClick={toggleAddStaffModal} className="p-0 bg-primary">
            <IoMdAdd className="mr-2 h-5 w-5" />
            Add Staff
          </Button>
        </div>
        <Table hoverable className="w-full text-left">
          <Table.Head>
            <Table.HeadCell className="p-4"></Table.HeadCell>
            <Table.HeadCell className="p-4">Staff ID</Table.HeadCell>
            <Table.HeadCell className="p-4">Name</Table.HeadCell>
            <Table.HeadCell className="p-4">Email</Table.HeadCell>
            <Table.HeadCell className="p-4">
              <span className="sr-only">Actions</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {staff?.map((member) => (
              <Table.Row
                key={member.id}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="p-4">
                  <input
                    type="checkbox"
                    checked={selectedStaffIds.includes(member.id)}
                    onChange={() => handleSelectStaff(member.id)}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                </Table.Cell>
                <Table.Cell className="p-4 whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {member.id}
                </Table.Cell>
                <Table.Cell className="p-4">{member.name}</Table.Cell>
                <Table.Cell className="p-4">{member.email}</Table.Cell>
                <Table.Cell className="p-4 text-right">
                  <MdDelete
                    onClick={() => handleRemoveStaff([member.id])}
                    className="h-7 w-7 ml-2 hover:bg-red-500 rounded-full cursor-pointer p-1"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <Modal show={showAddStaffModal} onClose={toggleAddStaffModal}>
        <Modal.Header>Add Staff</Modal.Header>
        <Modal.Body>
          <div className="h-[200px]">
            <Select
              isMulti
              name="staff"
              options={AllStaffByCampus?.getStaffsByCampus.map((member) => ({
                value: member.id,
                label: member.email,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleStaffChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAddStaff} gradientDuoTone="greenToBlue">
            Add Staff
          </Button>
          <Button onClick={toggleAddStaffModal} gradientDuoTone="redToYellow">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StaffManageTab;
