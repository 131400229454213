import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { abcdef } from "@uiw/codemirror-theme-abcdef";
import { androidstudio } from "@uiw/codemirror-theme-androidstudio";
import { sublime } from "@uiw/codemirror-theme-sublime";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Button, Spinner, Tooltip } from "flowbite-react";
import { EditorView } from "@codemirror/view";
import { useSaveAutoSaveMutation } from "../../../graphql/generated.tsx";
import ConformationModal from "../../Admin/Components/ConformationModal.jsx";

const Compiler = ({
  language,
  setLanguage,
  setToDefault,
  handleCodeChange,
  errorRef,
  handleSubmit,
  code,
  handleRun,
  CourseData,
  compileLoading,
  submitLoading,
  question_id,
  student_id,
  previewMode,
  isChecked,
  setCodeApplied,
  isCodeChanged,
}) => {
  const themes = [
    { label: "Dracula", value: dracula },
    { label: "ABCDEF", value: abcdef },
    { label: "Android Studio", value: androidstudio },
    { label: "Sublime", value: sublime },
  ];

  const [autoSave, { loading }] = useSaveAutoSaveMutation();
  const [selectedTheme, setSelectedTheme] = useState(dracula);
  const [languageExtensions, setLanguageExtensions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [disableCopyPaste, setDisableCopyPaste] = useState([]);
  const [lastRunCode, setLastRunCode] = useState("");
  const [hasCodeChanged, setHasCodeChanged] = useState(true);
  const [saveStatus, setSaveStatus] = useState("idle");
  const [isSwitchingLanguage, setIsSwitchingLanguage] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [newLanguage, setNewLanguage] = useState(language); // Keep track of new language

  useEffect(() => {
    const loadLanguageExtensions = async () => {
      let extensions = [];
      switch (language) {
        case "CPP":
        case "C":
          const { cpp } = await import("@codemirror/lang-cpp");
          extensions.push(cpp());
          break;
        case "PYTHON":
          const { python } = await import("@codemirror/lang-python");
          extensions.push(python());
          break;
        case "JAVA":
          const { java } = await import("@codemirror/lang-java");
          extensions.push(java());
          break;
        default:
          break;
      }
      setLanguageExtensions(extensions);
    };
    loadLanguageExtensions();
  }, [language]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!previewMode && isCodeChanged) {
        handleSaveCode(); // Save the code before the user leaves
      }
      event.preventDefault();
      event.returnValue = "";
    };

    if (!previewMode && isCodeChanged) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [code, language, previewMode, isCodeChanged]);

  useEffect(() => {
    if (!previewMode && !isSaving && isCodeChanged) {
      const interval = setInterval(() => {
        handleSaveCode(); // Auto-save every 2 minutes
      }, 120000);

      return () => clearInterval(interval);
    }
  }, [
    autoSave,
    question_id,
    student_id,
    code,
    language,
    previewMode,
    isSaving,
  ]);

  const handleSaveCode = async () => {
    if (isSaving || isSwitchingLanguage || !isCodeChanged)
      return Promise.resolve();
    setSaveStatus("saving");
    setIsSaving(true);

    try {
      await autoSave({
        variables: {
          question_id,
          student_id,
          code,
          language,
        },
      });
      setSaveStatus("saved");
      setTimeout(() => {
        setSaveStatus("idle");
      }, 2000);
    } catch (error) {
      console.error("Save code error:", error);
      setSaveStatus("idle");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (code !== lastRunCode || isChecked) {
      setHasCodeChanged(true);
    } else {
      setHasCodeChanged(false);
    }
  }, [code, lastRunCode, isChecked]);

  const handleRunClick = () => {
    if (hasCodeChanged || isChecked) {
      handleRun();
      setLastRunCode(code);
      setHasCodeChanged(false);
    }
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setNewLanguage(selectedLanguage); 
    setCodeApplied(false);
    if (code && code.trim() !== "" && !previewMode && isCodeChanged) {
      setOpenModal(true); 
    } else {
      setLanguage(selectedLanguage);
    }
  };

  const handleConfirmLanguageChange = () => {
    setCodeApplied(false);
    setIsSwitchingLanguage(true);
    handleSaveCode().then(() => {
      setLanguage(newLanguage);
      setIsSwitchingLanguage(false);
      setOpenModal(false); // Close modal after confirming
    });
  };

  useEffect(() => {
    if (
      student_id === "e236fa5f-cadf-4cbb-8a1b-4b7445150b24" ||
      student_id === "2997a790-1422-426e-ba0d-79cad583e803" ||
      student_id === "e58f8d65-a5fa-4f94-8473-cf3d8f3b4be2" ||
      student_id === "4dc3a9d4-1897-4170-9d4b-aeb4d3dd2311"
    ) {
      setDisableCopyPaste([]);
    } else {
      const handlers = EditorView.domEventHandlers({
        keydown: (event) => {
          if (
            event.ctrlKey &&
            (event.key === "c" || event.key === "v" || event.key === "x")
          ) {
            event.preventDefault();
          }
        },
        copy: (event) => event.preventDefault(),
        paste: (event) => event.preventDefault(),
        cut: (event) => event.preventDefault(),
      });
      setDisableCopyPaste([handlers]);
    }
  }, [student_id]);

  return (
    <>
      <ConformationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        event={handleConfirmLanguageChange}
        text="Your progress may be lost. Do you want to switch the language?"
      />

      <div className="w-full bg-gray-100 shadow-lg flex items-center rounded-t-lg justify-between p-5 gap-x-4">
        <FormControl className="w-[130px]">
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            value={language}
            label="Language"
            disabled={isSaving || saveStatus === "saving"}
            onChange={handleLanguageChange} // Trigger the modal on change
          >
            {CourseData?.languages?.map((lang) => (
              <MenuItem key={lang} value={lang.toUpperCase()}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="w-[200px] max-md:w-[100px]">
          <InputLabel id="theme-select-label">Theme</InputLabel>
          <Select
            labelId="theme-select-label"
            value={selectedTheme}
            label="Theme"
            onChange={(e) => setSelectedTheme(e.target.value)}
          >
            {themes.map((theme) => (
              <MenuItem key={theme.label} value={theme.value}>
                {theme.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {!previewMode && (
          <>
            <Button
              onClick={setToDefault}
              className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
            >
              Reset Code
            </Button>
            <Button
              className={`bg-primary text-white rounded-md max-md:p-0 max-md:text-sm transition-all duration-300 ease-in-out ${
                saveStatus === "saved" ? "bg-green-500" : "bg-primary"
              }`}
              onClick={handleSaveCode}
            >
              {saveStatus === "saving" && (
                <>
                  <Spinner
                    aria-label="Medium sized spinner example"
                    size="sm"
                    className="mr-2"
                  />
                  Saving...
                </>
              )}
              {saveStatus === "saved" && (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-white mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Saved
                </>
              )}
              {saveStatus === "idle" && "Save Code"}
            </Button>
          </>
        )}
      </div>

      <div className="flex flex-col">
        <div className="w-full">
      <CodeMirror
        value={code}
            height="600px"
            width="100%"
        theme={selectedTheme}
            extensions={[...languageExtensions, ...disableCopyPaste]}
            options={{
              mode: "text/x-csrc",
              lineNumbers: true,
              readOnly: "nocursor",
            }}
            onChange={handleCodeChange}
      />
        </div>
      </div>

      <div ref={errorRef} className="flex "></div>
      {!previewMode && (
        <div className="mt-4 flex gap-2 justify-end">
          {!hasCodeChanged && !compileLoading ? (
            <Tooltip content="Edit your code to enable">
              <Button
                className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
                onClick={handleRunClick}
                disabled={(!hasCodeChanged && !isChecked) || compileLoading}
              >
                {compileLoading && (
                  <Spinner
                    aria-label="Medium sized spinner example"
                    size="sm"
                    className="mr-2"
                  />
                )}
                Run
              </Button>
            </Tooltip>
          ) : (
            <Button
              className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
              onClick={handleRunClick}
              disabled={(!hasCodeChanged && !isChecked) || compileLoading}
            >
              {compileLoading && (
                <Spinner
                  aria-label="Medium sized spinner example"
                  size="sm"
                  className="mr-2"
                />
              )}
              Run
            </Button>
          )}

          <Button
            className="bg-primary text-white rounded-md max-md:p-0 max-md:text-sm"
            onClick={handleSubmit}
          >
            {submitLoading && (
              <Spinner
                aria-label="Medium sized spinner example"
                size="sm"
                className="mr-2"
              />
            )}
            Submit
          </Button>
        </div>
      )}
    </>
  );
};

export default Compiler;
