import React from "react";
import { Table } from "flowbite-react";
import { AiOutlineCode, AiOutlineCheckCircle } from "react-icons/ai";

const SampleTestCaseTable = ({ question }) => {
  return (
    <Table className="w-full mt-4 shadow-sm rounded-sm overflow-hidden">
      <Table.Head className="bg-blue-600">
        <Table.HeadCell className="border border-gray-100 px-6 py-3 text-lg font-semibold">
          <AiOutlineCode className="inline-block mr-2" /> Input
        </Table.HeadCell>
        <Table.HeadCell className="border border-gray-100 px-6 py-3 text-lg font-semibold">
          <AiOutlineCheckCircle className="inline-block mr-2" /> Output
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="bg-white ">
        {question?.test_cases?.map(
          (test) =>
            test.visibility === "SAMPLE" && (
              <Table.Row
                key={test.id}
                className="transition duration-300 ease-in-out"
              >
                <Table.Cell className="border border-gray-100 px-6 py-3">
                  <pre className="text-sm whitespace-pre-wrap font-sans">
                    {test.input}
                  </pre>
                </Table.Cell>
                <Table.Cell className="border border-gray-100 px-6 py-3">
                  <pre className="text-sm whitespace-pre-wrap font-sans">
                    {test.output}
                  </pre>
                </Table.Cell>
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
};

export default SampleTestCaseTable;
