import React from "react";
import SampleTestCaseTable from "../../Practice/Components/SampleTestCaseTable.jsx";

const CompilerQuestionCard = ({ question, loading }) => {
  const onCopyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (loading) {
    return (
      <div className="shadow-md bg-white p-8 rounded-md items-center">
        Loading
      </div>
    );
  }

  return (
    <div
      className="relative shadow-md bg-white p-8 rounded-md"
      onCopy={onCopyHandler}
    >
      <h3 className="text-2xl font-semibold">{question?.title}</h3>
      <div
        className="py-2 prose w-full max-w-none custom-bullet-list"
        dangerouslySetInnerHTML={{ __html: question?.text }}
      />


      <h4 className="text-xl font-semibold mt-4">Sample Test Case:</h4>
      <div className="w-1/2 max-md:w-full">
        <SampleTestCaseTable question={question} />
      </div>

      {question?.hints?.length > 0 && (
        <div className="mt-4">
          <span className="font-semibold text-gray-600 dark:text-gray-400">
            Hints:
          </span>
          <ul>
            {question.hints.map(
              (hint, index) =>
                hint && (
                  <li key={index}>
                    {index + 1}. {hint}
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CompilerQuestionCard;
