import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseCard from "../../Components/CourseCard.jsx";
import AdminLayout from "../../Layout/index.jsx";
import { IoMdAdd, IoMdArrowRoundBack } from "react-icons/io";
import {
  useGetAdminUsingEmailQuery,
  useGetAllCoursesQuery,
  useRemoveCourseMutation,
} from "../../../../graphql/generated.tsx";
import { toast } from "react-toastify";

const Courses = () => {
  const { data, loading, refetch } = useGetAllCoursesQuery();
  const [deleteCourse] = useRemoveCourseMutation();
  const [filteredCourses, setFilteredCourses] = useState([]);

  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: admin } = useGetAdminUsingEmailQuery({
    variables: { email },
    skip: !email,
  });

  console.log(admin?.getAdminUsingEmail);

  useEffect(() => {
    refetch();
    if (data) {
      if (admin?.getAdminUsingEmail?.isSuperAdmin) {
        setFilteredCourses(data?.getAllCourses);
      } else {
        const courses = data?.getAllCourses.filter((course) =>
          admin?.getAdminUsingEmail?.permissions?.courses.includes(course.id)
        );
        setFilteredCourses(courses);
      }
    }
  }, [data, admin]);

  const handleDelete = async (id) => {
    await deleteCourse({ variables: { id } });
    refetch();
    toast.success("Deleted Successfully");
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p className="">Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-8 bg-slate-100">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              All Courses
            </div>
          </div>
          {admin?.getAdminUsingEmail?.isSuperAdmin && (
            <Link
              to="/admin/courses/create-course"
              className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary"
            >
              <IoMdAdd className="mr-1 mt-1" /> <span>Create Course</span>
            </Link>
          )}
        </div>
        <div className="flex gap-6 flex-wrap">
          {filteredCourses?.map((course) => (
            <CourseCard
              key={course._id}
              course_id={course.id}
              name={course.name}
              short_description={course.short_description}
              time_period={course.time_period}
              onRemove={handleDelete}
              isSuperAdmin={admin?.getAdminUsingEmail?.isSuperAdmin}
            />
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Courses;
