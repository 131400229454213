import { Route } from "react-router-dom";
import CreateStudents from "../../Modules/Admin/Screens/Student_Creation/CreateStudents";
import StudentDetails from "../../Modules/Admin/Screens/Student_Creation/StudentDetails";

const StudentCreationRoutes = [
  <Route
    key="student-details"
    path="/admin/students/:id"
    element={<StudentDetails />}
  />,
  <Route
    key="create-students"
    path="/admin/students/:id/create-students"
    element={<CreateStudents />}
  />,
];

export default StudentCreationRoutes;
