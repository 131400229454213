import React from "react";
import { useGetDashboardCourseDetailsQuery } from "../../../graphql/generated.tsx";
import { MdMenuBook } from "react-icons/md";

const ProgressOverview = ({ Courses }) => {
  return (
    <div className="flex flex-wrap gap-5 mt-4 max-md:mt-0">
      {Courses?.getDashboardCourseDetails?.courseDetails.map(
        (course, index) => (
          <div
            key={index}
            className="p-5 rounded-xl shadow-md bg-blue-100 max-md:w-[290px] w-[250px] relative min-h-[250px]"
          >
            <div className="bg-gray-50 w-[40px] h-[40px] flex justify-center items-center rounded-full mb-3">
              <MdMenuBook className="w-6 h-6" />
            </div>
            <h2 className="text-xl font-semibold">{course?.courseName}</h2>
            <p className="text-sm text-gray-600">{course?.level}</p>
            <div className="absolute bottom-4 left-5 right-5">
              <p className="text-sm flex justify-between">
                Questions completed:
                <span>
                  {course?.completedQuestion}/{course?.totalQuestion}
                </span>
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div
                  className="bg-primary h-2.5 rounded-full"
                  style={{ width: `${course?.percentageCompleted}%` }}
                ></div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default ProgressOverview;
