import React, { useState, useEffect } from "react";
import AdminLayout from "../../../Layout/index.jsx";
import {
  Button,
  Checkbox,
  Label,
  Select,
  TextInput,
  Textarea,
  Tooltip,
} from "flowbite-react";
import { useParams } from "react-router-dom";
import { IoMdAdd, IoMdArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

import { FaCircleInfo } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import {
  cppTooltipContent,
  cTooltipContent,
  javaTooltipContent,
  pythonTooltipContent,
} from "../../../Helpers/TooltipData.jsx";
import {
  useGetCourseQuery,
  useGetQuestionQuery,
  useUpdateQuestionMutation,
} from "../../../../../graphql/generated.tsx";
import ConformationModal from "../../../Components/ConformationModal.jsx";

const EditQuestion = () => {
  const { courseid, questionid } = useParams();
  const [text, setText] = useState("");
  const [questionSubText, setQuestionSubText] = useState("");
  const [testCases, setTestCases] = useState([]);
  const [marks, setMarks] = useState(0);
  const [title, setTitle] = useState("");
  const [hints, setHints] = useState([""]);
  const [editorText, setEditorText] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [courseData, setCourseData] = useState(null);
  const [category, setCategory] = useState(null);
  const [usePredefinedCode, setUsePredefinedCode] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedTestCaseId, setSelectedTestCaseId] = useState("");
  const [predefinedCode, setPredefinedCode] = useState({
    python: "",
    c: "",
    java: "",
    cpp: "",
  });
  const [showFunctionOnly, setShowFunctionOnly] = useState(false);
  const [targetFunctionName, setTargetFunctionName] = useState("");

  const editorConfig = {
    height: 300,
    menubar: false,
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "code",
      "help",
      "wordcount",
    ],
    directionality: "ltr",
    toolbar:
      "undo redo | blocks | " +
      "bold italic forecolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    branding: false, // Remove the TinyMCE branding watermark
  };

  const { data } = useGetCourseQuery({ variables: { id: courseid } });
  const [updateQuestion] = useUpdateQuestionMutation();
  const { data: QuestionData, refetch } = useGetQuestionQuery({
    variables: { id: questionid },
  });
  useEffect(() => {
    setCourseData(data?.getCourse);
  }, [data]);

  useEffect(() => {
    refetch();
    const questionData = QuestionData?.getQuestion;
    setTitle(questionData?.title);
    setText(questionData?.text);
    setEditorText(questionData?.text);
    setQuestionSubText(questionData?.question_sub_text);
    setTestCases(questionData?.test_cases);
    setMarks(questionData?.marks);
    setHints(questionData?.hints);
    setTargetFunctionName(questionData?.targetFunctionName);
    setCategory(questionData?.category);
    setDifficulty(questionData?.difficulty);
    questionData?.predefinedCode
      ? setPredefinedCode(questionData?.predefinedCode)
      : setPredefinedCode({
          python: "",
          c: "",
          java: "",
          cpp: "",
        });
    setShowFunctionOnly(questionData?.showFunctionOnly);

    if (questionData?.predefinedCode) {
      setUsePredefinedCode(true);
    }
  }, [QuestionData]);

  const handleAddTestCase = () => {
    const newTestCase = {
      id: testCases.length + 1,
      visibility: "SAMPLE",
      input: "",
      output: "",
    };
    setTestCases([...testCases, newTestCase]);
  };

  const handleRemoveTestCase = () => {
    const updatedTestCases = testCases.filter(
      (testCase) => testCase.id !== selectedTestCaseId
    );
    setTestCases(updatedTestCases);
  };

  const handleTestCaseChange = (id, field, value) => {
    const updatedTestCases = testCases?.map((testCase) =>
      testCase.id === id ? { ...testCase, [field]: value } : testCase
    );
    setTestCases(updatedTestCases);
  };

  const handleAddHint = () => {
    setHints([...hints, ""]);
  };

  const handleRemoveHint = (index) => {
    const updatedHints = hints.filter((_, i) => i !== index);
    setHints(updatedHints);
  };

  const handleHintChange = (index, value) => {
    const updatedHints = [...hints];
    updatedHints[index] = value;
    setHints(updatedHints);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!title.trim()) {
      toast.error("Title is required");
      return;
    }

    if (!text.trim()) {
      toast.error("Question text is required");
      return;
    }

    if (!category.id) {
      toast.error("Category is required");
      return;
    }

    if (!difficulty) {
      toast.error("Difficulty level is required");
      return;
    }

    if (testCases.length === 0) {
      toast.error("Test case is required");
      return;
    }

    for (let testCase of testCases) {
      if (!testCase.input.trim() || !testCase.output.trim()) {
        toast.error("Test case input and output cannot be empty");
        return;
      }
    }

    const totalMarks = testCases?.reduce(
      (total, test) => total + parseFloat(test.weightage),
      0
    );

    if (difficulty === "EASY" && (totalMarks < 30 || totalMarks > 60)) {
      toast.error("Invalid Total Mark");
      return;
    }
    if (difficulty === "MEDIUM" && (totalMarks < 50 || totalMarks > 100)) {
      toast.error("Invalid Total Mark");
      return;
    }
    if (difficulty === "HARD" && (totalMarks < 70 || totalMarks > 150)) {
      toast.error("Invalid Total Mark");
      return;
    }

    const requestBody = {
      title,
      text: editorText,
      question_sub_text: questionSubText,
      test_cases: testCases.map((test) => ({
        id: test.id,
        input: test.input,
        output: test.output,
        visibility: test.visibility,
        weightage: parseFloat(test.weightage),
      })),
      category: {
        name: category.name,
        id: category.id,
      },
      marks: totalMarks.toString(),
      difficulty: difficulty,
      hints,
      predefinedCode: predefinedCode
        ? {
            python: predefinedCode?.python,
            c: predefinedCode?.c,
            java: predefinedCode?.java,
            cpp: predefinedCode?.cpp,
          }
        : null,
      showFunctionOnly,
      targetFunctionName,
    };
    try {
      await updateQuestion({
        variables: { id: questionid, updateQuestionInput: requestBody },
      });
      toast.success("Question updated successfully");
      window.history.back();
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Error updating question");
    }
  };
  // const predefinedCodeLanguages = courseData?.languages || [];

  return (
    <AdminLayout>
      <div className="py-8 px-10 mx-auto ">
        <div className="flex items-center mb-6">
          <button
            onClick={() => window.history.back()}
            className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
          >
            <span className="gap-2 text-sm font-medium flex items-center ">
              <IoMdArrowRoundBack className="h-6 w-6" />
              Go Back
            </span>
          </button>
          <h2 className="text-3xl font-bold text-center w-[80%]">
            Edit Question
          </h2>
        </div>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="max-w-2xl mx-auto bg-white rounded-md shadow-md p-8"
        >
          <div className="mb-5">
            <div className="block">
              <Label htmlFor="title" value="Title" className="font-semibold" />
            </div>
            <TextInput
              id="title"
              type="text"
              sizing="lg"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 mb-2"
            />
          </div>
          <div className="mb-5">
            <div className="block">
              <Label
                htmlFor="questionText"
                value="Question Text"
                className="font-semibold"
              />
            </div>
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              initialValue={text}
              init={editorConfig}
              onEditorChange={(content) => setEditorText(content)}
            />
          </div>
          <div className="mb-5">
            <div className="block">
              <Label
                htmlFor="questionSubText"
                value="Question Subtext"
                className="font-semibold"
              />
            </div>
            <Textarea
              id="questionSubText"
              type="text"
              sizing="lg"
              value={questionSubText}
              onChange={(e) => setQuestionSubText(e.target.value)}
              className="w-full p-2 mb-2"
            />
          </div>
          <div className="mb-5">
            <div className="block">
              <Label
                htmlFor="category"
                value="Category"
                className="font-semibold"
              />
            </div>
            <Select
              id="category"
              value={category?.name} // Use category.name for displaying the selected category in the dropdown
              onChange={(e) => {
                const selectedCategory = courseData?.categories.find(
                  (cat) => cat.name === e.target.value
                );
                setCategory({
                  name: e.target.value,
                  id: selectedCategory ? selectedCategory.id : "",
                });
              }}
              required
            >
              {courseData?.categories?.map((cat) => (
                <option key={cat._id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
            </Select>
          </div>

          <div className="mb-5">
            <div className="block">
              <Label
                htmlFor="difficulty"
                value="Difficulty Level"
                className="font-semibold"
              />
            </div>
            <Select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Difficulty
              </option>
              <option value="EASY">Easy</option>
              <option value="MEDIUM">Medium</option>
              <option value="HARD">Hard</option>
            </Select>
          </div>

          <div className="block">
            <Label
              htmlFor="testCases"
              value="Test Cases"
              className="font-semibold"
            />
          </div>
          {testCases?.map((testCase) => (
            <div key={testCase.id} className="p-4 mb-5 border rounded-md">
              <Select
                value={testCase.visibility}
                onChange={(e) =>
                  handleTestCaseChange(
                    testCase.id,
                    "visibility",
                    e.target.value
                  )
                }
                className="mb-3 w-full"
              >
                <option value="SAMPLE">SAMPLE</option>
                <option value="HIDDEN">HIDDEN</option>
              </Select>
              <Textarea
                type="text"
                className="mb-3 w-full"
                placeholder="Input"
                value={testCase.input}
                onChange={(e) =>
                  handleTestCaseChange(testCase.id, "input", e.target.value)
                }
              />
              <Textarea
                type="text"
                className="mb-3 w-full"
                placeholder="Output"
                value={testCase.output}
                onChange={(e) =>
                  handleTestCaseChange(testCase.id, "output", e.target.value)
                }
              />
              <TextInput
                type="text"
                className="mb-3 w-full"
                placeholder="Weightage"
                value={testCase.weightage}
                onChange={(e) =>
                  handleTestCaseChange(testCase.id, "weightage", e.target.value)
                }
              />
              <MdDelete
                className="h-6 w-8 m-2 cursor-pointer text-gray-500 hover:text-red-500"
                onClick={() => {
                  setIsConfirmationModalOpen(true);
                  setSelectedTestCaseId(testCase.id);
                }}
              />
            </div>
          ))}
          <Button
            className="h-9 mb-5 flex items-center bg-primary"
            onClick={handleAddTestCase}
          >
            <IoMdAdd className="p-0 mr-1 mt-1" /> <span>Add testcase</span>
          </Button>

          <div className="block">
            <Label htmlFor="hints" value="Hints" className="font-semibold" />
          </div>
          {hints?.map((hint, index) => (
            <div key={index} className="p-2 border rounded-md mb-2">
              <TextInput
                type="text"
                className="w-full mb-3"
                placeholder={`Hint ${index + 1}`}
                value={hint}
                onChange={(e) => handleHintChange(index, e.target.value)}
              />
              <MdDelete
                className="h-6 w-8 m-2 cursor-pointer text-gray-500 hover:text-red-500"
                onClick={() => handleRemoveHint(index)}
              />
            </div>
          ))}
          <Button
            className="h-9 mb-5 flex items-center bg-primary"
            onClick={handleAddHint}
          >
            <IoMdAdd className="mr-1 mt-1" /> <span>Add hint</span>
          </Button>
          <div className="block">
            <Label htmlFor="marks" value="Marks" className="font-semibold" />
          </div>
          <TextInput
            required
            type="number"
            className="mb-5"
            disabled
            value={testCases?.reduce(
              (total, test) => total + parseFloat(test.weightage),
              0
            )}
          />
          <div className="mb-5">
            <Checkbox
              id="usePredefinedCode"
              checked={usePredefinedCode}
              onChange={(e) => setUsePredefinedCode(e.target.checked)}
            />
            <Label
              htmlFor="usePredefinedCode"
              value="Use Predefined Code"
              className="ml-4"
            />
          </div>
          {usePredefinedCode && (
            <>
              {["python", "c", "java", "cpp"].map((language) => {
                const tooltipContent = {
                  python: pythonTooltipContent,
                  c: cTooltipContent,
                  java: javaTooltipContent,
                  cpp: cppTooltipContent,
                }[language];

                return (
                  <div key={language} className="mb-5">
                    <div className="flex items-center">
                      <Label
                        htmlFor={`predefinedCode${language}`}
                        value={`Predefined Code (${language.toUpperCase()})`}
                        className="font-semibold"
                      />
                      <Tooltip
                        content={
                          <div
                            dangerouslySetInnerHTML={{ __html: tooltipContent }}
                          />
                        }
                        className="h-[150px]"
                        placement="right"
                        style="light"
                      >
                        <FaCircleInfo className="ml-2" color="gray" />
                      </Tooltip>
                    </div>
                    <Textarea
                      id={`predefinedCode${language}`}
                      value={predefinedCode[language]}
                      onChange={(e) =>
                        setPredefinedCode({
                          ...predefinedCode,
                          [language]: e.target.value,
                        })
                      }
                      placeholder={`Add predefined code for ${language}...`}
                    />
                  </div>
                );
              })}

              <div className="mb-5">
                <Checkbox
                  id="showFunctionOnly"
                  checked={showFunctionOnly}
                  onChange={(e) => setShowFunctionOnly(e.target.checked)}
                />
                <Label
                  htmlFor="showFunctionOnly"
                  value="Show Function Only"
                  className="ml-4"
                />
              </div>
              {showFunctionOnly && (
                <div className="mb-5">
                  <Label
                    htmlFor="targetFunctionName"
                    value="Target Function Name"
                    className="font-semibold"
                  />
                  <TextInput
                    id="targetFunctionName"
                    value={targetFunctionName}
                    onChange={(e) => setTargetFunctionName(e.target.value)}
                    placeholder="Enter the target function name..."
                  />
                </div>
              )}
            </>
          )}

          <Button type="sumbit" className="bg-primary p-0">
            Update
          </Button>
        </form>
        <ConformationModal
          openModal={isConfirmationModalOpen}
          setOpenModal={setIsConfirmationModalOpen}
          event={handleRemoveTestCase} // Call confirmDelete on confirmation
          text="Are you sure you want to delete this TestCase?"
        />
      </div>
    </AdminLayout>
  );
};

export default EditQuestion;
