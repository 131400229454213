import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

const SubmitModel = ({ output, question }) => {
  const [countdown, setCountdown] = useState(5);
  const totalPassed = output?.testCasePassed.count;
  const totalFailed = output?.testCaseFailed.count;
  const allPassed = totalFailed === 0;
  const hasError = output?.compilationOutput?.stderr;

  useEffect(() => {
    if (allPassed && !hasError) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          return prevCountdown > 0 ? prevCountdown - 1 : 0;
        });
      }, 1000);

      const timeout = setTimeout(() => {
        window.history.back();
      }, 5000);

      return () => {
        clearInterval(timer);
        clearTimeout(timeout);
      };
    }
  }, [allPassed, hasError]);

  return (
    <div className="p-5 bg-white rounded-md shadow-md mt-5">
      {hasError ? (
        <div className="text-red-600">
          <p>Error during compilation:</p>
          <div className="overflow-x-auto">
            <pre>{hasError}</pre>
          </div>
        </div>
      ) : (
        <>
          {allPassed && <Confetti width={window?.innerWidth} height={1400} />}
          <p>Passed Test Cases: {totalPassed}</p>
          <p>Total Test Cases: {totalPassed + totalFailed}</p>
          <p className="mb-2">Test Cases:</p>
          <div className="grid grid-cols-2 gap-4">
            {question?.test_cases.map((test, index) => {
              const isFailed = output?.testCaseFailed.testCaseIds?.some(
                (failedTest) => failedTest?.testCaseId === index
              );
              const isPassed = output?.testCasePassed?.testCaseIds?.some(
                (passedTest) => passedTest?.testCaseId === index
              );

              return (
                <div
                  key={test?.id}
                  className={`border shadow-sm p-4 rounded-md ${
                    isFailed
                      ? "border-r-4 border-r-red-500"
                      : "border-r-4 border-r-green-500"
                  }`}
                >
                  <p>Test Case {index + 1}</p>
                  {isFailed && <p className="text-red-500">Failed</p>}
                  {isPassed && <p className="text-green-500">Passed</p>}
                </div>
              );
            })}
          </div>
          {allPassed && (
            <p className="mt-5 text-green-600 font-semibold">
              All test cases passed! Redirecting in {countdown} seconds...
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default SubmitModel;
