import { Route } from "react-router-dom";
import AssignToStudents from "../../Modules/Admin/Screens/Assignments/AssignToStudents";
import CreateAssignment from "../../Modules/Admin/Screens/Assignments/CreateAssignment";
import CreateAssignmentQuestion from "../../Modules/Admin/Screens/Assignments/Questions/CreateAssignmentQuestion";
import EditAssignmentQuestion from "../../Modules/Admin/Screens/Assignments/Questions/EditAssignmentQuestion";
import ImportAssignmentQuestion from "../../Modules/Admin/Screens/Assignments/Questions/ImportAssignmentQuestion";
import StudentListing from "../../Modules/Admin/Screens/Assignments/Studentlisting";
import AssignmentDetails from "../../Modules/Admin/Screens/Assignments/AssignmentDetails";
import Assignments from "../../Modules/Admin/Screens/Assignments/Assignments";
import PreviewAssignmentQuestion from "../../Modules/Admin/Screens/Assignments/Questions/PreviewAssignmentQuestion";

const AssignmentRoutes = [
  <Route
    key="assignments"
    path="/admin/assignments"
    element={<Assignments />}
  />,
  <Route
    key="create-assignment"
    path="/admin/assignments/create-assignment"
    element={<CreateAssignment />}
  />,
  <Route
    key="assignment-details"
    path="/admin/assignments/:id"
    element={<AssignmentDetails />}
  />,
  <Route
    key="editAssignmentQuestion"
    path="/admin/assignments/:assignmentid/edit-question/:assignmentQuestionId"
    element={<EditAssignmentQuestion />}
  />,
  <Route
    key="importAssignmentQuestion"
    path="/admin/assignments/:assignmentid/import-question"
    element={<ImportAssignmentQuestion />}
  />,
  <Route
    key="createAssignmentQuestion"
    path="/admin/assignments/:id/add-question"
    element={<CreateAssignmentQuestion />}
  />,
  <Route
    key="assign-to-students"
    path="/admin/assignments/assign-to-students"
    element={<AssignToStudents />}
  />,
  <Route
    key="editAssignmentQuestion"
    path="/admin/assignments/:assignmentId/preview-question/:questionId"
    element={<PreviewAssignmentQuestion />}
  />,
  <Route
    key="student-listing"
    path="/admin/assignments/:assignmentId/assign-to-students/:campusId"
    element={<StudentListing />}
  />,
];

export default AssignmentRoutes;
