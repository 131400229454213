import { ChangeEvent, FormEvent, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { confirmThePasswordReset } from "../../utils/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Label, TextInput, Spinner } from "flowbite-react";
import NavBar from "../../Common/NavBar";

const defaultFormFields = {
  password: "",
  confirmPassword: "",
};

function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { password, confirmPassword } = formFields;
  const [successMessage, setSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  let oobCode = searchParams.get("oobCode");
  const resetFormFields = () => setFormFields(defaultFormFields);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords did not match.");
      return;
    }

    try {
      setIsLoading(true); // Set loading to true
      if (oobCode) {
        await confirmThePasswordReset(oobCode, confirmPassword);
        resetFormFields();
        setSuccessMessage(true);
        toast.success("Success! Your password has been changed.");
      } else {
        toast.error("Something is wrong; try again later!");
        console.log("missing oobCode");
      }
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-action-code":
          toast.error("Invalid action code. Please try again.");
          break;
        case "auth/too-many-requests":
          toast.error("Too many attempts. Please try again later.");
          break;
        default:
          toast.error("An unexpected error occurred. Please try again.");
          break;
      }
      console.log("Error message:", error.message);
    } finally {
      setIsLoading(false); // Set loading back to false
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <>
      <NavBar />
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
        <ToastContainer />
        {successMessage ? (
          <div className="text-center p-10 bg-white rounded shadow-md w-full max-w-sm">
            <div className="mb-8">
              <img
                className="mx-auto h-28 w-auto"
                src="/favicon.ico"
                alt="Your Company"
              />
            </div>
            <h3 className="text-2xl text-green-600 font-semibold mb-4">
              Success! Your password has been changed.
            </h3>
            <p className="text-gray-600 mb-6">
              You can now log in with your new password.
            </p>
            <button
              onClick={() => navigate("/login")}
              className="w-full bg-primary text-white py-2 rounded-md text-lg hover:bg-primary-dark transition duration-300 ease-in-out"
            >
              Sign in
            </button>
          </div>
        ) : (
          <>
            <div className="bg-white p-10 rounded shadow-md w-full max-w-md">
              <form onSubmit={handleSubmit}>
                <img
                  className="mx-auto h-24 w-auto"
                  src="/favicon.ico"
                  alt="Your Company"
                />
                <h2 className="text-2xl font-bold mb-4 text-center mt-8">
                  Reset Password
                </h2>
                <Label>New Password</Label>
                <div className="mb-4">
                  <TextInput
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    placeholder="Enter the new Password"
                    required
                  />
                </div>
                <Label>Confirm Password</Label>
                <div className="mb-4">
                  <TextInput
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    placeholder="Re-enter the password"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full bg-primary text-white py-2 rounded hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 flex items-center justify-center cursor-pointer"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner size="sm" className="mr-2" />}
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ResetPassword;
