import React from "react";
import NavBar from "../../Common/NavBar";
import Home from "./Components/Home";

function HomePage() {
  return (
    <div className="bg-neutral-50 ">
      <NavBar />
      <Home />
    </div>
  );
}

export default HomePage;
