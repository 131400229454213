import { Card } from "flowbite-react";
import React from "react";

const CourseListing = ({ Courses }) => {
  return (
    <>
      <h1 className="mt-5 text-2xl font-semibold"> Courses,</h1>
      <div className="grid grid-cols-3 gap-4 ">
        {Courses?.getDashboardCourseDetails.map((course) => (
          <Card className="max-w-sm mt-5">
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {course.courseName}
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {course.coursedesc}
            </p>
            <div className="w-full bg-secondary rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-primary h-2.5 rounded-full"
                style={{ width: `${course.percentageCompleted}%` }}
              ></div>
            </div>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {course.percentageCompleted}%
            </p>
          </Card>
        ))}
      </div>
    </>
  );
};

export default CourseListing;
