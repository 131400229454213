import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs} from "flowbite-react";
import { HiAdjustments, HiUserCircle, HiViewBoards } from "react-icons/hi";
import { MdAssignment} from "react-icons/md";
import {
  useGetClusterQuery,
  useGetCampusByNameQuery,
} from "../../../../graphql/generated.tsx";
import StudentsTab from "../../Components/ManageClusterTabs/StudentsTab.jsx";
import ManageCourseTab from "../../Components/ManageClusterTabs/ManageCourseTab.jsx";
import SettingsTab from "../../Components/ManageClusterTabs/SettingsTab.jsx";
import StaffManageTab from "../../Components/ManageClusterTabs/StaffManageTab.jsx";
import AdminLayout from "../../Layout/index.jsx";
import ManageAssignmentTab from "../../Components/ManageClusterTabs/ManageAssignmentTab.jsx";

const ClusterStudentListing = () => {
  const { clusterId, campusName } = useParams();

  const [formData, setFormData] = useState({
    name: "",
  });

  const { data: ClusterData } = useGetClusterQuery({
    variables: { campusName, clusterId },
  });

  const { data: CampusIdData } = useGetCampusByNameQuery({
    variables: { name: campusName },
  });

  const campusId = CampusIdData?.getCampusByName?.id;

  useEffect(() => {
    if (ClusterData && ClusterData.getCluster) {
      setFormData({
        name: ClusterData.getCluster.name || "",
      });
    }
  }, [ClusterData]);

  return (
    <AdminLayout>
      <Tabs aria-label="Tabs with icons" style="underline">
        <Tabs.Item active title="Students" icon={HiUserCircle}>
          <StudentsTab
            campusId={campusId}
            clusterId={clusterId}
            ClusterData={ClusterData}
            campusName={campusName}
          />
        </Tabs.Item>

        <Tabs.Item title="Manage Courses" icon={HiViewBoards}>
          <ManageCourseTab campusName={campusName} clusterId={clusterId} />
        </Tabs.Item>
        <Tabs.Item title="Manage Assignment" icon={MdAssignment}>
          <ManageAssignmentTab campusName={campusName} clusterId={clusterId} />
        </Tabs.Item>
        <Tabs.Item title="Settings" icon={HiAdjustments}>
          <SettingsTab
            campusName={campusName}
            clusterId={clusterId}
            formData={formData}
            setFormData={setFormData}
            ClusterData={ClusterData}
          />
        </Tabs.Item>
        <Tabs.Item title="Staff Management" icon={HiAdjustments}>
          <StaffManageTab
            campusName={campusName}
            clusterId={clusterId}
            campusId={campusId}
            ClusterData={ClusterData}
          />
        </Tabs.Item>
      </Tabs>
    </AdminLayout>
  );
};

export default ClusterStudentListing;
