import React, { useEffect, useState } from "react";
import { Button, Card, Badge } from "flowbite-react";
import {
  useGetCampusQuery,
  useGetLastFiveSubmissionsQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";

const StudentQuestionCard = ({ data, courseId, catName, clusterid }) => {
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  const { data: studentData, refetch } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const { data: campusData } = useGetCampusQuery({
    variables: { id: studentData?.getStudentByEmailId?.campusId },
    skip: !studentData,
  });

  const submitInput = {
    campus_name: campusData?.campus?.name,
    cluster_id: clusterid,
    student_id: studentData?.getStudentByEmailId?.id,
    course_id: courseId,
    question_id: data.id,
  };
  const { data: submissions } = useGetLastFiveSubmissionsQuery({
    variables: { submitInput },
    skip: !submitInput,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  function getDifficultyColor(difficulty) {
    switch (difficulty) {
      case "EASY":
        return "green";
      case "MEDIUM":
        return "yellow";
      case "HARD":
        return "red";
      default:
        return "gray";
    }
  }

  const difficulty = data?.difficulty
    ? data.difficulty.charAt(0).toUpperCase() +
      data.difficulty.slice(1).toLowerCase()
    : "Unknown";

  const isCompleted = submissions?.getLastFiveSubmissions?.is_completed;

  return (
    <>
      <Card
        href={`/student/courses/question/${clusterid}/${courseId}/${data?.id}`}
        className="min-w-full mb-2"
      >
        <div className="flex max-md:flex-col justify-between lg:items-center">
          <div className="flex flex-col w-[70%] justify-between gap-2 max-md:w-full">
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {data?.title || "No Title"}
            </h5>
            <p className="font-light text-gray-700 text-sm dark:text-gray-400">
              Category: {catName}, Max Score: {data?.marks || "N/A"}
            </p>
            <div></div>
            {data?.difficulty && (
              <Badge
                color={getDifficultyColor(data.difficulty)}
                className="mt-2 w-16"
              >
                {difficulty}
              </Badge>
            )}
          </div>
          {isCompleted && (
            <Badge color="green" className="mt-2  w-20">
              Completed
            </Badge>
          )}

          <Button className="p-0 h-10 max-md:mt-4 bg-primary">
            Solve Problem
          </Button>
        </div>
      </Card>
    </>
  );
};

export default StudentQuestionCard;
