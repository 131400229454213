import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  Button,
  Select as FlowbiteSelect,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import {
  useBulkUploadStudentsMutation,
  useCreateClusterMutation,
  useGetAllCoursesQuery,
  useGetCampusQuery,
  useGetClusterQuery,
  useGetClustersByCampusQuery,
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select"; // Import react-select
import AdminLayout from "../../Layout/index.jsx";

// Step 1: Bulk Create Students
const BulkCreateStudentsStep = ({ formData, setFormData, nextStep }) => {
  const [inputValue, setInputValue] = useState(formData.StudentsInput || "");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setFormData((prev) => ({ ...prev, StudentsInput: e.target.value }));
  };

  const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emails.every((email) => emailRegex.test(email));
  };

  const handleNextStep = () => {
    const emails = inputValue.split(",").map((email) => email.trim());
    if (!validateEmails(emails)) {
      toast.error(
        "Invalid email(s) found. Please enter valid email addresses."
      );
      return;
    }
    const students = emails.map((email) => ({ email }));
    setFormData((prev) => ({ ...prev, students }));
    nextStep();
  };

  return (
    <div className="flex flex-col justify-center h-full">
      <form className="m-5 flex flex-col justify-center items-center">
        <Textarea
          type="text"
          required
          name="students"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter student emails, separated by commas"
          className="border p-2 w-full mb-4"
        />
        <div className="mt-auto">
          <Button className="p-0 bg-primary" onClick={handleNextStep}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

// Step 2: Create or Add to Existing Cluster
const ClusterStep = ({
  formData,
  setFormData,
  prevStep,
  nextStep,
  campusDetails,
  setClusterId,
}) => {
  const { data } = useGetClustersByCampusQuery({
    variables: { campusName: campusDetails?.campus?.name },
  });
  const { data: ClusterData } = useGetClusterQuery({
    variables: {
      campusName: campusDetails?.campus?.name,
      clusterId: formData.existingCluster,
    },
  });
  const { id } = useParams();
  const [createCluster] = useCreateClusterMutation();
  const { data: coursesData } = useGetAllCoursesQuery(); // Fetch courses data

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Disable existingCluster dropdown if newCluster input is filled
    if (name === "newCluster" && value) {
      setFormData((prev) => ({ ...prev, existingCluster: "" }));
    }

    // Disable newCluster input if existingCluster dropdown is selected
    if (name === "existingCluster" && value) {
      setFormData((prev) => ({ ...prev, newCluster: "" }));
    }
  };

  const handleCoursesChange = (selectedOptions) => {
    setFormData((prev) => ({
      ...prev,
      selectedCourses: selectedOptions.map((option) => option.value),
    }));
  };

  const handleSubmit = async () => {
    let clusterId = formData.existingCluster;
    let clusterName = "";

    // If newCluster is filled, create a new cluster
    if (formData.newCluster) {
      const response = await createCluster({
        variables: {
          createClusterInput: {
            name: formData.newCluster,
            campusName: campusDetails.campus.name,
            courseIds: formData.selectedCourses,
          },
        },
      });
      clusterId = response.data.createCluster.id;
      setClusterId(clusterId);
      clusterName = formData.newCluster;
    } else {
      // Get cluster name from existing clusters data
      const selectedCluster = data?.getClustersByCampus?.find(
        (cluster) => cluster.id === formData.existingCluster
      );
      clusterName = selectedCluster ? selectedCluster.name : "";
    }
    setFormData((prev) => ({ ...prev, clusterName }));

    // Assign clusterId and selectedCourses to students
    const updatedStudents = formData.students.map((student) => ({
      ...student,
      clusterIds: [clusterId],
      campusId: id,
      courseIds: formData.selectedCourses || ClusterData.getCluster.courseIds, // Add selected courses
    }));
    setClusterId(clusterId);
    setFormData((prev) => ({ ...prev, students: updatedStudents }));

    nextStep();
  };

  const coursesOptions = coursesData?.getAllCourses?.map((course) => ({
    value: course.id,
    label: course.name,
  }));

  return (
    <div className="flex flex-col justify-between h-full">
      <form className="m-5 flex flex-col justify-center items-center">
        <div className="mb-4 w-full">
          <TextInput
            type="text"
            name="newCluster"
            value={formData.newCluster || ""}
            onChange={handleInputChange}
            placeholder="Enter new cluster name"
            disabled={!!formData.existingCluster}
          />
        </div>
        {!!formData.newCluster && (
          <div className="mb-4 w-full">
            <Select
              isMulti
              options={coursesOptions}
              onChange={handleCoursesChange}
              placeholder="Select courses"
            />
          </div>
        )}
        <p className="text-center text-gray-500 mb-4">
          ------------ or ------------
        </p>
        <div className="mb-4 w-full">
          <FlowbiteSelect
            name="existingCluster"
            value={formData.existingCluster || ""}
            onChange={handleInputChange}
            disabled={!!formData.newCluster}
          >
            <option value="">Select existing cluster</option>
            {data?.getClustersByCampus?.map((cluster) => (
              <option key={cluster.id} value={cluster.id}>
                {cluster.name}
              </option>
            ))}
          </FlowbiteSelect>
        </div>
      </form>
      <div className="flex justify-between m-5">
        <Button className="p-0 bg-primary" onClick={prevStep}>
          Prev
        </Button>
        <Button
          className="p-0 bg-primary"
          onClick={handleSubmit}
          disabled={
            !formData.selectedCourses?.length &&
            !formData.existingCluster?.length
          }
        >
          Submit
        </Button>
      </div>
    </div>
  );
};


const ReviewStep = ({ formData, prevStep, submitForm, createLoading }) => {
  const students = formData.students || [];
  const clusterName = formData.clusterName;

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="m-5">
        <div>
          <h3 className="text-lg font-semibold">Students:</h3>
          <ul className="max-h-40 overflow-y-auto">
            {students.map((student, index) => (
              <li key={index}>{student.email}</li>
            ))}
          </ul>
        </div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Cluster:</h3>
          <p>{clusterName}</p>
        </div>
      </div>
      <div className="flex justify-between m-5">
        <Button className="p-0 bg-primary" onClick={prevStep}>
          Prev
        </Button>
        <Button
          className="p-0 bg-primary flex items-center justify-center"
          onClick={submitForm}
          disabled={createLoading}
        >
          {createLoading ? (
            <>
              <Spinner size="sm" className="mr-2 mb-1" /> Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </div>
  );
};


const CreateStudents = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: campusDetails } = useGetCampusQuery({ variables: { id: id } });
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);
  const [bulkupload,{loading: createLoading}] = useBulkUploadStudentsMutation();
  const [clusterid, setClusterId] = useState("");

  const submitForm = async () => {
    try {
      await bulkupload({
        variables: {
          students: formData.students,
          campusName: campusDetails?.campus?.name,
          clusterId: clusterid,
        },
      });

      toast.success("Created student Successfully");

      navigate(`/admin/students/${id}`);

      setFormData({});
      setCurrentStep(0);
    } catch (error) {
      toast.error("Error in creating students, Check your inputs");
    }
  };

  return (
    <AdminLayout>
      <div className="p-10 min-h-screen">
        <div className="flex justify-between items-center max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              Create Students
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-10">
          <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-2xl h-[500px] flex flex-col">
            <div className="mt-4 flex flex-col flex-grow">
              <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                <li
                  className={`flex items-center ${
                    currentStep === 0 ? "text-blue-600 dark:text-blue-500" : ""
                  }`}
                >
                  <span
                    className={`flex items-center justify-center w-5 h-5 me-2 text-xs border ${
                      currentStep === 0
                        ? "border-blue-600 dark:border-blue-500"
                        : "border-gray-500 dark:border-gray-400"
                    } rounded-full shrink-0`}
                  >
                    1
                  </span>
                  Bulk Create Students
                  <svg
                    className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li
                  className={`flex items-center ${
                    currentStep === 1 ? "text-blue-600 dark:text-blue-500" : ""
                  }`}
                >
                  <span
                    className={`flex items-center justify-center w-5 h-5 me-2 text-xs border ${
                      currentStep === 1
                        ? "border-blue-600 dark:border-blue-500"
                        : "border-gray-500 dark:border-gray-400"
                    } rounded-full shrink-0`}
                  >
                    2
                  </span>
                  Create/Add to Cluster
                  <svg
                    className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li
                  className={`flex items-center ${
                    currentStep === 2 ? "text-blue-600 dark:text-blue-500" : ""
                  }`}
                >
                  <span
                    className={`flex items-center justify-center w-5 h-5 me-2 text-xs border ${
                      currentStep === 2
                        ? "border-blue-600 dark:border-blue-500"
                        : "border-gray-500 dark:border-gray-400"
                    } rounded-full shrink-0`}
                  >
                    3
                  </span>
                  Review
                </li>
              </ol>
              {currentStep === 0 && (
                <BulkCreateStudentsStep
                  formData={formData}
                  setFormData={setFormData}
                  nextStep={nextStep}
                />
              )}
              {currentStep === 1 && (
                <ClusterStep
                  formData={formData}
                  setFormData={setFormData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  campusDetails={campusDetails}
                  setClusterId={setClusterId}
                />
              )}
              {currentStep === 2 && (
                <ReviewStep
                  formData={formData}
                  prevStep={prevStep}
                  submitForm={submitForm}
                  createLoading={createLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CreateStudents;
