import React from "react";

const Input = (props) => {
  const { value, onChange, required, id, name, type, autoComplete, disabled } =
    props;
  return (
    <input
      id={id}
      name={name}
      type={type}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      required={required}
      disabled={disabled}
    />
  );
};

export default Input;
