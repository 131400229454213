import React, { useEffect, useCallback, useState } from "react";
import Timer from "../Components/Timer";
import {
  useFinalSubmitAssignmentMutation,
  useGetAssignementQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import Detection from "../Components/Detection/index.jsx";
import swal from "sweetalert";
import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const TestLayout = ({ children, assignmentId, disableSubmit, previewMode }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { data: assignmentData } = useGetAssignementQuery({
    variables: { id: assignmentId },
  });
  const [submit, setSubmit] = useState(false);
  const studentEmail = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email: studentEmail },
  });
  const cheatCount = parseInt(
    sessionStorage.getItem("count_facedetect") || "0",
    10
  );
  const tabSwitches = parseInt(
    sessionStorage.getItem("count_tabchange") || "0",
    10
  );
  const { data: campus } = useGetCampusQuery({
    variables: { id: studentData?.getStudentByEmailId?.campusId },
    skip: !studentData?.getStudentByEmailId,
  });

  useEffect(() => {
    if (assignmentData?.getAssignement?.enable_proctoring) {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          const countTabChange = tabSwitches + 1;
          swal(
            "Changed Tab Detected",
            `Action has been Recorded. Tab switches: ${countTabChange}`,
            "error"
          );
          sessionStorage.setItem("count_tabchange", countTabChange.toString());
          if (
            countTabChange >= assignmentData?.getAssignement?.max_tabSwitches &&
            !submit
          ) {
            handleSubmit();
            setSubmit(true);
          }
        }
      };

      document.addEventListener(
        "visibilitychange",
        handleVisibilityChange,
        false
      );
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [tabSwitches, assignmentData?.getAssignement?.enable_proctoring]);

  const [finalSubmitAssignment] = useFinalSubmitAssignmentMutation();

  const handleSubmit = useCallback(async () => {
    try {
      await finalSubmitAssignment({
        variables: {
          AssignmentInput: {
            assignment_id: assignmentId,
            cheat_count: assignmentData?.getAssignement?.enable_proctoring
              ? cheatCount
              : 0,
            tabSwitches: assignmentData?.getAssignement?.enable_proctoring
              ? tabSwitches
              : 0,
            student_id: studentData?.getStudentByEmailId?.id,
            campus_name: campus?.campus?.name,
            cluster_id: studentData?.getStudentByEmailId?.clusterIds[0],
          },
        },
      });

      swal("Submitted!", "Your assignment has been submitted.", "success").then(
        () => {
          localStorage.removeItem("timerStartTime");
          localStorage.removeItem("timerDuration");
          if (document.fullscreenElement) {
            document.exitFullscreen().then(() => {
              navigate(`/assignments/${assignmentId}`);
              window.location.reload();
            });
          } else {
            navigate(`/assignments/${assignmentId}`);
            window.location.reload();
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }, [
    finalSubmitAssignment,
    assignmentId,
    cheatCount,
    tabSwitches,
    studentData,
    navigate,
    assignmentData?.getAssignement?.enable_proctoring,
  ]);

  return (
    <div className="bg-slate-100 min-w-screen min-h-screen p-14 max-md:p-5">
      {!previewMode && (
        <div className="flex justify-between mb-10">
          <Timer
            durationInHours={assignmentData?.getAssignement?.duration}
            onTimeUp={handleSubmit}
          />
          {!disableSubmit && (
            <Button
              className="bg-primary p-0 text-center flex items-center"
              onClick={() => setOpenModal(true)}
            >
              Submit Test
            </Button>
          )}
        </div>
      )}
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
        position="center"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to submit the test?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="success" onClick={handleSubmit}>
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpenModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {!previewMode && assignmentData?.getAssignement?.enable_proctoring && (
        <Detection
          max_cheat_count={assignmentData?.getAssignement?.max_cheat_count}
          handleSubmit={handleSubmit}
        />
      )}

      <main>{children}</main>
    </div>
  );
};

export default TestLayout;
