import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import { useParams } from "react-router-dom";
import { CommandLineIcon } from "@heroicons/react/24/solid";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaEllipsisV } from "react-icons/fa";
import EditAssignmentModal from "../../Components/EditAssignmentModal.jsx";
import {
  useDeleteAssignmentQuestionMutation,
  useGetAdminUsingEmailQuery,
  useGetAllAssignementQuestionQuery,
  useGetAssignementQuery,
} from "../../../../graphql/generated.tsx";
import formatDate from "../../Helpers/formatDate.jsx";
import AssignmentQuestionListing from "./Questions/AssignmentQuestionListing.jsx";
import { Dropdown } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { GiAlarmClock } from "react-icons/gi";
import { CiClock2, CiViewList } from "react-icons/ci";

const AssignmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [assignment, setAssignments] = useState();
  const [showModal, setShowModal] = useState(false);
  const [assignmentQuestion, setAssignmentQuestion] = useState();

  const { data, loading } = useGetAssignementQuery({ variables: { id } });
  const { data: AssignmentQuestions, refetch } =
    useGetAllAssignementQuestionQuery({
      variables: { assignmentId: id },
    });
  const [deleteAssignmentQuestion] = useDeleteAssignmentQuestionMutation();

  useEffect(() => {
    if (data?.getAssignement) setAssignments(data?.getAssignement);
  }, [data]);

  useEffect(() => {
    refetch();
    if (AssignmentQuestions?.getAllAssignementQuestion) {
      setAssignmentQuestion(AssignmentQuestions.getAllAssignementQuestion);
    }
  }, [AssignmentQuestions]);

  const handleEditClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = async (questionId) => {
    try {
      await deleteAssignmentQuestion({ variables: { id: questionId } });
      setAssignmentQuestion(
        assignmentQuestion.filter((question) => question.id !== questionId)
      );
    } catch (error) {
      console.error("Error removing assignment ID from question:", error);
    }
  };

  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: admin } = useGetAdminUsingEmailQuery({
    variables: { email },
    skip: !email,
  });

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          Loading...
        </div>
      </AdminLayout>
    );
  }
  return (
    <AdminLayout>
      <div className="p-8 max-md:p-5">
        <button
          onClick={() => window.history.back()}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <span className="gap-2 text-sm font-medium flex items-center">
            <IoMdArrowRoundBack className="h-6 w-6" />
            Go Back
          </span>
        </button>
        <div className="px-6 py-8 rounded-md shadow-sm bg-white relative mt-5">
          <div className="absolute top-7 right-5">
            <Dropdown
              arrowIcon={false}
              inline={true}
              className="w-44"
              label={
                <FaEllipsisV className="h-6 w-6 text-gray-500 hover:text-primary cursor-pointer" />
              }
            >
              {admin?.getAdminUsingEmail?.isSuperAdmin && (
                <>
                  <Dropdown.Item onClick={handleEditClick}>
                    Edit Assignment
                  </Dropdown.Item>
                  <hr className="w-full" />
                </>
              )}
              <Dropdown.Item
                onClick={() =>
                  navigate(`/admin/assignments/${id}/add-question`)
                }
              >
                Add New Question
              </Dropdown.Item>
              <hr className="w-full" />
              <Dropdown.Item
                onClick={() =>
                  navigate(`/admin/assignments/${id}/import-question`)
                }
              >
                Import New Question
              </Dropdown.Item>
              <hr className="w-full" />
            </Dropdown>
          </div>
          {showModal && (
            <EditAssignmentModal
              id={id}
              onClose={handleCloseModal}
              setAssignments={setAssignments}
              assignment={assignment}
            />
          )}
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <div className="inline-block bg-orange-100 p-6 rounded-lg max-md:w-1/2">
              <CommandLineIcon className="h-12 w-12 text-orange-500" />
            </div>
            <div className="md:max-w-screen-sm">
              <div className="font-bold text-xl mb-2">{assignment?.name}</div>
              <div className="text-slate-500 text-sm">
                {assignment?.description}
              </div>
            </div>
          </div>
          <hr className="w-screen-md my-6" />
          <div className="flex gap-32 flex-wrap">
            <div className="flex flex-col justify-center items-center">
              <CiClock2 className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">Due Date</span>
              <span className="font-bold">
                {formatDate(assignment?.due_date) || "Not Set"}
              </span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <CiViewList className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">
                Number of Questions
              </span>
              <span className="font-bold">
                {assignmentQuestion ? assignmentQuestion.length : 0}
              </span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <GiAlarmClock className="w-5 h-5 text-slate-500" />
              <span className="text-sm text-slate-500 mb-1">Duration</span>
              <span className="font-bold">
                {assignment ? `${assignment?.duration} hrs` : 0}
              </span>
            </div>
          </div>
        </div>

        <h1 className="text-center text-2xl font-bold my-5">Questions</h1>
        <div className="mt-8 bg-white p-8 max-md:p-5 rounded-md min-h-2xl">
          {assignmentQuestion?.map((assignment, index) => (
            <div key={index}>
              <AssignmentQuestionListing
                onDelete={handleDelete}
                data={assignment}
                assignmentId={id}
              />
              {index !== assignmentQuestion.length - 1 && (
                <hr className="px-8 my-4" />
              )}
            </div>
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AssignmentDetails;
