import { initializeApp } from "firebase/app";
import {
  confirmPasswordReset,
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAzapiuGWyhecdA5DTn9N_86z8s3nqlkAU",
  authDomain: "bt-tool-test-8b921.firebaseapp.com",
  projectId: "bt-tool-test-8b921",
  storageBucket: "bt-tool-test-8b921.appspot.com",
  messagingSenderId: "790703157638",
  appId: "1:790703157638:web:dda20e4dd5aabb62c87c07",
  measurementId: "G-CNFV4SZQ51",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const passwordReset = async (email) => {
  return await sendPasswordResetEmail(auth, email);
};

export const confirmThePasswordReset = async (oobCode, newPassword) => {
  if (!oobCode && !newPassword) return;

  return await confirmPasswordReset(auth, oobCode, newPassword);
};
