import { Card } from "flowbite-react";
import React from "react";

const StudentCategoryCard = (props) => {
  const { name, description, cat_id, course_id, clusterid } = props;
  return (
    <>
      <Card
        href={`/student/courses/${clusterid}/${course_id}/${cat_id}`}
        className="w-[700px]"
      >
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {name}
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {description}
        </p>
      </Card>
    </>
  );
};

export default StudentCategoryCard;
