import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API, // Replace with your GraphQL endpoint
});

// Set up a context link to add headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("idtoken");
  const uid = JSON.parse(localStorage.getItem("user")).uid;

  return {
    headers: {
      ...headers,
      clientid: uid,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
