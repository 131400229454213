import { Route } from "react-router-dom";
import Campus from "../../Modules/Admin/Screens/Manage_Campus/Campus";
import CampusDetails from "../../Modules/Admin/Screens/Manage_Campus/CampusDetails";
import SuperAdmin from "../../Modules/Admin/Screens/Bt_Admin/SuperAdmin";
import ManageClusters from "../../Modules/Admin/Screens/Manage_Cluster/ManageClusters";
import ClusterListing from "../../Modules/Admin/Screens/Manage_Cluster/ClusterListing";

const SuperAdminRoutes = [
  <Route key="bt-admin" path="admin/bt-admin" element={<SuperAdmin />} />,
];

export default SuperAdminRoutes;
