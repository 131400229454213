import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import "codemirror/mode/clike/clike";
import "codemirror/mode/python/python";
import PracticeLayout from '../../../../Practice/Layout/index.jsx';
import BackButton from '../../../../Practice/Components/BackButton.jsx';
import CompilerQuestionCard from '../../../../Practice/Components/CompilerQuestionCard.jsx';
import Compiler from '../../../../Practice/Components/Compiler.jsx';
import CustomInput from '../../../../Practice/Components/CustomInput.jsx';
import RunModel from '../../../../Practice/Components/RunModel.jsx';
import ErrorModel from '../../../../Practice/Components/ErrorModel.jsx';
import SubmitModel from '../../../../Practice/Components/SumbitModel.jsx';
import {
  useSubmitCodeMutation,
  useCompileCodeLazyQuery,
  useGetAutoSaveQuery,
  useGetCampusQuery,
  useGetCourseQuery,
  useGetQuestionQuery,
  useGetStudentByEmailIdQuery,
} from "../../../../../graphql/generated.tsx";

const PreviewQuestion = () => {
 const [code, setCode] = useState("");
 const [language, setLanguage] = useState("C");
 const [output, setOutput] = useState("");
 const [error, setError] = useState("");
 const [input, setInput] = useState("");
 const [question, setQuestion] = useState({});
 const [isChecked, setIsChecked] = useState(false);
 const errorRef = useRef(null);
 const [testResults, setTestResults] = useState([]);
 const [errorOccurred, setErrorOccurred] = useState(false);
 const [run, setRun] = useState(false);
 const [submit, setSubmit] = useState(false);
 const tableRef = useRef(null);
 const { questionId, courseId, clusterid } = useParams();
 const [isFullScreen, setFullScreen] = useState(false);
 const [codeApplied, setCodeApplied] = useState(false);

 const { data, loading } = useGetQuestionQuery({
   variables: { id: questionId },
 });
 const { data: CourseData } = useGetCourseQuery({
   variables: { id: courseId },
 });

 const extractFunctionCode = (code, language, targetFunctionName) => {
   let functionRegex;

   switch (language) {
     case "python":
       functionRegex = new RegExp(
         `def\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*:[\\s\\S]*?(?=\\n\\S|$)`,
         "g"
       );
       break;
     case "java":
       functionRegex = new RegExp(
         `public\\s+static\\s+\\w+\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*{[^}]*}`,
         "g"
       );
       break;
     case "cpp":
     case "c":
       functionRegex = new RegExp(
         `\\w+(\\s*\\*\\s*|\\s+)${targetFunctionName}\\s*\\([^)]*\\)\\s*{([\\s\\S]*?)}(?![^}]*})`,
         "g"
       );
       break;
     default:
       return "";
   }

   const match = code?.match(functionRegex);
   return match ? match[0] : "";
 };
 const email = JSON.parse(localStorage.getItem("user"))?.email;
 const { data: studentData } = useGetStudentByEmailIdQuery({
   variables: { email },
 });
 const student = studentData?.getStudentByEmailId;

 const { data: autosave, refetch: refetchautosave } = useGetAutoSaveQuery({
   variables: { question_id: question?.id, student_id: student?.id },
 });

 useEffect(() => {
   if (autosave?.getAutoSave) {
     const lang = autosave?.getAutoSave?.language;
     setLanguage(lang);
   } else if (CourseData?.getCourse) {
     const defaultLang = CourseData?.getCourse.languages[0];
     setLanguage(defaultLang);
   }
 }, [CourseData, autosave]);

 useEffect(() => {
   if (data && !codeApplied) {
     setQuestion(data.getQuestion);
     const langKey = language?.toLowerCase();
     if (
       autosave?.getAutoSave &&
       autosave?.getAutoSave?.language === language
     ) {
       setCode(autosave.getAutoSave?.code);
     } else {
       if (
         data?.getQuestion.showFunctionOnly &&
         data?.getQuestion.predefinedCode[langKey]
       ) {
         const targetFunctionCode = extractFunctionCode(
           data.getQuestion.predefinedCode[langKey],
           langKey,
           data.getQuestion.targetFunctionName
         );
         setCode(targetFunctionCode);
       } else if (data?.getQuestion?.predefinedCode?.[langKey]) {
         setCode(data?.getQuestion?.predefinedCode[langKey]);
       } else {
         setCode("//Type your code here");
       }
     }
   }
 }, [data, autosave, language]);

 useEffect(() => {
   if (isFullScreen) {
     document.documentElement.requestFullscreen();
   } else {
     if (document.fullscreenElement) {
       document.exitFullscreen();
     }
   }
 }, [isFullScreen]);

 useEffect(() => {
   if (tableRef.current) {
     tableRef.current.scrollIntoView({ behavior: "smooth" });
   }
 }, [testResults]);

 useEffect(() => {
   if (error) {
     errorRef.current.scrollIntoView({ behavior: "smooth" });
   }
 }, [error]);

 const [
   compileCode,
   { data: compileData, loading: compileLoading, error: compileerror },
 ] = useCompileCodeLazyQuery();

 const [submitCode, { data: submitData, loading: submitLoading }] =
   useSubmitCodeMutation();

 useEffect(() => {
   if (compileData) {
     setTestResults(compileData.compileCode.Results);
     setErrorOccurred(false);
   }
 }, [compileData]);

 useEffect(() => {
   if (submitData?.submitCode) {
     setOutput(submitData?.submitCode);
   }
 }, [submitData]);

 const handleCodeChange = (newValue) => {
   setCode(newValue);
 };

 const handleCheckboxChange = () => {
   setIsChecked(!isChecked);
   setRun(false);
   setInput("");
 };

 const { data: campusData } = useGetCampusQuery({
   variables: { id: student?.campusId },
   skip: !student,
 });
 const campusDetails = campusData?.campus;
 const submitInput = {
   campus_name: campusDetails?.name,
   cluster_id: clusterid,
   student_id: student?.id,
   course_id: courseId,
   question_id: questionId,
 };
 const handleSubmit = async () => {
   setIsChecked(false);
   submitCode({
     variables: {
       compilerCodeInput: {
         code,
         testCases: question?.test_cases?.map((test) => ({
           id: test.id,
           input: test.input,
           output: test.output,
           visibility: test.visibility,
           weightage: parseFloat(test.weightage),
         })),
         language,
         questionid: question.id,
       },
       submitInput: submitInput,
     },
   });
   setSubmit(true);
   setRun(false);
   setInput("");
 };

 const handleRun = async () => {
   try {
     setSubmit(false);
     setError(false);

     let data;

     if (!isChecked) {
       const response = await compileCode({
         variables: {
           compileCodeInput: {
             code,
             testCases: question?.test_cases?.map((test) => ({
               id: test.id,
               input: test.input,
               output: test.output,
               visibility: test.visibility,
               weightage: parseFloat(test.weightage),
             })),
             language,
             questionid: question.id,
           },
         },
       });
       setRun(true);
       data = response.data;
     } else {
       const response = await compileCode({
         variables: {
           compileCodeInput: {
             code,
             testCases: [
               {
                 id: "0",
                 input: input,
                 output: " ",
                 visibility: "SAMPLE",
                 weightage: 0,
               },
             ],
             language,
             questionid: question?.id,
           },
         },
       });

       data = response.data;
     }

     if (data) {
       setRun(true);
       setTestResults(data.compileCode.Results);
       setErrorOccurred(false);
     }
   } catch (err) {
     setErrorOccurred(true);
     setTestResults([]);

     if (err?.graphQLErrors && err?.graphQLErrors.length > 0) {
       const errorMessage = err?.graphQLErrors[0]?.message;
       setError(errorMessage);
     } else {
       setError(
         "There could some logic error in your code or network connectivity issue. Kindly try again later"
       );
     }
   }
 };
 const setToDefault = () => {
   const langKey = language?.toLowerCase();
   if (
     data?.getQuestion.showFunctionOnly &&
     data?.getQuestion.predefinedCode[langKey]
   ) {
     const targetFunctionCode = extractFunctionCode(
       data.getQuestion.predefinedCode[langKey],
       langKey,
       data.getQuestion.targetFunctionName
     );
     setCode(targetFunctionCode);
   } else if (data?.getQuestion.predefinedCode[langKey]) {
     setCode(data.getQuestion.predefinedCode[langKey]);
   }
 };
 const onApply = (code, lang) => {
   setCodeApplied(true);
   setLanguage(lang || "C");
   const langKey = lang?.toLowerCase() ? lang?.toLowerCase() : "c";
   if (data?.getQuestion.showFunctionOnly) {
     const targetFunctionCode = extractFunctionCode(
       code,
       langKey,
       data.getQuestion.targetFunctionName
     );
     setCode(targetFunctionCode);
   } else {
     setCode(code);
   }
 };
 return (
   <PracticeLayout isFullScreen={isFullScreen}>
     <BackButton setFullScreen={setFullScreen} isFullScreen={isFullScreen} />
     <div className={!isFullScreen ? "" : "flex"}>
       <div className={!isFullScreen ? "mb-5" : "w-1/2 pr-4"}>
         <CompilerQuestionCard
           question={question}
           loading={loading}
           setLanguage={setLanguage}
           setCodeApplied={setCodeApplied}
           submitInput={submitInput}
           onApply={onApply}
           extractFunctionCode={extractFunctionCode}
           showFunctionOnly={data?.getQuestion?.showFunctionOnly}
           targetFunctionName={data?.getQuestion?.targetFunctionName}
           hideSubmission
         />
       </div>
       <div className={!isFullScreen ? "" : "w-1/2 pl-4"}>
         <Compiler
           student_id={student?.id}
           setCodeApplied={setCodeApplied}
           question_id={question?.id}
           language={language}
           code={code}
           setToDefault={setToDefault}
           setLanguage={setLanguage}
           handleCodeChange={handleCodeChange}
           errorRef={errorRef}
           handleSubmit={handleSubmit}
           handleRun={handleRun}
           CourseData={CourseData?.getCourse}
           compileLoading={compileLoading}
           submitLoading={submitLoading}
           isChecked={isChecked}
           refetchautosave={refetchautosave}
           previewMode
         />
       </div>
     </div>
   </PracticeLayout>
 );
}

export default PreviewQuestion