import React, { useEffect, useState } from "react";
import PracticeLayout from "../Layout";
import { ClockIcon, CommandLineIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useParams } from "react-router-dom";
import StudentCategoryCard from "../Components/StudentCategoryCard";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useGetCourseQuery } from "../../../graphql/generated.tsx";

const StudentCourseDetails = () => {
  const [courseData, setCourseData] = useState(null);
  const { courseId, clusterid } = useParams();
  const { data } = useGetCourseQuery({ variables: { id: courseId } });

  useEffect(() => {
    setCourseData(data?.getCourse);
  }, [data]);

  return (
    <PracticeLayout>
      <div className="flex items-center mb-6 max-md:mt-5">
        <button
          onClick={() => window.history.back()}
          className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
        >
          <span className="gap-2 text-sm font-medium flex items-center">
            <IoMdArrowRoundBack className="h-6 w-6" />
            Go Back
          </span>
        </button>
      </div>
      <div className="px-6 py-8 rounded-md shadow-sm bg-white relative mt-5 max-md:mt-10">
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <div className="inline-block bg-orange-100 p-6 rounded-lg max-w-full md:max-w-1/2">
            <CommandLineIcon className="h-12 w-12 text-orange-500" />
          </div>
          <div className="md:max-w-screen-sm">
            <div className="font-bold text-xl mb-2">{courseData?.name}</div>
            <div className="text-slate-500 text-sm">
              {courseData?.short_description}
            </div>
          </div>
        </div>
        <hr className="max-w-full my-6" />
        <div className="flex flex-wrap gap-4 md:gap-32">
          <div className="flex flex-col w-full md:w-auto">
            <ClockIcon className="w-5 h-5 text-slate-500" />
            <span className="text-sm text-slate-500 mb-1">Duration</span>
            <span className="font-bold">{courseData?.time_period} hours</span>
          </div>
        </div>
      </div>
      <div className="mt-8 bg-white p-8 rounded-md min-h-2xl">
        <h1 className="text-center text-2xl font-semibold mb-5">Categories</h1>
        {courseData?.categories?.length === 0 ? (
          <div className="text-center text-gray-500">
            No Categories available
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {courseData?.categories?.map((cat) => (
              <div key={cat.id} className="flex">
                <StudentCategoryCard
                  name={cat.name}
                  description={cat.description}
                  cat_id={cat.id}
                  course_id={courseId}
                  clusterid={clusterid}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </PracticeLayout>
  );
};

export default StudentCourseDetails;
