import { Card } from "flowbite-react";
import React from "react";

const CategoryCard = (props) => {
  const { name, description, cat_id, course_id } = props;
  return (
    <Card href={`/admin/courses/${course_id}/${cat_id}`} className="w-[700px]">
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {name}
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400">
        {description}
      </p>
    </Card>
  );
};

export default CategoryCard;
