import React from "react";
import NavBar from "../../../Common/NavBar";

const PracticeLayout = ({ children, isFullScreen }) => {
  return (
    <div className="w-[100%]">
      {isFullScreen ? null : <NavBar />}
      <main
        className={`bg-slate-100 min-w-screen min-h-screen ${
          isFullScreen ? "p-14" : "p-20"
        } max-md:p-5 max-md:mt-12`}
      >
        {children}
      </main>
    </div>
  );
};

export default PracticeLayout;
