import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import {
  useGetStudentsByCampusQuery,
  useSendInviteEmailToStudentMutation,
  useDeleteStudentMutation,
  useDeleteBulkStudentsMutation,
  useGetCampusQuery,
} from "../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import {
  IoMdAdd,
  IoMdArrowRoundBack,
  IoMdSend,
  IoMdTrash,
} from "react-icons/io";
import { MdCheckCircle } from "react-icons/md";
import { Button, Table, Spinner } from "flowbite-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ConformationModal from "../../Components/ConformationModal.jsx";

const StudentDetails = () => {
  const { id } = useParams();
  const { loading, error, data, refetch } = useGetStudentsByCampusQuery({
    variables: { campusId: id },
  });
  const { data: campusDetails } = useGetCampusQuery({ variables: { id: id } });
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [inviteStudent] = useSendInviteEmailToStudentMutation();
  const [deleteStudent] = useDeleteStudentMutation();
  const [deleteBulkStudents] = useDeleteBulkStudentsMutation();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isInviting, setIsInviting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [openBulkModal, setOpenBulkModal] = useState(false);

  useEffect(() => {
    refetch();
    if (data && data.getStudentsByCampus) {
      setStudents(data.getStudentsByCampus.students);
    }
  }, [data]);

  const handleInviteStudent = async (emails) => {
    try {
      setIsInviting(true);
      await inviteStudent({ variables: { emails } });
      setStudents((prev) =>
        prev.map((s) =>
          emails.includes(s.email) ? { ...s, isInviteSend: true } : s
        )
      );
      toast.success("Invite sent successfully");
    } catch (err) {
      toast.error("Failed to send invites");
    } finally {
      setIsInviting(false);
    }
  };

  const handleSelectStudent = (email) => {
    setSelectedStudents((prev) =>
      prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email]
    );
  };

  const handleBulkInvite = () => {
    handleInviteStudent(selectedStudents);
    setSelectedStudents([]);
  };

  const handleDeleteStudent = async (id) => {
    try {
      setIsDeleting(true);
      await deleteStudent({
        variables: { id, campusName: campusDetails?.campus?.name },
      });
      setStudents((prev) => prev.filter((s) => s.id !== id));
      toast.success("Student deleted successfully");
    } catch (err) {
      toast.error("Failed to delete student");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleBulkDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteBulkStudents({
        variables: {
          studentIds: selectedStudents,
          campusName: campusDetails?.campus?.name,
        },
      });
      setStudents((prev) =>
        prev.filter((s) => !selectedStudents.includes(s.email))
      );
      setSelectedStudents([]);
      toast.success("Selected students deleted successfully");
    } catch (err) {
      toast.error("Failed to delete selected students");
    } finally {
      setIsDeleting(false);
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p>Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              Student Details
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleBulkInvite}
              className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary p-0"
              disabled={selectedStudents.length === 0 || isInviting}
            >
              <IoMdSend className="mr-1 mt-1" />
              <span>Send Bulk Invite</span>
              {isInviting && <Spinner className="ml-2" size="sm" />}
            </Button>
            <Button
              onClick={() => setOpenBulkModal(true)} // Open modal for bulk delete
              className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-red-600 p-0"
              disabled={selectedStudents.length === 0 || isDeleting}
            >
              <IoMdTrash className="mr-1 mt-1" />
              <span>Delete Selected Students</span>
              {isDeleting && <Spinner className="ml-2" size="sm" />}
            </Button>
            <Link to={`create-students`}>
              <Button className="text-white px-3 py-1 rounded-lg shadow-md w-300px flex items-center bg-primary p-0">
                <IoMdAdd className="mr-1 mt-1" /> <span>Create Student</span>
              </Button>
            </Link>
          </div>
        </div>
        <div className="mt-10">
          <Table className="min-w-full bg-white border border-gray-300">
            <Table.Head>
              <Table.HeadCell>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setSelectedStudents(
                      e.target.checked ? students.map((s) => s.email) : []
                    )
                  }
                  checked={
                    selectedStudents.length === students.length &&
                    students.length > 0
                  }
                />
              </Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Roll No</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {students?.map((student) => (
                <Table.Row
                  key={student.id}
                  onMouseEnter={() => setHoveredRow(student.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  <Table.Cell>
                    <input
                      type="checkbox"
                      checked={selectedStudents.includes(student.email)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectStudent(student.email);
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>{student.name}</Table.Cell>
                  <Table.Cell>{student.email}</Table.Cell>
                  <Table.Cell>{student.rollNo}</Table.Cell>
                  <Table.Cell className="flex items-center gap-1">
                    {student.isInviteSend ? (
                      <MdCheckCircle className="text-green-500 h-6 w-6" />
                    ) : (
                      hoveredRow === student.id && (
                        <IoMdSend
                          className="mr-1 h-5 w-5 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInviteStudent([student.email]);
                          }}
                        />
                      )
                    )}
                    {hoveredRow === student.id && (
                      <IoMdTrash
                        className="mr-1 h-5 w-5 cursor-pointer text-red-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenModal(true);
                          setStudentToDelete(student.id);
                        }}
                      />
                    )}
                  </Table.Cell>
                  {studentToDelete && (
                    <ConformationModal
                      openModal={openModal}
                      event={() => handleDeleteStudent(studentToDelete)}
                      setOpenModal={setOpenModal}
                      text={"Are you sure you want to delete this student?"}
                    />
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>

      {/* Bulk Delete Modal */}
      <ConformationModal
        openModal={openBulkModal}
        event={handleBulkDelete}
        setOpenModal={setOpenBulkModal}
        text={"Are you sure you want to delete this student?"}
      />
    </AdminLayout>
  );
};

export default StudentDetails;
