import React from "react";
import StudentLayout from "../Layout";
import {
  useGetCampusQuery,
  useGetClustersByIdsAndCampusQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import { Card, Spinner, Alert } from "flowbite-react";
import { Link } from "react-router-dom";

const StudentCourses = () => {
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const {
    data: studentData,
    loading: studentLoading,
    error: studentError,
  } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const campusId = studentData?.getStudentByEmailId?.campusId;
  const {
    data: campusData,
    loading: campusLoading,
    error: campusError,
  } = useGetCampusQuery({
    variables: { id: campusId },
    skip: !campusId,
  });

  const clusterIds = studentData?.getStudentByEmailId?.clusterIds || [];
  const campusName = campusData?.campus?.name || "";
  const {
    data: clustersData,
    loading: clustersLoading,
    error: clustersError,
  } = useGetClustersByIdsAndCampusQuery({
    variables: { clusterIds, campusName },
    skip: !campusName || clusterIds.length === 0,
  });

  if (studentLoading || campusLoading || clustersLoading) {
    return (
      <StudentLayout>
        <div className="flex justify-center items-center min-h-screen">
          <Spinner size="xl" />
        </div>
      </StudentLayout>
    );
  }

  if (studentError || campusError || clustersError) {
    return (
      <StudentLayout>
        <div className="p-5">
          <Alert color="failure">
            <span>Error loading data. Please try again later.</span>
          </Alert>
        </div>
      </StudentLayout>
    );
  }

  const clusters = clustersData?.getClustersByIdsAndCampus || [];

  return (
    <StudentLayout noSidebar={true}>
      <div className="p-10 space-y-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-2">
            Batch Enrolled
          </h1>
          <p className="text-md text-black">
            Explore the batch you are enrolled in and start learning!
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5">
          {clusters.map((cluster) => (
            <Link to={`/student/courses/${cluster.id}`} key={cluster.id}>
              <Card className="h-[300px] text-center transition-transform transform hover:scale-105">
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {cluster.name}
                </h5>
                <div className="mt-4">
                  <span className="inline-block bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-semibold">
                    {campusName}
                  </span>
                </div>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </StudentLayout>
  );
};

export default StudentCourses;
