import React, { useEffect, useState } from "react";
import {
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useUpdateAdminMutation,
  useGetAllAdminQuery,
  useGetAllAssignementsQuery,
  useGetAllCoursesQuery,
} from "../../../../graphql/generated.tsx";
import {
  IoMdAdd,
  IoMdArrowRoundBack,
  IoMdTrash,
  IoMdCreate,
} from "react-icons/io";
import { Button, Table, Modal, Label, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import Select from "react-select";
import AdminLayout from "../../Layout/index.jsx";

const SuperAdmin = () => {
  const { loading, error, data, refetch } = useGetAllAdminQuery();
  const [admins, setAdmins] = useState([]);
  const [createAdmin] = useCreateAdminMutation();
  const [updateAdmin] = useUpdateAdminMutation();
  const [deleteAdmin] = useDeleteAdminMutation();
  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [course, setCourse] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [report, setReport] = useState(false);
  const [studentCreation, setStudentCreation] = useState(false);

  const { data: courseData } = useGetAllCoursesQuery();
  const { data: assignmentData } = useGetAllAssignementsQuery();

  const courseOptions = courseData?.getAllCourses.map((course) => ({
    label: course.name,
    value: course.id,
  }));
  const assignmentOptions = assignmentData?.getAllAssignements.map(
    (assign) => ({
      label: assign.name,
      value: assign.id,
    })
  );

  useEffect(() => {
    if (data) {
      setAdmins(data.getAllAdmin);
    }
  }, [data]);

  const handleOpenModal = () => {
    setOpenModal(true);
    setIsEditMode(false);
    setSelectedAdminId(null);
    setName("");
    setEmail("");
    setIsSuperAdmin(false);
    setCourse([]);
    setAssignment([]);
    setReport(false);
  };

  const handleCreateOrUpdateAdmin = async () => {
    const variables = {
      name,
      email,
      isSuperAdmin,
      permissions: {
        courses: isSuperAdmin ? null : course.map((option) => option.value),
        assignments: isSuperAdmin
          ? null
          : assignment.map((option) => option.value),
        reports: report,
        student_creation: studentCreation
      },
    };

    try {
      if (isEditMode && selectedAdminId) {
        await updateAdmin({
          variables: {
            updateAdminInput: { ...variables, id: selectedAdminId },
          },
        });
        refetch();

        toast.success("Admin Updated Successfully");
      } else {
        const { data: newAdminData } = await createAdmin({
          variables: { CreateAdminInput: variables },
        });

        if (newAdminData?.createAdmin?.message?.includes("already exists")) {
          toast.error("Email already exists");
        } else {
          setAdmins((prev) => [...prev, newAdminData.createAdmin.admin]);
          toast.success("Admin Added Successfully");
        }
      }
      setOpenModal(false);
    } catch (err) {
      console.error("Error creating or updating admin:", err);
      toast.error("Failed to create or update admin");
    }
  };

  const handleDeleteAdmin = async (id) => {
    try {
      await deleteAdmin({ variables: { id } });
      setAdmins((prev) => prev.filter((a) => a.id !== id));
      toast.success("Admin deleted successfully");
    } catch (err) {
      console.error("Error deleting admin:", err);
      toast.error("Failed to delete admin");
    }
  };

  const handleEditAdmin = (admin) => {
    setIsEditMode(true);
    setSelectedAdminId(admin.id);
    setName(admin.name);
    setEmail(admin.email);
    setIsSuperAdmin(admin.isSuperAdmin);

    const selectedCourses = courseOptions.filter((option) =>
      admin?.permissions?.courses?.includes(option.value)
    );
    const selectedAssignments = assignmentOptions.filter((option) =>
      admin?.permissions?.assignments?.includes(option.value)
    );

    setCourse(selectedCourses);
    setAssignment(selectedAssignments);
    setReport(admin?.permissions?.reports);
    setStudentCreation(admin?.permissions?.student_creation);
    setOpenModal(true);
  };

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen">
          <p>Loading...</p>
        </div>
      </AdminLayout>
    );
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2">Admin Details</div>
          </div>
          <Button onClick={handleOpenModal} className="bg-primary p-0">
            <IoMdAdd className="mr-1 mt-1" /> Create Admin
          </Button>
        </div>

        <Table>
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {admins.map((admin) => (
              <Table.Row key={admin.id} className="bg-white">
                <Table.Cell>{admin.name}</Table.Cell>
                <Table.Cell>{admin.email}</Table.Cell>
                <Table.Cell className="flex space-x-2">
                  <IoMdCreate
                    className="cursor-pointer text-blue-500"
                    onClick={() => handleEditAdmin(admin)}
                  />
                  <IoMdTrash
                    className="cursor-pointer text-red-500"
                    onClick={() => handleDeleteAdmin(admin.id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {/* Modal for creating or editing admin */}
        <Modal show={openModal} onClose={() => setOpenModal(false)}>
          <Modal.Header>
            {isEditMode ? "Edit Admin" : "Create a new admin member"}
          </Modal.Header>
          <Modal.Body>
            <Label htmlFor="name" value="Admin Name" />
            <TextInput
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter admin name"
              required
              className="mb-4"
            />

            <Label htmlFor="email" value="Admin Email" />
            <TextInput
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter admin email"
              required
              className="mb-4"
            />

            <div className="flex items-center space-x-4 mb-4">
              <input
                type="checkbox"
                id="superAdmin"
                checked={isSuperAdmin}
                onChange={(e) => setIsSuperAdmin(e.target.checked)}
                className="form-checkbox"
              />
              <Label htmlFor="superAdmin" value="Super Admin" />
            </div>

            {!isSuperAdmin && (
              <>
                <Label value="Course Access" className="mt-10" />
                <Select
                  options={courseOptions}
                  isMulti
                  value={course}
                  onChange={setCourse}
                  placeholder="Select Courses"
                  className="mb-4"
                />

                <Label value="Assignment Access" className="mt-10" />
                <Select
                  options={assignmentOptions}
                  isMulti
                  value={assignment}
                  onChange={setAssignment}
                  placeholder="Select Assignments"
                  className="mb-4"
                />

                <div className="flex items-center space-x-4 mb-4">
                  <input
                    type="checkbox"
                    checked={report}
                    onChange={() => setReport(!report)}
                    className="form-checkbox"
                  />
                  <Label value="Report Access" />

                  <input
                    type="checkbox"
                    checked={studentCreation}
                    onChange={() => setStudentCreation(!studentCreation)}
                    className="form-checkbox"
                  />
                  <Label value="Student Creation Access" />
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCreateOrUpdateAdmin}>
              {isEditMode ? "Update" : "Create"}
            </Button>
            <Button color="gray" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </AdminLayout>
  );
};

export default SuperAdmin;
