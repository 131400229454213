import { Card } from "flowbite-react";
import React from "react";

const BadgeCard = () => {
  return (
    <Card className="w-[320px] mt-5">
      <div className="flex flex-col justify-start items-start h-full">
        <h3 className="text-start block text-gray-500">Badges</h3>
        <p className="text-lg font-semibold">10</p>
      </div>
      <div className="flex flex-col justify-end items-start h-full">
        <h3 className="text-start block text-gray-500">Locked Badges</h3>
        <p className="text-lg font-semibold">May BTCoding Challenge</p>
      </div>
    </Card>
  );
};

export default BadgeCard;
