import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Button, Checkbox } from "flowbite-react";
import { useParams, useNavigate } from "react-router-dom";
import AdminLayout from "../../../Layout";
import ImportQuestionCard from "../../../Components/ImportQuestionCard";
import {
  useAddQuestionsToCourseMutation,
  useGetQuestionsByCategoryQuery,
} from "../../../../../graphql/generated.tsx";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";

const ImportQuestion = () => {
  const { cat_id, course_id } = useParams();
  const [catName, setCatName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;
  const navigate = useNavigate();

  const {
    data: FilterByCategoryQuestions,
    error,
    refetch,
  } = useGetQuestionsByCategoryQuery({
    variables: {
      categoryId: cat_id,
      courseId: course_id,
      limit: questionsPerPage,
      page: currentPage,
      searchName: searchQuery,
    },
    onError: (err) => toast.error(`Error fetching questions: ${err.message}`),
  });

  useEffect(() => {
    if (FilterByCategoryQuestions?.getQuestionsByCategory?.questions) {
      setCatName(
        FilterByCategoryQuestions?.getQuestionsByCategory?.category?.name || ""
      );
      setQuestions(FilterByCategoryQuestions?.getQuestionsByCategory.questions);
    }
  }, [FilterByCategoryQuestions]);

  useEffect(() => {
    refetch();
  }, [searchQuery, currentPage, refetch]);

  const totalPages = FilterByCategoryQuestions?.getQuestionsByCategory
    ?.totalCount
    ? Math.ceil(
        FilterByCategoryQuestions?.getQuestionsByCategory.totalCount /
          questionsPerPage
      )
    : 0;

  const handleCheckboxChange = (e, questionId) => {
    if (e.target.checked) {
      setSelectedQuestions([...selectedQuestions, questionId]);
    } else {
      setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
    }
  };

  const [addQuestionToCourse, { loading }] = useAddQuestionsToCourseMutation({
    onError: (err) => toast.error(`Error adding questions: ${err.message}`),
  });

  const handleSave = async () => {
    if (selectedQuestions.length > 0) {
      try {
        await addQuestionToCourse({
          variables: { courseId: course_id, questionIds: selectedQuestions },
        });
        navigate(-1);
        toast.success("Questions Imported Successfully");
      } catch (error) {
        console.error(error);
        toast.error("Error importing questions");
      }
    } else {
      toast.error("Please select at least one question.");
    }
  };

  return (
    <AdminLayout>
      <div className="p-8">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate(-1)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
          >
            <span className="gap-2 text-sm font-medium flex items-center">
              <IoMdArrowRoundBack className="h-6 w-6" />
              Go Back
            </span>
          </button>
          <h2 className="text-3xl font-bold text-center max-md:text-2xl">
            {catName} Questions
          </h2>
          <Button
            onClick={handleSave}
            disabled={selectedQuestions.length === 0 || loading}
            className="bg-primary text-white"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>

        {/* Search and Filter Section */}
        <div className="flex justify-center mb-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search questions..."
            className="border border-gray-300 rounded-md px-2 py-1 mr-2"
          />
          <Button onClick={() => refetch()} className="bg-primary p-0">
            Search
          </Button>
        </div>

        <div className="mt-5">
          {questions.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-[500px] text-center">
              <FaSearch className="text-7xl text-gray-400 mb-4" />
              <h2 className="text-2xl font-bold text-gray-600 mb-2">
                Oops, No Results Found
              </h2>
              <p className="text-gray-500 mb-6 lg:w-[500px]">
                It looks like we couldn't find any questio to import.
              </p>
            </div>
          ) : (
            questions.map((question) => (
              <div
                className="flex items-center w-full bg-white px-8 mb-2 py-4 justify-center rounded-md shadow-md"
                key={question._id}
              >
                <Checkbox
                  className="pb-3"
                  onChange={(e) => handleCheckboxChange(e, question.id)}
                  checked={selectedQuestions.includes(question.id)}
                />
                <div className="w-full">
                  <ImportQuestionCard data={question} />
                </div>
              </div>
            ))
          )}

          <div className="flex justify-between mt-4">
            <Button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-0 bg-primary"
            >
              Previous
            </Button>
            <span className="text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-0 bg-primary"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ImportQuestion;
