import React, { useState, useEffect } from "react";
import {
  useGetCampusQuery,
  useGetStaffByEmailIdQuery,
  useUpdateStaffMutation,
} from "../../../../graphql/generated.tsx";
import { MdClose, MdOutlineEdit } from "react-icons/md";
import { TfiSave } from "react-icons/tfi";
import NavBar from "../../../../Common/NavBar/index.jsx";

const StaffMyProfile = () => {
  const email = JSON.parse(localStorage.getItem("user")).email;
  const {
    data: StaffData,
    loading,
    error,
  } = useGetStaffByEmailIdQuery({
    variables: { email },
  });
  const [isEditing, setIsEditing] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    contactNo: "",
    designation: "",
  });
  const { data: CampusData } = useGetCampusQuery({
    variables: { id: StaffData?.getStaffByEmailId?.campusId },
  });

  const [updateStaff] = useUpdateStaffMutation();

  useEffect(() => {
    if (StaffData && StaffData.getStaffByEmailId) {
      setFormData({
        name: StaffData.getStaffByEmailId.name || "",
        contactNo: StaffData.getStaffByEmailId.contactNo || "",
        designation: StaffData.getStaffByEmailId.designation || "",
        email: StaffData.getStaffByEmailId.email || "",
        campusName: CampusData?.campus?.name || "",
      });
    }
  }, [CampusData?.campus?.name, StaffData]);

  const handleEditClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveClick = (field) => {
    const { name, contactNo, designation, email } = formData;
    updateStaff({
      variables: {
        updateStaffInput: {
          id: StaffData.getStaffByEmailId.id,
          name,
          email,
          contactNo,
          designation,
        },
      },
    }); // Adjust according to your GraphQL mutation structure
    setIsEditing((prev) => ({ ...prev, [field]: false }));
  };

  const handleCloseClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
  };

  const renderField = (field, label) => (
    <div
      key={field}
      className="flex items-center mb-4 p-4 bg-white rounded-md shadow-md"
    >
      <strong className="w-[40%] text-gray-700">{label}:</strong>
      {isEditing[field] ? (
        <input
          type="text"
          name={field}
          value={formData[field]}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
      ) : (
        <span className="w-1/2 text-gray-900">{formData[field]}</span>
      )}
      {field !== "email" && field !== "campusName" && !isEditing[field] && (
        <MdOutlineEdit
          onClick={() => handleEditClick(field)}
          className="ml-2 text-blue-500 cursor-pointer"
        />
      )}
      {isEditing[field] && (
        <>
          <TfiSave
            onClick={() => handleSaveClick(field)}
            className="ml-3 h-5 w-5 hover:text-secondary cursor-pointer"
          />
          <MdClose
            onClick={() => handleCloseClick(field)}
            className="ml-3 h-5 w-5 hover:text-red-500 cursor-pointer"
          />
        </>
      )}
    </div>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <>
      <NavBar />
      <div className="mt-14 bg-slate-100 min-h-screen">
        <h2 className="text-3xl font-bold mb-10 text-center text-gray-800 pt-5">
          My Profile
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-lg">
          {renderField("name", "Name")}
          {renderField("email", "Email")}
          {renderField("campusName", "College Name")}
          {renderField("contactNo", "Contact No")}
          {renderField("designation", "Designation")}
        </div>
      </div>
    </>
  );
};

export default StaffMyProfile;
