import React, { useEffect, useState } from "react";
import { MdClose, MdOutlineEdit } from "react-icons/md";
import { TfiSave } from "react-icons/tfi";
import {
  useToggleSendEmailMutation,
  useUpdateClusterNameMutation,
} from "../../../../graphql/generated.tsx";

const SettingsTab = ({
  campusName,
  clusterId,
  formData,
  setFormData,
  ClusterData,
}) => {
  const [isEditing, setIsEditing] = useState({});
  const [sendEmail, setSendEmail] = useState();

  const handleEditClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    if (ClusterData && ClusterData?.getCluster) {
      setSendEmail(ClusterData?.getCluster.sendEmail);
    }
  }, [ClusterData]);

  const [toggleSendEmail] = useToggleSendEmailMutation();
  const [updateCluster] = useUpdateClusterNameMutation();

  const handleSaveClick = (field) => {
    const { name } = formData;
    updateCluster({
      variables: {
        updateClusterNameInput: {
          campusName,
          clusterId,
          newName: name,
        },
      },
    })
      .then(() => {
        setIsEditing((prev) => ({ ...prev, [field]: false }));
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating cluster:", error);
      });
  };

  const handleCloseClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
  };

  const handleToggleChange = () => {
    const newSendEmail = !sendEmail;
    setSendEmail(newSendEmail);
    toggleSendEmail({
      variables: { campusName, clusterId, sendEmail: newSendEmail },
    }).catch((error) => {
      // Handle error
      console.error("Error toggling send email:", error);
    });
  };

  const renderField = (field, label) => (
    <div key={field} className="flex items-center mb-4 p-4">
      <strong className="w-[40%] text-gray-700">{label}:</strong>
      {isEditing[field] ? (
        <input
          type="text"
          name={field}
          value={formData[field]}
          onChange={handleInputChange}
          className="w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
      ) : (
        <span className="w-1/2 text-gray-900">{formData[field]}</span>
      )}
      {field !== "email" && field !== "campusName" && !isEditing[field] && (
        <MdOutlineEdit
          onClick={() => handleEditClick(field)}
          className="ml-2 text-blue-500 cursor-pointer"
        />
      )}
      {isEditing[field] && (
        <>
          <TfiSave
            onClick={() => handleSaveClick(field)}
            className="ml-3 h-5 w-5 hover:text-secondary cursor-pointer"
          />
          <MdClose
            onClick={() => handleCloseClick(field)}
            className="ml-3 h-5 w-5 hover:text-red-500 cursor-pointer"
          />
        </>
      )}
    </div>
  );

  return (
    <div className="p-4">
      <h2 className="text-3xl font-bold mb-10 text-center text-gray-800 pt-5">
        Cluster Info
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-6xl mx-auto bg-white p-6 rounded-lg">
        {renderField("name", "Name")}
        <div className="flex items-center mb-4 p-4">
          <strong className="w-[40%] text-gray-700">Send Email:</strong>
          <div className="flex items-center">
            <input
              className="hidden" // Hide the checkbox
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={sendEmail}
              onChange={handleToggleChange}
            />
            <div
              onClick={handleToggleChange}
              className={`relative w-12 h-6 flex-shrink-0 rounded-full cursor-pointer ${
                sendEmail ? "bg-blue-500" : "bg-neutral-300"
              }`}
            >
              <div
                className={`absolute top-0.5 left-0.5 w-5 h-5 bg-neutral-100 rounded-full shadow-md transform transition-transform ${
                  sendEmail ? "translate-x-6" : ""
                }`}
              ></div>
            </div>
            <label
              className="inline-block pl-[0.15rem] hover:cursor-pointer font-light color-gray-50"
              htmlFor="flexSwitchCheckDefault"
            >
              {sendEmail ? "Enabled" : "Disabled"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;
