import React, { useState } from "react";
import { Modal, Button, TextInput, Label, Textarea } from "flowbite-react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  useAllCategoriesQuery,
  useUpdateCourseMutation,
} from "../../../graphql/generated.tsx";

const languageOptions = [
  { value: "PYTHON", label: "Python" },
  { value: "C", label: "C" },
  { value: "JAVA", label: "Java" },
  { value: "CPP", label: "C++" },
];

const levelOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
];

const EditCourse = ({ id, onClose, setCourseData, courseData }) => {
  const [name, setName] = useState(courseData?.name || "");
  const [duration, setDuration] = useState(courseData?.time_period);
  const { data: CategoriesData } = useAllCategoriesQuery();
  const allCategories = CategoriesData?.allCategories || [];

  const [short_description, setDescription] = useState(
    courseData?.short_description || ""
  );
  const [categories, setCategories] = useState(
    courseData?.categories.map((item) => {
      return {
        value: item.id,
        label: item.name,
        description: item.description,
      };
    }) || []
  );
  const [languages, setLanguages] = useState(
    courseData?.languages.map((lang) => ({ value: lang, label: lang })) || []
  );
  const [level, setLevel] = useState(
    levelOptions.find((option) => option.value === courseData?.level) || null
  );

  const [updateCourse] = useUpdateCourseMutation();

  const handleUpdate = async () => {
    try {
      const filteredCategories = categories.map(
        ({ value, label, description }) => ({
          id: value,
          name: label,
          description,
        })
      );

      await updateCourse({
        variables: {
          updateCourseInput: {
            name,
            time_period: parseInt(duration),
            short_description,
            categories: filteredCategories,
            languages: languages.map((lang) => lang.value),
            level: level?.value,
          },
          id,
        },
      });
      toast.success("Course Updated Successfully");
      onClose();
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    setCategories(selectedOptions || []);
  };

  const handleLanguageChange = (selectedOptions) => {
    setLanguages(selectedOptions || []);
  };

  const handleLevelChange = (selectedOption) => {
    setLevel(selectedOption);
  };

  return (
    <Modal show={true} onClose={onClose}>
      <Modal.Header>Edit Course</Modal.Header>
      <Modal.Body className="h-[100px]">
        <div className="block">
          <Label htmlFor="name" value="Name :" className="font-semibold" />
          <TextInput value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="block">
          <Label htmlFor="duration" value="Duration :" className="font-semibold" />
          <TextInput
          type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="short_description"
            value="Short Description :"
            className="font-semibold"
          />
          <Textarea
            value={short_description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="block mt-4">
          <Label
            htmlFor="languages"
            value="Languages :"
            className="font-semibold"
          />
          <Select
            isMulti
            name="languages"
            options={languageOptions}
            value={languages}
            onChange={handleLanguageChange}
            className="w-full rounded-md mb-4"
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="categories"
            value="Categories :"
            className="font-semibold"
          />
          <Select
            id="categories"
            options={allCategories.map((category) => ({
              label: category.name,
              value: category.id,
              description: category.description,
            }))}
            isMulti
            value={categories}
            onChange={handleCategoryChange}
            placeholder="Select Categories"
            className="w-full rounded-md mb-4 z-100"
          />
        </div>

        <div className="block mt-4">
          <Label htmlFor="level" value="Level :" className="font-semibold" />
          <Select
            name="level"
            options={levelOptions}
            value={level}
            onChange={handleLevelChange}
            className="w-full rounded-md mb-4"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpdate} className="p-0 bg-primary">
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCourse;
