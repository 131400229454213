import { useEffect, useState } from "react";
import { passwordReset } from "../../utils/firebase";
import { toast } from "react-toastify";
import { Button, Label, TextInput, Spinner } from "flowbite-react";
import NavBar from "../../Common/NavBar";
import { useNavigate, useLocation } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const emailFromParams = query.get("email");

    if (emailFromParams) {
      setEmail(emailFromParams);
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when form is submitted

    try {
      await passwordReset(email);
      toast.success("The Email has been sent; Check your Inbox!");
      navigate("/login");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        toast.error("User not found, try again!");
        setEmail("");
      } else if (error.code === "auth/too-many-requests") {
        toast.error("Too many requests. Please try again later.");
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      console.log("Error:", error.message);
    } finally {
      setIsLoading(false); // Set loading back to false after submission
    }
  };

  return (
    <>
      <NavBar />
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md"
        >
          <img
            className="mx-auto h-24 w-auto mb-8"
            src="/favicon.ico"
            alt="Your Company"
          />
          <h2 className="text-2xl font-extrabold mb-6 text-center text-gray-800 mt-8">
            Forgot Password
          </h2>
          <Label>User Email:</Label>
          <TextInput
            type="email"
            name="email"
            placeholder="Enter your email address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
            className="mb-6"
          />
          <Button
            type="submit"
            className="w-full bg-primary flex items-center justify-center"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading && <Spinner size="sm" className="mr-2" />}
            Submit
          </Button>
        </form>
      </div>
    </>
  );
}

export default ForgotPassword;
